import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import RapportiniComponent from '../../../components/admin/cantieri/rapportini'

const RapportiniDetails = () => {
  return (
    <Layout>
      <RapportiniComponent />
    </Layout>
  )
}

export default RapportiniDetails