import React, { useState, useEffect } from "react";
import { Edit, Trash2, Download, Eye, Search } from "lucide-react";
import { DateFormat } from "../../../../config/helper";
import {
  fetchDocuments,
  deleteDocument,
  fetchDocument,
  postDocument,
  updateDocument,
} from "../../../../apis/Document";
import { toast } from "react-toastify";

import { Button } from "../../../ui/button";
import { Input } from "../../../ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../ui/dialog";
import { Badge } from "../../../ui/badge";
import { Label } from "../../../ui/label";

import Pagination from "../../../admin/sharedComponent/Pgination";

const deleteContent = {
  title: "Elimina Documento",
  description: "Sei sicuro di voler eliminare questo documento? Questa azione è irreversibile.",
};

const AllDocuments = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentData, setDocumentData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user.companyId;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  const [newDocument, setNewDocument] = useState({
    title: "",
    expireDate: "",
    file: null
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const GetALLDocument = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetchDocuments(
        companyId,
        currentPage,
        limit,
        searchQuery
      );
      if (response && response.documents) {
        setDocumentData(response.documents);
        setTotalPage(response.totalPages);
        setCurrentPage(response.currentPage);
      } else {
        setError("Ricevuto un formato di risposta inaspettato dal server.");
      }
    } catch (error) {
      console.error("Errore nel recupero dei documenti:", error);
      setError("Impossibile recuperare i documenti. Riprova più tardi.");
    } finally {
      setIsLoading(false);
    }
  };

  function handleExpireDate(expireDate) {
    const currentDate = new Date();
    const expireDateTime = new Date(expireDate);
    return expireDateTime > currentDate;
  }

  useEffect(() => {
    GetALLDocument();
  }, [isUpdated, currentPage, limit, searchQuery]);

  const editDocument = async (id) => {
    try {
      setSelectedId(id);
      const response = await fetchDocument(id);
      setNewDocument({
        title: response.data.title,
        expireDate: response.data.expireDate,
        file: null
      });
      setIsModalOpen(true);
    } catch (error) {
      console.error("Errore nel recupero del documento per la modifica:", error);
      toast.error("Impossibile recuperare il documento per la modifica.");
    }
  };

  const handleDeleteDocument = async () => {
    try {
      const response = await deleteDocument(selectedId);
      if (response.status === 200) {
        toast.success(response.data?.message);
        setIsUpdated(!isUpdated);
        setDeleteModal(false);
      }
    } catch (error) {
      console.error("Errore nell'eliminazione del documento:", error);
      toast.error("Impossibile eliminare il documento.");
    }
  };

  const handleDownload = async (filePath) => {
    const proxyUrl = "https://cors-anywhere.herokuapp.com/";
    const baseUrl = process.env.REACT_APP_FRONTEND_URL;
    const fileUrl = `${baseUrl}/${filePath}`;
    try {
      const response = await fetch(proxyUrl + fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filePath.split("/").pop());
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success("Documento scaricato con successo");
    } catch (error) {
      console.error("Errore nel download del file", error);
      toast.error("Impossibile scaricare il documento.");
    }
  };

  const handlePreview = async (filePath) => {
    const fileUrl = `${process.env.REACT_APP_BACKEND_URL}/${filePath}`;
    try {
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Errore nell'apertura del file per l'anteprima:", error);
      toast.error("Impossibile visualizzare l'anteprima del documento.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'file') {
      setNewDocument({ ...newDocument, [name]: files[0] });
    } else {
      setNewDocument({ ...newDocument, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response;
      if (selectedId) {
        response = await updateDocument(selectedId, { ...newDocument, companyId });
      } else {
        response = await postDocument({ ...newDocument, companyId });
      }
      
      setIsModalOpen(false);
      setIsUpdated(!isUpdated);
      setNewDocument({ title: "", expireDate: "", file: null });
      toast.success(selectedId ? "Documento aggiornato con successo" : "Documento aggiunto con successo");
      
      GetALLDocument();
    } catch (error) {
      console.error("Errore nell'invio del documento:", error);
      toast.error("Impossibile salvare il documento. Riprova.");
    }
  };

  if (isLoading) return <div className="text-center py-4">Caricamento...</div>;
  if (error) return <div className="text-center py-4 text-red-500">Errore: {error}</div>;

  return (
    <div className="space-y-4 ">
      <div className="flex justify-between items-center">
        <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
          <DialogTrigger asChild>
            <Button variant="default">
              Aggiungi
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>{selectedId ? "Modifica Documento" : "Aggiungi Nuovo Documento"}</DialogTitle>
              <DialogDescription>
                {selectedId ? "Apporta modifiche al tuo documento qui." : "Aggiungi un nuovo documento qui."}
              </DialogDescription>
            </DialogHeader>
            <form onSubmit={handleSubmit}>
              <div className="grid gap-4 py-4">
                <div className="grid grid-cols-1 items-center gap-4">
                  <Label htmlFor="title" className="text-left">
                    Titolo
                  </Label>
                  <Input
                    id="title"
                    name="title"
                    value={newDocument.title}
                    onChange={handleInputChange}
                    placeholder="Inserisci il titolo"
                  />
                </div>
                <div className="grid grid-cols-1 items-center gap-4">
                  <Label htmlFor="expireDate" className="text-left">
                    Data di Scadenza
                  </Label>
                  <Input
                    id="expireDate"
                    name="expireDate"
                    type="date"
                    value={newDocument.expireDate}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="grid grid-cols-1 items-center gap-4">
                  <Label htmlFor="file" className="text-left">
                    File
                  </Label>
                  <Input
                    id="file"
                    name="file"
                    type="file"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <DialogFooter>
                <Button type="submit">{selectedId ? "Salva modifiche" : "Aggiungi documento"}</Button>
              </DialogFooter>
            </form>
          </DialogContent>
        </Dialog>
        <div className="md:w-[20%] relative">
          <Input
            type="search"
            placeholder="Cerca documenti..."
            onChange={(e) => setSearchQuery(e.target.value)}
            className="pl-10"
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
        </div>
      </div>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-center">No.</TableHead>
              <TableHead className="text-center">Nome documento</TableHead>
              <TableHead className="text-center">Scadenza</TableHead>
              <TableHead className="text-center">Stato</TableHead>
              <TableHead className="text-center">Azioni</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {documentData.length > 0 ? (
              documentData.map((data, index) => (
                <TableRow key={data._id}>
                  <TableCell className="text-center">{index + 1}</TableCell>
                  <TableCell className="text-center">{data.title}</TableCell>
                  <TableCell className="text-center">{DateFormat(data.expireDate)}</TableCell>
                  <TableCell className="text-center">
                    <Badge variant={handleExpireDate(data.expireDate) ? "success" : "destructive"}>
                      {handleExpireDate(data.expireDate) ? "Valido" : "Scaduto"}
                    </Badge>
                  </TableCell>
                  <TableCell className="text-center">
                    <div className="flex justify-center space-x-2">
                      <Button variant="ghost" size="sm" onClick={() => handlePreview(data.file)}>
                        <Eye size={18} />
                      </Button>
                      <Button variant="ghost" size="sm" onClick={() => editDocument(data._id)}>
                        <Edit size={18} />
                      </Button>
                      <Dialog>
                        <DialogTrigger asChild>
                          <Button variant="ghost" size="sm" onClick={() => setSelectedId(data._id)}>
                            <Trash2 size={18} />
                          </Button>
                        </DialogTrigger>
                        <DialogContent>
                          <DialogHeader>
                            <DialogTitle>{deleteContent.title}</DialogTitle>
                            <DialogDescription>{deleteContent.description}</DialogDescription>
                          </DialogHeader>
                          <DialogFooter>
                            <Button variant="outline" onClick={() => setDeleteModal(false)}>Annulla</Button>
                            <Button variant="destructive" onClick={handleDeleteDocument}>Elimina</Button>
                          </DialogFooter>
                        </DialogContent>
                      </Dialog>
                      <Button variant="ghost" size="sm" onClick={() => handleDownload(data.file)}>
                        <Download size={18} />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} className="text-center py-4">Nessun dato disponibile</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {documentData.length > 0 && (
        <div className="flex items-center justify-end space-x-2 py-4">
          <Pagination
            totalPages={totalPages}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
            setLimit={setLimit}
          />
        </div>
      )}
    </div>
  );
};

export default AllDocuments;