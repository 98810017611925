import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import { ChevronLeft, ChevronRight, Euro } from "lucide-react";

import { GetMonthlySalary, CalculateSalary } from "../../../../../apis/Salary";
import { capitalizeFirstLetter } from "../../../../../config/helper";

import { Button } from "../../../../ui/button";
import { Input } from "../../../../ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../ui/table";
import SearchBar from "../../../sharedComponent/SearchBar";
import HelpSheet from "../../../sharedComponent/HelpSheet";

const Salary = ({ companyId }) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [salaryData, setSalaryData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDataUpdate, setIsDataUpdate] = useState(false);
  const [payload, setPayload] = useState({
    companyId,
    month: selectedMonth.getMonth() + 1,
    year: selectedMonth.getFullYear(),
  });

  const fetchSalary = async () => {
    try {
      const response = await GetMonthlySalary(
        payload.companyId,
        payload.month,
        payload.year,
        searchQuery
      );
      if (response.status === 200) {
        setSalaryData(response.data.salaries);
      }
    } catch (error) {
      console.error("Errore nel recupero dei dati di stipendio", error);
      setSalaryData([]);
    }
  };

  const calculateMonthlySalary = async () => {
    setIsDataUpdate(true);
    try {
      const response = await CalculateSalary(payload);
      if (response.status === 200) {
        fetchSalary();
        setIsDataUpdate(false);
        console.log(">>>>>>>>>>>>", response.data);
        toast.success(response.data.message);
      }
    } catch (error) {
      console.log(error);
      setIsDataUpdate(false);
      toast.error("Impossibile calcolare lo stipendio");
    }
  };

  const handleMonthChange = (e) => {
    const newDate = new Date(e.target.value);
    setSelectedMonth(newDate);
    setPayload({
      ...payload,
      month: newDate.getMonth() + 1,
      year: newDate.getFullYear(),
    });
  };

  const incrementMonth = () => {
    setSelectedMonth((prev) => {
      const newDate = new Date(prev.setMonth(prev.getMonth() + 1));
      setPayload({
        ...payload,
        month: newDate.getMonth() + 1,
        year: newDate.getFullYear(),
      });
      return newDate;
    });
  };

  const decrementMonth = () => {
    setSelectedMonth((prev) => {
      const newDate = new Date(prev.setMonth(prev.getMonth() - 1));
      setPayload({
        ...payload,
        month: newDate.getMonth() + 1,
        year: newDate.getFullYear(),
      });
      return newDate;
    });
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  useEffect(() => {
    fetchSalary();
  }, [payload, searchQuery]);

  return (
    <div className=" mx-auto space-y-4">
      <div className="flex flex-col md:flex-row justify-between items-center my-2 space-y-4 md:space-y-0">
        
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-2">
            <Button variant="outline" size="icon" onClick={decrementMonth}>
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <Input
              type="month"
              value={format(selectedMonth, "yyyy-MM")}
              onChange={handleMonthChange}
              className="w-full"
            />
            <Button variant="outline" size="icon" onClick={incrementMonth}>
              <ChevronRight className="h-4 w-4" />
            </Button>
          </div>
          <Button
            onClick={calculateMonthlySalary}
            disabled={isDataUpdate}
          >
            {isDataUpdate ? "Calcolando..." : "Calcola Stipendio"}
          </Button>
        </div>
        <div className="md:w-1/6 flex justify-center items-center gap-x-2">
          <SearchBar onSearch={handleSearch} />
          <HelpSheet route="/employees" />
        </div>
      </div>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-center">ID</TableHead>
              <TableHead className="text-center">Nome Dipendente</TableHead>
              <TableHead className="text-center">Giorni Lavorativi Totali</TableHead>
              <TableHead className="text-center">Ore di Lavoro Totali</TableHead>
              <TableHead className="text-center">Ore di Assenza</TableHead>
              <TableHead className="text-center">Ore Extra</TableHead>
              <TableHead className="text-center">Stipendio</TableHead>
              <TableHead className="text-center">Stato</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {salaryData?.length > 0 ? (
              salaryData?.map((data, index) => (
                <TableRow key={index}>
                  <TableCell className="text-center">{index + 1}</TableCell>
                  <TableCell className="text-center">
                    {data?.employee?.firstName}
                  </TableCell>
                  <TableCell className="text-center">{data?.workingDays}</TableCell>
                  <TableCell className="text-center">
                    {data?.totalWorkHours.toFixed(2)}H
                  </TableCell>
                  <TableCell className="text-center">
                    {data?.absenceHours.toFixed(2)}H
                  </TableCell>
                  <TableCell className="text-center">{data?.extraHours.toFixed(2)}</TableCell>
                  <TableCell className="text-center">
                    <div className="flex items-center justify-center gap-1">
                      {data?.totalMonthlySalary}
                      <Euro className="h-4 w-4" />
                    </div>
                  </TableCell>
                  <TableCell
                    className={`text-center ${
                      data?.state === "unpaid"
                        ? "text-red-600"
                        : "text-green-600"
                    }`}
                  >
                    {data?.state === "unpaid" ? "Non Pagato" : "Pagato"}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} className="text-center py-8">
                  Nessun record trovato per questo mese
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default Salary;