import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import Preventivi_editComponent from '../../../components/admin/preventivi/preventivi_edit'

const Preventivi_editDetails = () => {
  return (
    <Layout>
      <Preventivi_editComponent />
    </Layout>
  )
}

export default Preventivi_editDetails