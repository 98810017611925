import { useEffect, useState } from "react";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../../ui/tabs";
import { useNavigate, useLocation } from "react-router-dom";
import { Switch } from "../../ui/switch";
import { Label } from "../../ui/label";
import { RadioGroup, RadioGroupItem } from "../../ui/radio-group";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../ui/card";
import { Button } from "../../ui/button";
import { updateNotificationPreferences } from "../../../apis/NotificationApi";
import { toast } from "react-toastify";

const TABS = [
  { name: "Preferenze", route: "/impostazioni" },
  { name: "Turni di lavoro", route: "/settings" },
  { name: "Notifiche", route: "/notifications" }
];

const NotificationSettings = () => {
  const [inSystemNotifications, setInSystemNotifications] = useState(true);
  const [emailNotifications, setEmailNotifications] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState({
    documents: {
      inApp: 'NO',
      email: 'NO'
    }
  });

  const handleChange = (section, type, value) => {
    setNotifications(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [type]: value
      }
    }));
  };


  useEffect(() => {
    fetchNotificationPreferences();
  }, []);

  const fetchNotificationPreferences = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user && user.notificationPreferences) {
        setInSystemNotifications(user.notificationPreferences.inSystem);
        setEmailNotifications(user.notificationPreferences.email);
      }
    } catch (error) {
      console.error("Error fetching notification preferences:", error);
      toast({
        title: "Errore",
        description: "Impossibile recuperare le preferenze di notifica.",
        variant: "destructive",
      });
    }
  };

  const handleSaveSettings = async () => {
    setIsLoading(true);
    try {
      await updateNotificationPreferences({
        inSystem: inSystemNotifications,
        email: emailNotifications,
      });
      toast({
        title: "Successo",
        description: "Le preferenze di notifica sono state aggiornate.",
      });
      
      // Update local storage
      const user = JSON.parse(localStorage.getItem("user"));
      user.notificationPreferences = {
        inSystem: inSystemNotifications,
        email: emailNotifications,
      };
      localStorage.setItem("user", JSON.stringify(user));
    } catch (error) {
      console.error("Error saving notification preferences:", error);
      toast({
        title: "Errore",
        description: "Impossibile salvare le preferenze di notifica.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card>
          <CardHeader>
            <CardTitle className="text-base font-medium">Notifiche</CardTitle>
          </CardHeader>
          <CardContent className="space-y-8">
            <div className="space-y-4">
              <div>
                <h3 className="mb-1 font-medium">Scadenza documenti azienda</h3>
                <p className="text-sm text-muted-foreground">
                  Avvisa quando i documenti aziendali stanno per scadere
                </p>
              </div>
              <div>
                <h3 className="mb-1 font-medium">Company documents deadline</h3>
                <p className="mb-4 text-sm text-muted-foreground">
                  Alerts you when business documents are about to expire
                </p>
                <div className="grid grid-cols-3 gap-4">
                  <div />
                  <Label className="text-center">In-app</Label>
                  <Label className="text-center">Email</Label>
                  
                  <Label className="self-center">Scadenza documenti azienda</Label>
                  <RadioGroup
                    className="flex justify-center"
                    onValueChange={(value) => handleChange("documents", "inApp", value)}
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="SI" id="inapp-si" />
                      <Label htmlFor="inapp-si">SI</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="NO" id="inapp-no" />
                      <Label htmlFor="inapp-no">NO</Label>
                    </div>
                  </RadioGroup>
                  <RadioGroup
                    className="flex justify-center"
                    value={notifications.documents.email}
                    onValueChange={(value) => handleChange("documents", "email", value)}
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="SI" id="email-si" />
                      <Label htmlFor="email-si">SI</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="NO" id="email-no" />
                      <Label htmlFor="email-no">NO</Label>
                    </div>
                  </RadioGroup>
                  
                  <Label className="self-center">Company documents deadline</Label>
                  <RadioGroup
                    className="flex justify-center"
                    onValueChange={(value) => handleChange("documents", "inApp", value)}
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="SI" id="inapp-si-2" />
                      <Label htmlFor="inapp-si-2">SI</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="NO" id="inapp-no-2" />
                      <Label htmlFor="inapp-no-2">NO</Label>
                    </div>
                  </RadioGroup>
                  <RadioGroup
                    className="flex justify-center"
                    value={notifications.documents.email}
                    onValueChange={(value) => handleChange("documents", "email", value)}
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="SI" id="email-si-2" />
                      <Label htmlFor="email-si-2">SI</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="NO" id="email-no-2" />
                      <Label htmlFor="email-no-2">NO</Label>
                    </div>
                  </RadioGroup>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
  );
};

const Notifications = () => {
  const [activeTab, setActiveTab] = useState("/notifications");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentTab = TABS.find(tab => location.pathname.startsWith(tab.route));
    if (currentTab) {
      setActiveTab(currentTab.route);
    }
  }, [location]);

  const handleTabChange = (value) => {
    navigate(value);
  };

  return (
    <div className="mx-auto space-y-4 p-6">
      <Tabs value={activeTab} onValueChange={handleTabChange} className="w-full">
        <TabsList className="bg-[#06052C] rounded-none w-full flex justify-start h-auto">
          {TABS.map((tab) => (
            <TabsTrigger
              key={tab.name}
              value={tab.route}
              className="py-2 px-4 text-white data-[state=active]:bg-white data-[state=active]:text-blue-950"
            >
              {tab.name}
            </TabsTrigger>
          ))}
        </TabsList>
        <TabsContent value="/notifications">
          <NotificationSettings />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default Notifications;