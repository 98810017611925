import React from "react";
import Layout from "../../../components/admin/sharedComponent/Layout";
import CalendarComponent from "../../../components/admin/calender";

const Calendar = () => {
  return (
    <Layout>
      <CalendarComponent />
    </Layout>
  );
};

export default Calendar;
