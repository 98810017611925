import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import CantieriComponent from '../../../components/admin/cantieri/index'

const CantieriDetails = () => {
  return (
    <Layout>
      <CantieriComponent />
    </Layout>
  )
}

export default CantieriDetails