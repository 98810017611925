import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import * as chatApi from '../../../apis/Chat';

import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { ScrollArea } from "../../ui/scroll-area";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../ui/avatar";
import { Separator } from "../../ui/separator";
import { ChevronLeft } from "lucide-react";

function ChatComponent() {
  const [chats, setChats] = useState([]);
  const [messages, setMessages] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [companyUsers, setCompanyUsers] = useState([]);
  const socketRef = useRef();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 640);
  const [showChatList, setShowChatList] = useState(true);

  useEffect(() => {
    const userStr = localStorage.getItem('user');
    if (userStr) {
      const user = JSON.parse(userStr);
      setUser(user);
      const companyId = user.companyId;

      socketRef.current = io(process.env.REACT_APP_SOCKET_URL || 'http://localhost:5000');
      socketRef.current.emit('join', user._id);

      socketRef.current.on('newMessage', (message) => {
        if (currentChat && currentChat._id === message.chatId) {
          setMessages(prevMessages => [...prevMessages, message]);
        }
        fetchChats();
      });

      fetchChats();
      fetchCompanyUsers(companyId);
    }

    const handleResize = () => setIsMobileView(window.innerWidth < 640);
    window.addEventListener('resize', handleResize);

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchChats = async () => {
    try {
      const data = await chatApi.getChats();
      setChats(data);
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  };

  const fetchMessages = async (chatId) => {
    try {
      const data = await chatApi.getMessages(chatId);
      setMessages(data);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const fetchCompanyUsers = async (companyName) => {
    try {
      const response = await chatApi.getCompanyUsers(companyName);
      if (response && Array.isArray(response.users)) {
        const filteredUsers = response.users.filter(u => u._id !== user._id);
        setCompanyUsers(filteredUsers);
      } else {
        setCompanyUsers([]);
      }
    } catch (error) {
      console.error('Error fetching company users:', error);
      setCompanyUsers([]);
    }
  };

  const handleChatClick = (chat) => {
    setCurrentChat(chat);
    fetchMessages(chat._id);
    if (isMobileView) setShowChatList(false);
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() && currentChat) {
      try {
        await chatApi.sendMessage(currentChat._id, newMessage);
        setNewMessage('');
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      await chatApi.deleteMessage(currentChat._id, messageId);
      setMessages(prevMessages => prevMessages.filter(msg => msg._id !== messageId));
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  const handleDeleteChat = async (chatId) => {
    try {
      await chatApi.deleteChat(chatId);
      setChats(prevChats => prevChats.filter(chat => chat._id !== chatId));
      if (currentChat && currentChat._id === chatId) {
        setCurrentChat(null);
        setMessages([]);
      }
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  const handleCreateChat = async (participantId) => {
    try {
      const newChat = await chatApi.createChat(participantId);
      setChats(prevChats => [...prevChats, newChat]);
      setShowModal(false);
    } catch (error) {
      console.error('Error creating chat:', error);
    }
  };

  return (
    <div className="flex flex-col sm:flex-row h-screen sm:h-[80vh] gap-4">
      {(!isMobileView || (isMobileView && showChatList)) && (
        <Card className="w-full sm:w-1/4 flex flex-col">
          <CardHeader>
            <CardTitle className="flex justify-between items-center">
              Tutte le chat
              <Dialog open={showModal} onOpenChange={setShowModal}>
                <DialogTrigger asChild>
                  <Button variant="outline" size="icon">+</Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Seleziona un'utente per iniziare una chat</DialogTitle>
                  </DialogHeader>
                  <div className="space-y-4">
                    {companyUsers.map(user => (
                      <div key={user._id} className="flex items-center justify-between">
                        <span>{user.name}</span>
                        <Button onClick={() => handleCreateChat(user._id)}>Chiudi</Button>
                      </div>
                    ))}
                  </div>
                </DialogContent>
              </Dialog>
            </CardTitle>
          </CardHeader>
          <CardContent className="flex-grow overflow-hidden">
            <ScrollArea className="h-full">
              {chats.map(chat => (
                <div key={chat._id} className="flex items-center p-2 mb-2 cursor-pointer hover:bg-gray-100 rounded" onClick={() => handleChatClick(chat)}>
                  <Avatar className="mr-3">
                    <AvatarImage src={chat.participants.find(p => p._id !== user._id).avatar} />
                    <AvatarFallback>{chat.participants.find(p => p._id !== user._id).name[0]}</AvatarFallback>
                  </Avatar>
                  <div className="flex-grow">
                    <h6 className="text-sm font-medium">{chat.participants.find(p => p._id !== user._id).name}</h6>
                    <span className="text-xs text-gray-500">Last message: {new Date(chat.lastMessage).toLocaleString()}</span>
                  </div>
                  <Button variant="destructive" size="sm" onClick={(e) => { e.stopPropagation(); handleDeleteChat(chat._id); }}>Delete</Button>
                </div>
              ))}
            </ScrollArea>
          </CardContent>
        </Card>
      )}

      {(!isMobileView || (isMobileView && !showChatList)) && (
        <Card className="w-full sm:w-3/4 flex flex-col">
          <CardHeader>
            <CardTitle className="flex items-center">
              {isMobileView && (
                <Button variant="ghost" onClick={() => setShowChatList(true)} className="mr-2">
                  <ChevronLeft className="h-4 w-4" />
                </Button>
              )}
              {currentChat?.participants.find(p => p._id !== user._id)?.name}
            </CardTitle>
          </CardHeader>
          <CardContent className="flex-grow overflow-hidden">
            <ScrollArea className="h-[calc(100vh-12rem)] sm:h-[calc(80vh-12rem)]">
              {messages.map((message) => (
                <div key={message._id} className={`flex items-start mb-4 ${message.sender === user._id ? 'flex-row-reverse' : ''}`}>
                  <Avatar className={`${message.sender === user._id ? 'ml-3' : 'mr-3'}`}>
                    <AvatarImage src="https://via.placeholder.com/50" />
                    <AvatarFallback>U</AvatarFallback>
                  </Avatar>
                  <div className={`p-3 rounded ${message.sender === user._id ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
                    <p className="text-sm">{message.content}</p>
                    <span className="text-xs text-gray-500">{new Date(message.timestamp).toLocaleString()}</span>
                  </div>
                  <Button variant="destructive" size="sm" className="ml-2" onClick={() => handleDeleteMessage(message._id)}>Delete</Button>
                </div>
              ))}
            </ScrollArea>
          </CardContent>
          <Separator />
          <CardContent className="p-4">
            <div className="flex items-center">
              <Input
                className="flex-grow mr-2"
                placeholder="Scrivi un messaggio..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
              />
              <Button onClick={handleSendMessage}>Invia</Button>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
}

export default ChatComponent;