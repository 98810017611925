import React, { createContext, useState, useEffect, useContext } from 'react';
import { fetchCurrentSubscription } from '../../apis/SubscriptionApi';

const SubscriptionContext = createContext();

export const useSubscription = () => useContext(SubscriptionContext);

export const SubscriptionProvider = ({ children }) => {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        // Check if user is logged in
        const isLoggedIn = localStorage.getItem('user') !== null;

        if (isLoggedIn) {
          const data = await fetchCurrentSubscription();
          setSubscription(data);
        }
      } catch (error) {
        console.error('Error fetching subscription:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscription();
  }, []);

  return (
    <SubscriptionContext.Provider value={{ subscription, loading, setSubscription }}>
      {children}
    </SubscriptionContext.Provider>
  );
};