import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../ui/tooltip";
import { Card, CardHeader, CardTitle, CardContent } from "../../../ui/card";

const Composition = ({ SharesData }) => {
  const data = SharesData?.map((item) => ({
    title: item.name,
    value: parseFloat(item.shares) || 0, // Use 0 if parsing fails
    color: item.labelColor,
  })).filter(item => item.value > 0); // Filter out items with 0 or NaN values

  const totalShares = data.reduce((sum, item) => sum + item.value, 0);

  if (data.length === 0 || totalShares === 0) {
    return (
      <Card className="w-full sm:w-1/3">
        <CardHeader>
          <CardTitle>Composizione azienda</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-center text-gray-500">Nessun dato disponibile</p>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full sm:w-1/3">
      <CardHeader>
        <CardTitle>Composizione azienda</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-2 mb-4">
          {data.map((item, index) => (
            <div key={index} className="flex items-center">
              <div
                className="h-3 w-3 mr-2 rounded-full"
                style={{ backgroundColor: item.color }}
              ></div>
              <span>{item.title}</span>
            </div>
          ))}
        </div>
        <TooltipProvider>
          <PieChart
            data={data}
            labelStyle={{
              fontSize: "5px",
              fontFamily: "sans-serif",
            }}
            radius={40}
            labelPosition={70}
            segmentsStyle={{ transition: "stroke .3s", cursor: "pointer" }}
            segmentsShift={1}
            animate
            label={({ dataEntry }) => {
              const percentage = (dataEntry.value / totalShares) * 100;
              return percentage > 0 ? `${Math.round(percentage)}%` : '';
            }}
          />
          {data.map((item, index) => (
            <Tooltip key={index}>
              <TooltipTrigger asChild>
                <div className="absolute" style={{
                  left: `${50 + 40 * Math.cos(-Math.PI / 2 + 2 * Math.PI * (index / data.length))}%`,
                  top: `${50 + 40 * Math.sin(-Math.PI / 2 + 2 * Math.PI * (index / data.length))}%`,
                  transform: 'translate(-50%, -50%)',
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  backgroundColor: 'transparent',
                }}></div>
              </TooltipTrigger>
              <TooltipContent>
                <p>{`${item.title}: ${item.value.toFixed(2)}`}</p>
              </TooltipContent>
            </Tooltip>
          ))}
        </TooltipProvider>
      </CardContent>
    </Card>
  );
};

export default Composition;