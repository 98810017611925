import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Edit, Trash2, Eye } from "lucide-react";
import { deleteEmployee, importEmployeesList } from "../../../../../apis/Employees";
import { exportToExcel, exportToPDF } from "../../../../../config/helper";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../ui/tooltip";
import { Button } from "../../../../ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../../ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../ui/table";

import SearchBar from "../../../sharedComponent/SearchBar";
import Confirmation from "../../../sharedComponent/Confirmation";
import Pagination from "../../../sharedComponent/Pgination";
import ImportFile from "../../../sharedComponent/ImportFile";
import HelpSheet from "../../../sharedComponent/HelpSheet";

const deleteContent = {
  title: "Elimina Dipendente",
  des: "Sei sicuro di voler eliminare questo dipendente? Questa azione è irreversibile.",
};

const AllEmployees = ({
  employeeData,
  setIsDelete,
  isDelete,
  setSearchQuery,
  currentPage,
  setCurrentPage,
  setLimit,
  totalPages,
  handlePageChange,
  limit,
  dataUpdate,
  setDataUpdate
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deletedId, setDeletedId] = useState(null);
  const [importModal, setImportModal] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user.companyId;

  const handleDeleteEmployee = async () => {
    setIsModalOpen(false);
    const response = await deleteEmployee(deletedId);
    if (response.status === 200) {
      toast.success(
        `${response.data?.employee.firstName} dipendente eliminato con successo`
      );
      setIsDelete(!isDelete);
    }
  };

  const handleSearch = async (query) => {
    setSearchQuery(query);
  };

  const handleExport = (value) => {
    const columns = [
      { header: "Nome", key: "firstName" },
      { header: "Cognome", key: "sureName" },
      { header: "Email", key: "email" },
      { header: "Posizione", key: "Cognometion" },
      { header: "Livello", key: "level" },
      { header: "Cellulare", key: "telephone" },
    ];

    if (value === "excel") {
      exportToExcel(employeeData, "Dipendenti");
    } else if (value === "pdf") {
      exportToPDF(employeeData, columns, "Lista Dipendenti");
    }
  };

  const handleAddEmployee = (value) => {
    if (value === "single") {
      navigate("/employees/add")
    } else if (value === "multiple") {
      setImportModal(true)
    }
  }

  const importEmployees = async (fileData) => {
    let payload = {
      companyId: companyId,
      employees: fileData
    };
    const response = await importEmployeesList(payload);
    if (response.status === 201) {
      toast.success("Dipendenti importati con successo!");
      setDataUpdate(!dataUpdate);
      setImportModal(false)
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:justify-between sm:items-center">
        <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:space-x-2">
          <Select onValueChange={handleAddEmployee} className="bg-[#06052C] text-primary-foreground hover:bg-[#06052C]/90">
            <SelectTrigger className="w-full sm:w-[200px] bg-[#06052C] text-primary-foreground hover:bg-[#06052C]/90">
              <SelectValue placeholder="Aggiungi dipendente" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="single">Crea nuovo dipendente</SelectItem>
              <SelectItem value="multiple">Importa dipendenti</SelectItem>
            </SelectContent>
          </Select>
          <Select onValueChange={handleExport} >
            <SelectTrigger className="w-full sm:w-[150px]">
              <SelectValue placeholder="Esporta" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="excel">Esporta in Excel</SelectItem>
              <SelectItem value="pdf">Esporta in PDF</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="md:w-1/6 flex justify-center items-center gap-x-2">
          <SearchBar onSearch={handleSearch} />
          <HelpSheet route="/employees" />
        </div>
      </div>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-center">Nome</TableHead>
              <TableHead className="text-center">Cognome</TableHead>
              <TableHead className="text-center">Email</TableHead>
              <TableHead className="text-center">Mansione</TableHead>
              <TableHead className="text-center">Qualifica</TableHead>
              <TableHead className="text-center">Cellulare</TableHead>
              <TableHead className="text-center">Azioni</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {employeeData?.length > 0 ? (
              employeeData?.map((data, index) => (
                <TableRow key={index}>
                  <TableCell className="text-center">{data.firstName}</TableCell>
                  <TableCell className="text-center">{data.sureName}</TableCell>
                  <TableCell className="text-center">{data.email}</TableCell>
                  <TableCell className="text-center">{data.task}</TableCell>
                  <TableCell className="text-center">{data.qualification}</TableCell>
                  <TableCell className="text-center">{data.telephone}</TableCell>
                  <TableCell className="text-center">
                    <div className="flex justify-center space-x-2">
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => navigate(`/employees/edit/${data._id}`)}
                            >
                              <Eye className="h-4 w-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Modifica</p>
                          </TooltipContent>
                        </Tooltip>

                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => {
                                setIsModalOpen(true);
                                setDeletedId(data._id);
                              }}
                            >
                              <Trash2 className="h-4 w-4 text-red-600" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Elimina</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} className="text-center py-8">
                  Nessun dato disponibile
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {employeeData?.length > 0 && (
        <div className="flex justify-end">
          <Pagination
            totalPages={totalPages}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
            setLimit={setLimit}
          />
        </div>
      )}

      <Confirmation
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        setIsModalOpen={setIsModalOpen}
        deleteFunction={handleDeleteEmployee}
        deleteContent={deleteContent}
      />

      <ImportFile
        isOpen={importModal}
        onClose={() => setImportModal(false)}
        setIsModalOpen={setIsModalOpen}
        setDataUpdate={setDataUpdate}
        dataUpdate={dataUpdate}
        importFunction={importEmployees}
        vendorType="Dipendenti"
      />
    </div>
  );
};

export default AllEmployees;