import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updatePassword } from '../../../../apis/ProfilioEdit';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../../ui/dialog";
import { Button } from "../../../ui/button";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";

function EditPasswordModal({ isOpen, onClose, id }) {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [vecchiapassword, setVecchiaPassword] = useState('');
  const [confermarepassword, setConfermarePassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSave = async () => {
    if (password !== confermarepassword) {
      setError("Le password non coincidono.");
      return;
    }
  
    setLoading(true);
    setError(null);
    try {
      const response = await updatePassword(id, { vecchiapassword, password, confermarepassword });

      if (response.status === 200) {
        toast.success("Password aggiornata con successo");
        navigate(-1);
        onClose();
      }
    } catch (error) {
      setError(error.message);
      toast.error("Errore nell'aggiornamento della password");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Modifica Password</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-1 items-center gap-4">
            <Label htmlFor="password" className="text-left">
              Vecchia Password
            </Label>
            <Input
              id="vecchiapassword"
              type="password"
              value={vecchiapassword}
              onChange={(e) => setVecchiaPassword(e.target.value)}
              className="col-span-3"
              placeholder="Inserisci la nuova password"
            />
          </div>
          {error && (
            <p className="text-red-500 text-sm">{error}</p>
          )}
        </div>


        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-1 items-center gap-4">
            <Label htmlFor="password" className="text-left">
              Nuova Password
            </Label>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="col-span-3"
              placeholder="Inserisci la nuova password"
            />
          </div>
          {error && (
            <p className="text-red-500 text-sm">{error}</p>
          )}
        </div>



        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-1 items-center gap-4">
            <Label htmlFor="password" className="text-left">
              Confermare Password
            </Label>
            <Input
              id="confermarepassword"
              type="password"
              value={confermarepassword}
              onChange={(e) => setConfermarePassword(e.target.value)}
              className="col-span-3"
              placeholder="Inserisci la nuova password"
            />
          </div>
          {error && (
            <p className="text-red-500 text-sm">{error}</p>
          )}
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Annulla
          </Button>
          <Button onClick={handleSave} disabled={loading}>
            {loading ? "Salvataggio..." : "Salva"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default EditPasswordModal;