import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';

// Register custom fonts
Font.register({
  family: 'Helvetica',
  fonts: [
    { src: 'https://fonts.gstatic.com/s/helvetica/v16/1Ptsg8zYS_SKggPNyCg4QIFqPfE.ttf' },
    { src: 'https://fonts.gstatic.com/s/helvetica/v16/1Ptsg8zYS_SKggPNyCg4TYFqPfE.ttf', fontWeight: 'bold' },
  ]
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 30,
    fontFamily: 'Helvetica',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 20,
  },
  logo: {
    width: 150,
    height: 75,
    objectFit: 'contain',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '20%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: '#f0f0f0',
  },
  tableCol: {
    width: '20%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    marginBottom: 5,
    fontSize: 8,
  },
  companyInfo: {
    fontSize: 10,
    marginBottom: 10,
  },
});

const PreventiviTablePDFExport = ({ data, company }) => {
  if (!data || !company) {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <Text>Error: Insufficient data to generate PDF</Text>
        </Page>
      </Document>
    );
  }

  const getLogoUrl = (logoPath) => {
    if (!logoPath) return null;
    return `http://localhost:5000/${logoPath.replace(/\\/g, '/')}`;
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          {company.logo1 && <Image style={styles.logo} src={getLogoUrl(company.logo1)} />}
          <View>
            <Text style={styles.companyInfo}>{company.companyName}</Text>
            <Text style={styles.companyInfo}>{company.address}, {company.city}</Text>
            <Text style={styles.companyInfo}>P.IVA: {company.taxIdCode}</Text>
          </View>
        </View>

        <Text style={styles.title}>Lista Preventivi</Text>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Numero</Text></View>
            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Data</Text></View>
            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Cliente</Text></View>
            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Oggetto</Text></View>
            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Totale</Text></View>
          </View>
          {data.map((item, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{item.numero}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{item.dataa}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{item.clienti?.fullName}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{item.ogguto}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>€{item.totaleval}</Text></View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default PreventiviTablePDFExport;