import { createJsonTypeInstance } from './index';
const axios = createJsonTypeInstance();

export const fetchMovimenti = async (companyId, currentPage, limit) => {
  try {
    const response = await axios.get(`/movimenti/${companyId}?page=${currentPage}&limit=${limit}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const addMovimenti = async (data) => {
  try {
    const response = await axios.post('/movimenti' , data);
    return response;
  } catch (error) {
    throw error.response.data; 
  }
};

export const updateMovimenti = async (id, data) => {
  try {
    const response = await axios.put(`/movimenti/${id}`, data);
    return response;
  } catch (error) {
    throw error.response.data; 
  }
};

export const sumEntra = async (companyId) =>{
  try {
    const response = await axios.get(`/movimenti/sumEntra/${companyId}`);
    return response;
  } catch (error) {
    throw error.response.data; 
  }
};

export const sumEntraPrevious = async (companyId) =>{
  try {
    const response = await axios.get(`/movimenti/sumEntraPrevious/${companyId}`);
    return response;
  } catch (error) {
    throw error.response.data; 
  }
};

export const sumUscita = async (companyId) =>{
  try {
    const response = await axios.get(`/movimenti/sumUscita/${companyId}`);
    return response;
  } catch (error) {
    throw error.response.data; 
  }
};

export const sumUscitaPrevious = async (companyId) =>{
  try {
    const response = await axios.get(`/movimenti/sumUscitaPrevious/${companyId}`);
    console.log(`sumUscitaPrevious: ${companyId}`);
    return response;
  } catch (error) {
    throw error.response.data; 
  }
};


export const sumAll = async (companyId) =>{
  try {
    const response = await axios.get(`/movimenti/sumAll/${companyId}`);
    return response;
  } catch (error) {
    throw error.response.data; 
  }
};

export const sumAllPrevious = async (companyId) =>{
  try {
    const response = await axios.get(`/movimenti/sumAllPrevious/${companyId}`);
    return response;
  } catch (error) {
    throw error.response.data; 
  }
};

export const importMovimenti = async (formData) => {
  try {
    const response = await axios.post('/movimenti/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const fetchMonthlyMovimenti = async (companyId) => {
  try {
    const response = await axios.get(`/movimenti/monthly/${companyId}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const deleteMovimenti = async (id) => {
  try {
    const response = await axios.delete(`/movimenti/${id}`);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
