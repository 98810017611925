import {createJsonTypeInstance} from './index';
const axios = createJsonTypeInstance();

export const getUserInfo = async (username) => {
  const axiosInstance = createJsonTypeInstance();
  try {
    const response = await axiosInstance.get(`/user/info/${username}`);
    return response;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const updateUserProfile = async (userId, updateData) => {
  try {
    const response = await axios.put(`/user/${userId}`, updateData);
    return response;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};