import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tabs, TabsList, TabsTrigger } from "../../ui/tabs";

const TABS = [
  { name: "Preferenze", route: "/impostazioni" },
  { name: "Status Preventivi", route: "/status_preventivi" }
];

function TableComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("/status_preventivi");

  useEffect(() => {
    const currentTab = TABS.find(tab => location.pathname.startsWith(tab.route));
    if (currentTab) {
      setActiveTab(currentTab.route);
    }
  }, [location]);

  const handleTabChange = (value) => {
    navigate(value);
  };

  return (
    <div className="ml-10 mr-7">
      <Tabs value={activeTab} onValueChange={handleTabChange} className="w-full">
        <TabsList className="bg-[#06052C] rounded-none w-full flex justify-start h-auto">
          {TABS.map((tab) => (
            <TabsTrigger
              key={tab.name}
              value={tab.route}
              className="py-2 px-4 text-white data-[state=active]:bg-white data-[state=active]:text-blue-950"
            >
              {tab.name}
            </TabsTrigger>
          ))}
        </TabsList>
      </Tabs>

      {/* Uncomment if you want to include the search bar
      <div className="flex my-7 justify-between">
        <div className="w-[60%] flex space-x-2">
        </div>
        <div className="w-[40%] flex space-x-2">
          <SearchBar />
        </div>
      </div>
      */}

      <div className="flex flex-wrap my-6">
        <div className="w-full md:w-1/4 py-4 bg-gray-200">
          <h6 className="text-base font-semibold">Preventivo in corso</h6>
        </div>
        <div className="w-full md:w-1/4 py-4 bg-gray-200">
          <h6 className="text-base font-semibold">Preventivo inviato</h6>
        </div>
        <div className="w-full md:w-1/4 py-4 bg-gray-200">
          <h6 className="text-base font-semibold">Preventivo accettato</h6>
        </div>
        <div className="w-full md:w-1/4 py-4 bg-gray-200">
          <h6 className="text-base font-semibold">Preventivo rifiutato</h6>
        </div>
      </div>
        
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/4">
          <div className="flex flex-wrap">
            <div className="w-full md:w-3/4 my-2">
              <div className="card p-1">
                <h6 className="text-base font-semibold">Preventivo n.12</h6>
                <p className="text-gray-500 p-0 m-0">676787</p>
                <div className="text-gray-500 w-full text-right">2024-06-04</div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/4">
          <div className="flex flex-wrap">
          </div>
        </div>

        <div className="w-full md:w-1/4">
          <div className="flex flex-wrap">
          </div>
        </div>

        <div className="w-full md:w-1/4">
          <div className="flex flex-wrap">
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableComponent;