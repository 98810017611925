import { createJsonTypeInstance } from './index';
const axios = createJsonTypeInstance();

export const getChats = async () => {
  try {
    const response = await axios.get('/chats');
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const getMessages = async (chatId) => {
  try {
    const response = await axios.get(`/chats/messages/${chatId}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const getCompanyUsers = async (companyId) => {
  try {
    const response = await axios.get(`/chats/company`, { params: { companyId } });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const sendMessage = async (chatId, content) => {
  try {
    const response = await axios.post(`/chats/${chatId}/messages`, { content });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteMessage = async (chatId, messageId) => {
  try {
    const response = await axios.delete(`/chats/${chatId}/messages/${messageId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const createChat = async (participantId) => {
  try {
    const response = await axios.post('/chats', { participantId });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteChat = async (chatId) => {
  try {
    const response = await axios.delete(`/chats/${chatId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};