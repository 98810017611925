import { createJsonTypeInstance } from "../apis";

const api = createJsonTypeInstance();

export const vehicleService = {
  getAllVehicles: async (companyId) => {
    if (!companyId) {
      throw new Error("Company ID is required");
    }
    try {
      const response = await api.get(`/vehicles?companyId=${companyId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getVehicleById: async (id, companyId) => {
    if (!companyId) {
      throw new Error("Company ID is required");
    }
    try {
      const response = await api.get(`/vehicles/${id}?companyId=${companyId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  addVehicle: async (vehicleData) => {
    try {
      const response = await api.post('/vehicles', vehicleData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateVehicle: async (id, vehicleData) => {
    try {
      const response = await api.put(`/vehicles/${id}`, vehicleData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  deleteVehicle: async (id, companyId) => {
    try {
      const response = await api.delete(`/vehicles/${id}?companyId=${companyId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  searchVehicles: async (query, companyId) => {
    try {
      const response = await api.get(`/vehicles/search?query=${query}&companyId=${companyId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  filterVehicles: async (filters, companyId) => {
    try {
      const queryString = new URLSearchParams({...filters, companyId}).toString();
      const response = await api.get(`/vehicles/filter?${queryString}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};