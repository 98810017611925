import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import FlottaDetailsComponent from '../../../components/admin/flotta/flotta_details'

const FlottaDetails = () => {
  return (
    <Layout>
      <FlottaDetailsComponent />
    </Layout>
  )
}

export default FlottaDetails