import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../../components/admin/sharedComponent/Layout';
import {
  fetchCurrentSubscription,
  cancelSubscription,
  initiateStripeCheckout,
  changePlan,
  fetchPlans,
  resetSubscription
} from '../../apis/SubscriptionApi';

import { Button } from "../../components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../components/ui/card";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Loader2 } from "lucide-react";
import { Badge } from "../../components/ui/badge";
import { getUserInfo } from '../../apis/UserApi';

const SubscriptionPage = () => {
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [selectedInterval, setSelectedInterval] = useState('month');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.success) {
      setSuccessMessage('Abbonamento aggiornato con successo!');
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  const fetchSubscriptionData = useCallback(async () => {
    try {
      const [subscriptionData, plansData] = await Promise.all([
        fetchCurrentSubscription(),
        fetchPlans()
      ]);
      setCurrentSubscription(subscriptionData);
      setPlans(plansData);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Errore nel caricamento dei dati. Per favore, riprova più tardi.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSubscriptionData();
  }, [fetchSubscriptionData]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('success') === 'true') {
      if (searchParams.get('trial') === 'true') {
        setSuccessMessage('Abbonamento attivato con successo! Il tuo periodo di prova gratuito di 30 giorni è iniziato.');
      } else {
        setSuccessMessage('Abbonamento attivato con successo!');
      }
      navigate('/subscription', { replace: true });
      fetchSubscriptionData();
    } else if (searchParams.get('error') === 'true') {
      setError('Si è verificato un errore durante l\'attivazione dell\'abbonamento. Per favore, riprova.');
      navigate('/subscription', { replace: true });
    }
  }, [location, navigate, fetchSubscriptionData]);

  const handleSubscribe = async (priceId) => {
    try {
      setLoading(true);
      const { checkoutUrl } = await initiateStripeCheckout(priceId);
      if (checkoutUrl) {
        window.location.href = checkoutUrl;
      } else {
        throw new Error('Checkout URL not received');
      }
    } catch (error) {
      console.error('Error initiating checkout:', error);
      setError('Si è verificato un errore durante l\'avvio del processo di pagamento. Per favore, riprova.');
    } finally {
      setLoading(false);
    }
  };

  const handleChangePlan = async (priceId) => {
    try {
      setLoading(true);
      const response = await changePlan(priceId);
      setSuccessMessage('Piano cambiato con successo. Le modifiche saranno effettive dal prossimo ciclo di fatturazione.');
      setCurrentSubscription(response.subscription);
    } catch (error) {
      console.error('Error changing plan:', error);
      setError(error.response?.data?.message || 'Si è verificato un errore durante il cambio del piano. Per favore, riprova.');
    } finally {
      setLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    setIsModalOpen(true);
  };

  const refreshUserData = useCallback(async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.username) {
        const updatedUserInfo = await getUserInfo(user.username);
        const updatedUser = { ...user, ...updatedUserInfo.data };
        localStorage.setItem('user', JSON.stringify(updatedUser));
      }
    } catch (error) {
      console.error('Error refreshing user data:', error);
    }
  }, []);

  const confirmCancelSubscription = async () => {
    try {
      setLoading(true);
      const response = await cancelSubscription();
      setCurrentSubscription(response.subscription);
      setSuccessMessage(`Abbonamento cancellato con successo. Il tuo piano rimarrà attivo fino al ${new Date(response.subscription.currentPeriodEnd).toLocaleDateString()}.`);
      await refreshUserData();
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      setError(error.response?.data?.message || 'Errore nella cancellazione dell\'abbonamento. Per favore, riprova.');
    } finally {
      setLoading(false);
      setIsModalOpen(false);
    }
  };

  const handleResetSubscription = async () => {
    try {
      setLoading(true);
      await resetSubscription();
      setSuccessMessage('Abbonamento resettato con successo.');
      await refreshUserData();
      fetchSubscriptionData();
    } catch (error) {
      console.error('Error resetting subscription:', error);
      setError('Si è verificato un errore durante il reset dell\'abbonamento. Per favore, riprova.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePaymentMethod = () => {
    console.log('Update payment method');
  };

  const renderSubscriptionStatus = () => {
    if (currentSubscription?.status === 'past_due' && currentSubscription.retryStatus) {
      return (
        <Alert variant="destructive" className="mt-4">
          <AlertTitle>Pagamento fallito</AlertTitle>
          <AlertDescription>
            <p>Il pagamento del tuo abbonamento non è andato a buon fine. Proveremo nuovamente il {new Date(currentSubscription.retryStatus.nextRetryDate).toLocaleDateString()}.</p>
            <p>Tentativi effettuati: {currentSubscription.retryStatus.attemptsMade + 1} di 3</p>
            <Button onClick={handleUpdatePaymentMethod} className="mt-2">Aggiorna metodo di pagamento</Button>
          </AlertDescription>
        </Alert>
      );
    }
    return null;
  };

  const getDisplayStatus = (subscription) => {
    const now = new Date();
    const trialEndDate = new Date(subscription.trialEndDate);
    const currentPeriodEnd = new Date(subscription.currentPeriodEnd);

    if (subscription.status === 'canceled') {
      if (now < trialEndDate) {
        return 'In prova (Cancellazione programmata)';
      } else if (now < currentPeriodEnd) {
        return 'Attivo (Cancellazione programmata)';
      } else {
        return 'Cancellato';
      }
    } else if (subscription.status === 'trialing') {
      return 'In prova';
    } else {
      return 'Attivo';
    }
  };

  if (loading) return (
    <Layout>
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    </Layout>
  );

  if (error) return (
    <Layout>
      <Alert variant="destructive">
        <AlertTitle>Errore</AlertTitle>
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    </Layout>
  );

  const isSubscribed = currentSubscription && currentSubscription.planType !== 'free';
  const isSubscriptionActive = isSubscribed && currentSubscription.status !== 'canceled';
  const isCanceled = currentSubscription && currentSubscription.status === 'canceled';
  const isTrialing = currentSubscription && currentSubscription.status === 'trialing';

  return (
    <Layout>
      <div className="p-6">
        <h1 className="text-3xl font-bold mb-6 text-center">Gestione Abbonamento</h1>

        {successMessage && (
          <Alert className="mb-4">
            <AlertTitle>Successo</AlertTitle>
            <AlertDescription>{successMessage}</AlertDescription>
          </Alert>
        )}
        

        <Card className="mb-8">
          <CardHeader>
            <CardTitle>
              Piano Attuale: {currentSubscription ? currentSubscription.planType.charAt(0).toUpperCase() + currentSubscription.planType.slice(1) : 'Nessun abbonamento attivo'}
            </CardTitle>
          </CardHeader>
          <CardContent>
            {currentSubscription && (
              <>
                <p className="mb-2">Stato: {getDisplayStatus(currentSubscription)}</p>
                {currentSubscription.cancelAtPeriodEnd && (
                  <p className="mb-2">Cancellazione programmata per: {new Date(currentSubscription.currentPeriodEnd).toLocaleDateString()}</p>
                )}
                {isCanceled ? (
                  <p className="mb-4">Il tuo abbonamento rimarrà attivo fino al: {new Date(currentSubscription.currentPeriodEnd).toLocaleDateString()}</p>
                ) : (
                  <p className="mb-4">Prossimo rinnovo: {new Date(currentSubscription.currentPeriodEnd).toLocaleDateString()}</p>
                )}
                {isTrialing && (
                  <Badge variant="secondary" className="mb-4">
                    Periodo di prova: termina il {new Date(currentSubscription.trialEndDate).toLocaleDateString()}
                  </Badge>
                )}
                {renderSubscriptionStatus()}
              </>
            )}
            {!currentSubscription && (
              <p>Non hai attualmente un abbonamento attivo. Scegli un piano qui sotto per iniziare.</p>
            )}
          </CardContent>
          {isSubscriptionActive && (
            <CardFooter>
              <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
                <DialogTrigger asChild>
                  <Button variant="destructive">Cancella Abbonamento</Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Conferma Cancellazione</DialogTitle>
                    <DialogDescription>
                      Sei sicuro di voler cancellare il tuo abbonamento? Il tuo piano rimarrà attivo fino alla fine del periodo di fatturazione corrente.
                    </DialogDescription>
                  </DialogHeader>
                  <DialogFooter>
                    <Button variant="outline" onClick={() => setIsModalOpen(false)}>Annulla</Button>
                    <Button variant="destructive" onClick={confirmCancelSubscription}>Conferma</Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            </CardFooter>
          )}
        </Card>

        <h2 className="text-2xl font-semibold mb-4">
          {isSubscriptionActive ? 'Cambia Piano di Abbonamento' : 'Piani di Abbonamento Disponibili'}
        </h2>

        <Tabs value={selectedInterval} onValueChange={setSelectedInterval} className="mb-6">
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="month">Mensile</TabsTrigger>
            <TabsTrigger value="quarter">Trimestrale</TabsTrigger>
            <TabsTrigger value="year">Annuale</TabsTrigger>
          </TabsList>
        </Tabs>

        <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {plans.map((plan) => (
            <Card key={plan.id}>
              <CardHeader>
                <CardTitle>{plan.name}</CardTitle>
                {plan.prices[selectedInterval] && (
                  <CardDescription>
                    €{(plan.prices[selectedInterval].unit_amount / 100).toFixed(2)}
                    /{selectedInterval === 'quarter' ? 'trimestre' : selectedInterval === 'year' ? 'anno' : 'mese'}
                  </CardDescription>
                )}
              </CardHeader>
              <CardContent>
                {plan.features && (
                  <ul className="mb-4 list-disc pl-5">
                    <li>Spazio di archiviazione: {plan.features.cloudStorageSpace} GB</li>
                    <li>Utenti: {plan.features.users}</li>
                    <li>Chat: {plan.features.chat ? '✓' : '✗'}</li>
                    <li>Movimenti: {plan.features.movimentsPerMonth}</li>
                    <li>Gestione clienti: {plan.features.customerManagement}</li>
                    <li>Gestione fornitori: {plan.features.supplierManagement}</li>
                    <li>Gestione flotta: {plan.features.fleetManagement ? '✓' : '✗'}</li>
                    <li>Cantieri: {plan.features.cantieri}</li>
                    <li>Supporto: {plan.features.support}</li>
                  </ul>
                )}
                {!isSubscribed && <p className="text-sm text-gray-600 mb-4">Include 30 giorni di prova gratuita</p>}
              </CardContent>
              <CardFooter>
                {plan.prices[selectedInterval] && (
                  currentSubscription?.planType !== plan.name.toLowerCase() ? (
                    <Button
                      onClick={() => isSubscriptionActive ?
                        handleChangePlan(plan.prices[selectedInterval].id) :
                        handleSubscribe(plan.prices[selectedInterval].id)}
                      disabled={loading}
                    >
                      {loading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
                      {isTrialing ? 'Upgrade' : isSubscriptionActive ? 'Cambia Piano' : isCanceled ? 'Riattiva' : 'Sottoscrivi'}
                    </Button>
                  ) : (
                    <Badge variant="secondary">Piano Attuale</Badge>
                  )
                )}
              </CardFooter>
            </Card>
          ))}
        </div>
        <div className="mt-8 text-center">
          <Button onClick={handleResetSubscription} variant="outline">Reset Subscription (Debug)</Button>
        </div>
      </div>
    </Layout>
  );
};

export default SubscriptionPage;