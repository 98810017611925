import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getConstructionSites, createConstructionSite, deleteConstructionSite } from '../../../apis/ConstructionSite';
import { Edit, Trash, Plus, Eye } from "lucide-react";
import { toast } from 'react-toastify';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../ui/tooltip";
import { ScrollArea } from "../../ui/scroll-area";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { Textarea } from "../../ui/textarea";

import SearchBar from '../sharedComponent/SearchBar';
import Pagination from '../sharedComponent/Pgination';
import HelpSheet from '../sharedComponent/HelpSheet';
import { exportToExcel, exportToPDF } from "../../../config/helper";

const getUserFromLocalStorage = () => {
  const userString = localStorage.getItem('user');
  if (!userString) return null;

  const user = JSON.parse(userString);
  if (!user.companyId) {
    console.warn('User does not have a companyId');
    user.companyId = 'default';
  }
  return user;
};

function Cantieri() {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [siteToDelete, setSiteToDelete] = useState(null);
  const [sites, setSites] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [clients, setClients] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [filteredSites, setFilteredSites] = useState([]);
  const [password, setPassword] = useState('');
  const [deleteError, setDeleteError] = useState('');
  const [newSite, setNewSite] = useState({
    name: '',
    client: '',
    address: '',
    amount: '',
    workType: '',
    description: '',
    city: '',
    postalCode: '',
    province: '',
    country: '',
    featuredImage: null,
    openingDate: '',
    closingDate: '',
    status: 'In pianificazione'
  });

  const [isAdding, setIsAdding] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchSites();
  }, [currentPage, limit, searchTerm]);

  const fetchSites = async () => {
    try {
      const user = getUserFromLocalStorage();
      if (!user) {
        console.error('User information not found');
        return;
      }
      const skip = (currentPage - 1) * limit;
      const response = await getConstructionSites(user.companyId, currentPage, limit);

      const limitedSites = response.data.sites.slice(0, limit);

      setSites(limitedSites);
      setFilteredSites(limitedSites);
      setClients(response.data.clients);
      setTotalPages(Math.ceil(response.data.totalItems / limit));
      setTotalItems(response.data.totalItems);
    } catch (error) {
      console.error('Error fetching construction sites:', error);
      toast.error('Error loading construction sites');
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
    if (term.length >= 3) {
      const filtered = sites.filter(site =>
        site.name?.toLowerCase().includes(term.toLowerCase()) ||
        site.client?.fullName?.toLowerCase().includes(term.toLowerCase()) ||
        site.address?.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredSites(filtered);
    } else {
      setFilteredSites(sites);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsAdding(true);

    try {
      const user = getUserFromLocalStorage();
      if (!user || !user.companyId) {
        throw new Error('User or company information not found');
      }

      // Validate required fields
      const requiredFields = [
        'name', 'client', 'address', 'amount', 'workType',
        'city', 'postalCode', 'province', 'country', 'openingDate'
      ];

      const missingFields = requiredFields.filter(field => !newSite[field]);

      if (missingFields.length > 0) {
        throw new Error(`Please fill in all required fields: ${missingFields.join(', ')}`);
      }

      // Create FormData object
      const formData = new FormData();

      // Append all fields to FormData
      Object.keys(newSite).forEach(key => {
        if (key === 'featuredImage' && newSite[key] instanceof File) {
          formData.append(key, newSite[key]);
        } else if (newSite[key] !== null && newSite[key] !== undefined) {
          formData.append(key, newSite[key].toString());
        }
      });

      // Add required fields that might be missing
      formData.append('companyId', user.companyId);
      formData.append('totalAmount', newSite.amount);

      // Make API call
      await createConstructionSite(formData);

      // Handle success
      toast.success('Construction site created successfully!');
      setIsModalOpen(false);

      // Reset form
      setNewSite({
        name: '',
        client: '',
        address: '',
        amount: '',
        workType: '',
        description: '',
        city: '',
        postalCode: '',
        province: '',
        country: '',
        featuredImage: null,
        openingDate: '',
        closingDate: '',
        status: 'In pianificazione'
      });

      // Refresh the list
      fetchSites();

    } catch (error) {
      console.error('Error creating construction site:', error);
      toast.error(error.message || 'Error creating construction site');
    } finally {
      setIsAdding(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === 'file') {
      setNewSite(prev => ({
        ...prev,
        [name]: files[0]
      }));
    } else {
      setNewSite(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSelectChange = (name, value) => {
    setNewSite(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDeleteClick = (site) => {
    setSiteToDelete(site);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    setIsDeleting(true);
    setDeleteError('');
    try {
      await deleteConstructionSite(siteToDelete._id, password);
      toast.success('Construction site deleted successfully');
      fetchSites();
      setDeleteModalOpen(false);
      setPassword('');
      setSiteToDelete(null);
    } catch (error) {
      setDeleteError(error.message || 'Password non valida');
      toast.error(error.message || 'Error deleting construction site');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleExport = (value) => {
    const columns = [
      { header: "Numero", key: "id" },
      { header: "Nome", key: "name" },
      { header: "Indirizzo", key: "address" },
    ];

    if (value === "excel") {
      exportToExcel(filteredSites, "cantieri");
    } else if (value === "pdf") {
      exportToPDF(filteredSites, columns, "Elenco Cantieri");
    }
  };

  return (
    <div className="md:p-6 p-2 space-y-6">
      <div className="flex justify-between items-center">
        <div className="grid grid-cols-2 gap-2 sm:flex sm:flex-row sm:items-center">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button onClick={() => setIsModalOpen(true)} disabled={isAdding}>
                  {isAdding ? 'Aggiungendo...' : 'Aggiungi'}
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Aggiungi nuovo cantiere</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Select onValueChange={handleExport}>
                  <SelectTrigger className="w-full sm:w-[180px]">
                    <SelectValue placeholder="Esporta" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="excel">Esporta in Excel</SelectItem>
                    <SelectItem value="pdf">Esporta in PDF</SelectItem>
                  </SelectContent>
                </Select>
              </TooltipTrigger>
              <TooltipContent>
                <p>Esporta dati</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="md:w-1/6 flex justify-center items-center gap-x-2">
          <SearchBar onSearch={handleSearch} />
          <HelpSheet route="/cantieri" />
        </div>
      </div>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Numero</TableHead>
              <TableHead>Nome</TableHead>
              <TableHead>Committente</TableHead>
              <TableHead>Indirizzo</TableHead>
              <TableHead>Stato</TableHead>
              <TableHead>Azioni</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredSites.length > 0 ? (
              filteredSites.map((site, index) => (
                <TableRow key={site._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{site.name}</TableCell>
                  <TableCell>{site.client?.fullName}</TableCell>
                  <TableCell>{site.address}</TableCell>
                  <TableCell>{site.status || 'In pianificazione'}</TableCell>
                  <TableCell>
                    <div className="flex space-x-2">
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Link to={`/cantieri/${site._id}/informazioni`}>
                              <Button variant="ghost" size="sm">
                                <Eye className="h-4 w-4" />
                              </Button>
                            </Link>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Visualizza dettagli</p>
                          </TooltipContent>
                        </Tooltip>

                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => handleDeleteClick(site)}
                            >
                              <Trash className="h-4 w-4 text-red-500" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Elimina cantiere</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} className="text-center">
                  Nessun cantiere trovato.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {filteredSites?.length > 0 && (
        <Pagination
          totalPages={totalPages}
          totalItems={totalItems}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}

      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent className="sm:max-w-[700px] max-h-[90vh]">
          <DialogHeader>
            <DialogTitle>Aggiungi Nuovo Cantiere</DialogTitle>
          </DialogHeader>
          <ScrollArea className="h-[500px] pr-4">
            <form onSubmit={handleSubmit} className="space-y-6 p-2">
              {/* Basic Information */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="col-span-1">
                  <Label htmlFor="name" className="required">Nome Cantiere</Label>
                  <Input
                    id="name"
                    name="name"
                    value={newSite.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-span-1">
                  <Label htmlFor="client" className="required">Committente</Label>
                  <Select
                    name="client"
                    value={newSite.client}
                    onValueChange={(value) => handleSelectChange('client', value)}
                    required
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Seleziona committente" />
                    </SelectTrigger>
                    <SelectContent>
                      {clients.map((client) => (
                        <SelectItem key={client._id} value={client._id}>
                          {client.typology === 'Private' ? client.fullName : client.companyName}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                {/* Date fields */}
                <div className="col-span-1">
                  <Label htmlFor="openingDate" className="required">Data Apertura</Label>
                  <Input
                    type="date"
                    id="openingDate"
                    name="openingDate"
                    value={newSite.openingDate}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-span-1">
                  <Label htmlFor="closingDate">Data Chiusura</Label>
                  <Input
                    type="date"
                    id="closingDate"
                    name="closingDate"
                    min={newSite.openingDate}
                    value={newSite.closingDate}
                    onChange={handleInputChange}
                  />
                </div>

                {/* Amount and Work Type */}
                <div className="col-span-1">
                  <Label htmlFor="amount" className="required">Importo €</Label>
                  <Input
                    type="number"
                    id="amount"
                    name="amount"
                    value={newSite.amount}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="col-span-1">
                  <Label htmlFor="workType" className="required">Tipologia di lavoro</Label>
                  <Select
                    name="workType"
                    value={newSite.workType}
                    onValueChange={(value) => handleSelectChange('workType', value)}
                    required
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Seleziona tipologia" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="Manutenzione ordinaria">Manutenzione ordinaria</SelectItem>
                      <SelectItem value="Manutenzione straordinaria">Manutenzione straordinaria</SelectItem>
                      <SelectItem value="Restauro">Restauro</SelectItem>
                      <SelectItem value="Risanamento conservativo">Risanamento conservativo</SelectItem>
                      <SelectItem value="Ristrutturazione edilizia">Ristrutturazione edilizia</SelectItem>
                      <SelectItem value="Nuova costruzione">Nuova costruzione</SelectItem>
                      <SelectItem value="Ristrutturazione urbanistica">Ristrutturazione urbanistica</SelectItem>
                      <SelectItem value="Lavori stradali">Lavori stradali</SelectItem>
                      <SelectItem value="Impianti fognari">Impianti fognari</SelectItem>
                      <SelectItem value="Movimento terra">Movimento terra</SelectItem>
                      <SelectItem value="Impiantistica">Impiantistica</SelectItem>
                      <SelectItem value="Bonifica">Bonifica</SelectItem>
                      <SelectItem value="Demolizione">Demolizione</SelectItem>
                      <SelectItem value="Edilizia industrializzata">Edilizia industrializzata</SelectItem>
                      <SelectItem value="Opere idrauliche">Opere idrauliche</SelectItem>
                      <SelectItem value="Impianti idroelettrici">Impianti idroelettrici</SelectItem>
                      <SelectItem value="Opere marittime">Opere marittime</SelectItem>
                      <SelectItem value="Lavori ferroviari">Lavori ferroviari</SelectItem>
                      <SelectItem value="Lavori di linee e condotte">Lavori di linee e condotte</SelectItem>
                      <SelectItem value="Lavori al sottosuolo">Lavori al sottosuolo</SelectItem>
                      <SelectItem value="Lavori speciali">Lavori speciali</SelectItem>
                      <SelectItem value="Altro">Altro</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                {/* Featured Image */}
                <div className="col-span-2">
                  <Label htmlFor="featuredImage">Immagine in evidenza</Label>
                  <Input
                    id="featuredImage"
                    type="file"
                    name="featuredImage"
                    onChange={handleInputChange}
                    accept="image/*"
                    className="mt-1"
                  />
                </div>

                {/* Address Information */}
                <div className="col-span-2">
                  <Label htmlFor="address" className="required">Indirizzo</Label>
                  <Input
                    id="address"
                    name="address"
                    value={newSite.address}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="col-span-1">
                  <Label htmlFor="city" className="required">Città</Label>
                  <Input
                    id="city"
                    name="city"
                    value={newSite.city}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="col-span-1">
                  <Label htmlFor="postalCode" className="required">CAP</Label>
                  <Input
                    id="postalCode"
                    name="postalCode"
                    value={newSite.postalCode}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="col-span-1">
                  <Label htmlFor="province" className="required">Provincia</Label>
                  <Input
                    id="province"
                    name="province"
                    value={newSite.province}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="col-span-1">
                  <Label htmlFor="country" className="required">Nazione</Label>
                  <Input
                    id="country"
                    name="country"
                    value={newSite.country}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              {/* Description and Status */}
              <div className="space-y-4">
                <div>
                  <Label htmlFor="description">Descrizione dei lavori</Label>
                  <Textarea
                    id="description"
                    name="description"
                    value={newSite.description}
                    onChange={handleInputChange}
                    className="h-32"
                  />
                </div>

                <div>
                  <Label htmlFor="status">Stato del cantiere</Label>
                  <Select
                    name="status"
                    value={newSite.status}
                    onValueChange={(value) => handleSelectChange('status', value)}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Seleziona stato" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="In pianificazione">In pianificazione</SelectItem>
                      <SelectItem value="In corso">In corso</SelectItem>
                      <SelectItem value="In revisione">In revisione</SelectItem>
                      <SelectItem value="Sospeso">Sospeso</SelectItem>
                      <SelectItem value="In collaudo">In collaudo</SelectItem>
                      <SelectItem value="Terminato">Terminato</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>

              <DialogFooter>
                <Button type="button" variant="outline" onClick={() => setIsModalOpen(false)}>
                  Annulla
                </Button>
                <Button type="submit" disabled={isAdding}>
                  {isAdding ? 'Aggiungendo...' : 'Salva'}
                </Button>
              </DialogFooter>
            </form>
          </ScrollArea>
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteModalOpen} onOpenChange={setDeleteModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Conferma eliminazione</DialogTitle>
          </DialogHeader>
          <DialogDescription className="space-y-4">
            <p>Sei sicuro di voler eliminare il cantiere "{siteToDelete?.name}"?</p>
            <div className="space-y-2">
              <Label htmlFor="password">Inserisci la tua password per confermare</Label>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setDeleteError('');
                }}
                placeholder="Inserisci la password"
              />
              {deleteError && (
                <p className="text-sm text-red-500">{deleteError}</p>
              )}
            </div>
          </DialogDescription>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setDeleteModalOpen(false);
                setPassword('');
                setDeleteError('');
                setSiteToDelete(null);
              }}
            >
              Annulla
            </Button>
            <Button
              variant="destructive"
              onClick={handleDeleteConfirm}
              disabled={isDeleting || !password}
            >
              {isDeleting ? 'Eliminando...' : 'Elimina'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

    </div>
  );
}

export default Cantieri;