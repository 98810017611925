import { createJsonTypeInstance, createMultipartInstance } from "./index";
const axios = createJsonTypeInstance();

export const Employees = async (companyId, currentPage, limit ,searchQuery) => {
  try {
    const response = await axios.get(
      `/employees/${companyId}?page=${currentPage}&limit=${limit}&search=${searchQuery}`
    );

    return response;
  } catch (error) {
    throw error.response;
  }
};

export const AddEmployee = async (data) => {
  try {
    const axiosInstance = createMultipartInstance();
    const employeeData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === "file" && data[key]) {
        employeeData.append(key, data[key]);
      } else {
        employeeData.append(key, data[key]);
      }
    });
    const response = await axiosInstance.post("/employees", employeeData);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const importEmployeesList = async (data) => {
  try {
    const response = await axios.post('/employees/import-employees', data);
    return response;
  } catch (error) {
    throw error.response.data; 
  }
};

export const singleEmployee = async (id) => {
  try {
    const response = await axios.get(`/employees/single/${id}`);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateEmployee = async (id, data) => {
  try {
    const axiosInstance = createMultipartInstance();
    const employeeData = new FormData();
    Object.keys(data).forEach(key => {
      if (key === 'attendance' || key === 'plannings') {
        // Skip the attendance & plannings field
        return;
      }
      if (key === 'file' && data[key]) {
        employeeData.append(key, data[key]);
      } else {
        employeeData.append(key, data[key]);
      }
    });

    const response = await axiosInstance.patch(`/employees/${id}`, employeeData);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};


export const deleteEmployee = async (id) => {
  try {
    const response = await axios.delete(`/employees/${id}`);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
