import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { fetchCompany } from "../apis/CompanyDetail";

export const DateFormat = (date) => {
  const parts = date?.split("-");
  const formattedExpireDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
  return formattedExpireDate;
};

export const changeDateFormat = (dateString) => {
  let parts = dateString.split("-");
  let formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
  return formattedDate;
};

export const exportToExcel = (data, fileName) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, fileName);
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

const addCompanyHeader = async (doc) => {
  // Get company info
  const user = JSON.parse(localStorage.getItem("user"));
  let companyInfo = null;
  
  if (user?.companyId) {
    try {
      const response = await fetchCompany(user.companyId);
      companyInfo = response.data.company;
    } catch (error) {
      console.log('Error fetching company info:', error);
      return 15; // Return default starting Y position if no company info
    }
  }

  let yPos = 15;
  const leftMargin = 14;
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  // Add logo if exists
  if (companyInfo?.logo1) {
    try {
      const logoUrl = `${process.env.REACT_APP_BACKEND_URL}/${companyInfo.logo1}`;
      const logoHeight = 15;
      const logoWidth = logoHeight * 1.5;
      doc.addImage(logoUrl, 'JPEG', leftMargin, yPos, logoWidth, logoHeight);
      yPos += logoHeight + 5;
    } catch (error) {
      console.log('Error adding logo:', error);
    }
  }

  // Company name - left aligned and bold
  if (companyInfo?.companyName) {
    doc.setFontSize(12);
    doc.setFont("Montserrat", "bold");
    doc.text(companyInfo.companyName, leftMargin, yPos);
    yPos += 6;
  }

  // Reset font
  doc.setFontSize(10);
  doc.setFont("Montserrat", "normal");

  // Address line
  let addressLine = '';
  if (companyInfo?.address) addressLine += companyInfo.address;
  if (companyInfo?.postalCode) addressLine += addressLine ? `, ${companyInfo.postalCode}` : companyInfo.postalCode;
  if (companyInfo?.province) addressLine += addressLine ? `, ${companyInfo.province}` : companyInfo.province;
  
  if (addressLine) {
    doc.text(addressLine, leftMargin, yPos);
    yPos += 5;
  }

  // Contact info line
  let contactLine = '';
  if (companyInfo?.taxIdCode) contactLine += `P.IVA: ${companyInfo.taxIdCode}`;
  if (companyInfo?.telephoneNumber) contactLine += contactLine ? `, Tel. ${companyInfo.telephoneNumber}` : `Tel. ${companyInfo.telephoneNumber}`;
  
  if (contactLine) {
    doc.text(contactLine, leftMargin, yPos);
    yPos += 5;
  }

  // Email
  if (companyInfo?.businessEmail) {
    doc.text(companyInfo.businessEmail, leftMargin, yPos);
    yPos += 8;
  }

  // Add separator line
  if (companyInfo && Object.keys(companyInfo).some(key => companyInfo[key])) {
    doc.setDrawColor(200, 200, 200);
    doc.line(leftMargin, yPos, pageWidth - leftMargin, yPos);
    yPos += 10;
  }

  return yPos;
};

export const exportToPDF = async (data, columns, fileName, stats, companyInfo = null, summaries = null) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  doc.setFont("Montserrat");
  
  // Add company header
  let yPos = await addCompanyHeader(doc);

  // Document title
  doc.setFontSize(16);
  doc.setFont("Montserrat", "bold");
  doc.text(fileName, 14, yPos);
  yPos += 10;

  // Stats section with two columns if stats is an object with left and right
  if (stats && stats.left && stats.right) {
    doc.setFontSize(10);
    doc.setFont("Montserrat", "normal");
    
    // Left column
    let leftYPos = yPos;
    stats.left.forEach((stat) => {
      doc.text(`${stat.label}: ${stat.value}`, 14, leftYPos);
      leftYPos += 5;
    });

    // Right column - align to right
    let rightYPos = yPos;
    
    stats.right.forEach((stat) => {
      const text = `${stat.label}: ${stat.value}`;
      const textWidth = doc.getTextWidth(text);
      doc.text(text, pageWidth - 14 - textWidth, rightYPos);
      rightYPos += 5;
    });

    yPos = Math.max(leftYPos, rightYPos) + 10;
  } else if (stats) {
    // Original stats handling for backward compatibility
    doc.setFontSize(10);
    doc.setFont("Montserrat", "normal");
    stats.forEach((stat) => {
      doc.text(`${stat.label}: ${stat.value}`, 14, yPos);
      yPos += 5;
    });
    yPos += 5;
  }

  // Add table
  const tableResult = doc.autoTable({
    startY: yPos,
    head: [columns.map(col => col.header)],
    body: data.map(row => columns.map(col => row[col.key] ?? '')),
    styles: {
      font: "Montserrat",
      lineWidth: 0.1,
      lineColor: [80, 80, 80],
      textColor: [0, 0, 0],
      fillColor: false
    },
    headStyles: {
      fontStyle: 'bold',
      halign: 'left',
      fillColor: false,
      textColor: [0, 0, 0]
    },
    bodyStyles: {
      fillColor: false
    },
    columnStyles: columns.reduce((styles, col, index) => {
      styles[index] = {
        cellWidth: (col.width || 1) * (pageWidth - 28) / 
                   columns.reduce((sum, c) => sum + (c.width || 1), 0),
        cellPadding: 2,
        fontSize: 9,
        overflow: 'linebreak'
      };
      return styles;
    }, {}),
    theme: 'plain',
    margin: { left: 14, right: 14 }
  });

  // Add summaries after the table
  if (summaries && summaries.length > 0) {
    let summaryY = tableResult.lastAutoTable.finalY + 10;
    doc.setFontSize(10);
    
    summaries.forEach((summary, index) => {
      const isLastItem = index === summaries.length - 1;
      if (isLastItem) doc.setFont("Montserrat", "bold");
      else doc.setFont("Montserrat", "normal");
      
      const text = `${summary.label}: ${summary.value}`;
      const textWidth = doc.getTextWidth(text);
      doc.text(text, pageWidth - 14 - textWidth, summaryY);
      
      summaryY += 6;
    });
  }

  // Add footer
  const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
  const footerText = "Powered by Restruct © Galdiero Systems Srl";
  doc.setFontSize(8);
  doc.setFont("Montserrat", "normal");
  const footerWidth = doc.getTextWidth(footerText);
  doc.text(footerText, pageWidth - footerWidth - 14, pageHeight - 10);

  doc.save(`${fileName}.pdf`);
};

// Utility functions for time formatting
export const formatTimeStringToAMPM = (timeString) => {
  if (!timeString) return '';
  let [hours, minutes] = timeString.trim().split(':').map(Number);
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return `${hours}:${minutes} ${ampm}`;
};

export const formatWorkingHours = (workingHours) => {
  if (!workingHours) return '';
  const [hours, minutes] = workingHours.toString().split('.').map(Number);
  const formattedMinutes = minutes || 0;
  return `${hours}h ${formattedMinutes.toString().padStart(2, '0')}m`;
};

export const capitalizeFirstLetter = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};