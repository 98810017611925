import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getConstructionSite, updateConstructionSite, getConstructionSites } from '../../../apis/ConstructionSite';
import ConstructionSiteNavbar from './ConstructionSiteNavbar';

import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { Textarea } from "../../ui/textarea";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../ui/select";
import { Card, CardContent } from "../../ui/card";
import { Skeleton } from "../../ui/skeleton";
import Spinner from '../../Spinner';
import { toast } from 'react-toastify';

function Informazioni() {
    const [site, setSite] = useState({
        name: '',
        client: '',
        address: '',
        amount: '',
        workType: '',
        description: '',
        city: '',
        postalCode: '',
        province: '',
        country: '',
        featuredImage: null,
        status: 'In pianificazione'
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const [clients, setClients] = useState([]);

    useEffect(() => {
        fetchSite();
    }, [id]);

    const getUserFromLocalStorage = () => {
        const userString = localStorage.getItem('user');
        if (!userString) return null;

        const user = JSON.parse(userString);
        if (!user.companyId) {
            console.warn('User does not have a companyId');
            user.companyId = 'default';
        }
        return user;
    };

    const fetchClients = async () => {
        try {
            const user = getUserFromLocalStorage();
            if (!user) {
                console.error('User information not found');
                return;
            }
            const response = await getConstructionSites(user.companyId, 1, 10);
            setClients(response.data.clients);
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };

    const fetchSite = async () => {
        setIsLoading(true);
        try {
            const response = await getConstructionSite(id);
            setSite(response.data);
        } catch (error) {
            console.error('Errore nel recupero del cantiere:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchClients();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'closingDate') {
            const openingDate = new Date(site.openingDate);
            const selectedClosingDate = new Date(value);

            if (selectedClosingDate < openingDate) {
                toast.error("La data di chiusura non può essere precedente alla data di apertura");
                return;
            }
        }

        setSite({ ...site, [name]: value });
    };

    const handleSelectChange = (name, value) => {
        setSite(prevSite => ({
            ...prevSite,
            [name]: value
        }));
    };
    

    const handleFileChange = (e) => {
        setSite({ ...site, featuredImage: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsUpdating(true);
        try {
            const formData = new FormData();
            Object.keys(site).forEach(key => {
                if (key === 'featuredImage') {
                    if (site[key] instanceof File) {
                        formData.append(key, site[key]);
                    } else if (site[key] === null) {
                        formData.append(key, 'null');
                    }
                } else if (key !== 'documents' && key !== 'progressStatuses' && key !== 'reports' && key !== 'mediaFolders' && key !== 'media') {
                    formData.append(key, site[key]);
                }
            });
    
            await updateConstructionSite(id, formData);
            await fetchSite(); // Refresh the site data after update
            navigate('/cantieri');
        } catch (error) {
            console.error('Errore nell\'aggiornamento del cantiere:', error);
        } finally {
            setIsUpdating(false);
        }
    };
    

    if (isLoading) {
        return <Spinner />
    }

    return (
        <div>
            <ConstructionSiteNavbar />

            <form onSubmit={handleSubmit} className="space-y-6">
                <Card className="border-0">
                    <CardContent className="p-6">
                        <div className="space-y-4">
                            <div>
                                <h2 className="text-lg font-medium">Immagine in evidenza</h2>
                                <div className="flex justify-between items-start mt-2">
                                    <div>
                                        <img
                                            src={site.featuredImage ? `${process.env.REACT_APP_BACKEND_URL}/${site.featuredImage}` : '/placeholder-image.jpg'}
                                            alt={site.name}
                                            className="w-32 h-32 rounded-full object-cover"
                                        />
                                        <div className="w-full sm:w-auto">
                                            <Input
                                                type="file"
                                                onChange={handleFileChange}
                                                className="max-w-[250px] text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div className="gap-4 md:flex">
    <div className="space-y-2">
        <Label htmlFor="openingDate">Apertura cantiere</Label>
        <Input
            type="date"
            id="openingDate"
            name="openingDate"
            value={site.openingDate ? new Date(site.openingDate).toISOString().split('T')[0] : ''}
            onChange={handleInputChange}
            required
        />
    </div>
    <div className="space-y-2">
        <Label htmlFor="closingDate">Chiusura cantiere</Label>
        <Input
            type="date"
            id="closingDate"
            name="closingDate"
            min={site.openingDate ? new Date(site.openingDate).toISOString().split('T')[0] : ''}
            value={site.closingDate ? new Date(site.closingDate).toISOString().split('T')[0] : ''}
            onChange={handleInputChange}
        />
    </div>
    <div className="space-y-2">
        <Label htmlFor="status">Stato del cantiere</Label>
        <Select
            name="status"
            value={site.status}
            onValueChange={(value) => handleSelectChange('status', value)}
        >
            <SelectTrigger>
                <SelectValue placeholder="Seleziona stato" />
            </SelectTrigger>
            <SelectContent>
                <SelectItem value="In pianificazione">In pianificazione</SelectItem>
                <SelectItem value="In corso">In corso</SelectItem>
                <SelectItem value="In revisione">In revisione</SelectItem>
                <SelectItem value="Sospeso">Sospeso</SelectItem>
                <SelectItem value="In collaudo">In collaudo</SelectItem>
                <SelectItem value="Terminato">Terminato</SelectItem>
            </SelectContent>
        </Select>
    </div>
</div>

                                </div>
                            </div>


                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                                <div className="space-y-2">
                                    <Label htmlFor="name">Nome Cantiere</Label>
                                    <Input
                                        id="name"
                                        name="name"
                                        value={site.name}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>

                                <div className="space-y-2">
                                    <Label htmlFor="amount">Importo €</Label>
                                    <Input
                                        id="amount"
                                        name="amount"
                                        type="number"
                                        step="0.01"
                                        min="0"
                                        value={site.amount}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>

                                <div className="space-y-2">
                                    <Label htmlFor="client">Committente</Label>
                                    <Select
                                        name="client"
                                        value={site.client}
                                        onValueChange={(value) => handleSelectChange('client', value)}
                                    >
                                        <SelectTrigger>
                                            <SelectValue placeholder="Seleziona committente" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {clients.map((client) => (
                                                <SelectItem key={client._id} value={client._id}>
                                                    {client.typology === 'Private' ? client.fullName : client.companyName}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>

                                <div className="space-y-2">
                                    <Label htmlFor="workType">Tipologia di lavoro</Label>
                                    <Select
                                        name="workType"
                                        value={site.workType}
                                        onValueChange={(value) => handleSelectChange('workType', value)}
                                    >
                                        <SelectTrigger>
                                            <SelectValue placeholder="Seleziona tipologia" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value="Manutenzione ordinaria">Manutenzione ordinaria</SelectItem>
                                            <SelectItem value="Manutenzione straordinaria">Manutenzione straordinaria</SelectItem>
                                            <SelectItem value="Restauro">Restauro</SelectItem>
                                            <SelectItem value="Risanamento conservativo">Risanamento conservativo</SelectItem>
                                            <SelectItem value="Ristrutturazione edilizia">Ristrutturazione edilizia</SelectItem>
                                            <SelectItem value="Nuova costruzione">Nuova costruzione</SelectItem>
                                            <SelectItem value="Ristrutturazione urbanistica">Ristrutturazione urbanistica</SelectItem>
                                            <SelectItem value="Lavori stradali">Lavori stradali</SelectItem>
                                            <SelectItem value="Impianti fognari">Impianti fognari</SelectItem>
                                            <SelectItem value="Movimento terra">Movimento terra</SelectItem>
                                            <SelectItem value="Impiantistica">Impiantistica</SelectItem>
                                            <SelectItem value="Bonifica">Bonifica</SelectItem>
                                            <SelectItem value="Demolizione">Demolizione</SelectItem>
                                            <SelectItem value="Edilizia industrializzata">Edilizia industrializzata</SelectItem>
                                            <SelectItem value="Opere idrauliche">Opere idrauliche</SelectItem>
                                            <SelectItem value="Impianti idroelettrici">Impianti idroelettrici</SelectItem>
                                            <SelectItem value="Opere marittime">Opere marittime</SelectItem>
                                            <SelectItem value="Lavori ferroviari">Lavori ferroviari</SelectItem>
                                            <SelectItem value="Lavori di linee e condotte">Lavori di linee e condotte</SelectItem>
                                            <SelectItem value="Lavori al sottosuolo">Lavori al sottosuolo</SelectItem>
                                            <SelectItem value="Lavori speciali">Lavori speciali</SelectItem>
                                            <SelectItem value="Altro">Altro</SelectItem>
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>

                            <div className="space-y-2">
                                <Label htmlFor="description">Descrizione dei lavori</Label>
                                <Textarea
                                    id="description"
                                    name="description"
                                    value={site.description}
                                    onChange={handleInputChange}
                                    rows={3}
                                />
                            </div>

                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                                <div className="space-y-2">
                                    <Label htmlFor="country">Nazione</Label>
                                    <Input
                                        id="country"
                                        name="country"
                                        value={site.country}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>

                                <div className="space-y-2">
                                    <Label htmlFor="city">Città</Label>
                                    <Input
                                        id="city"
                                        name="city"
                                        value={site.city}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>

                                <div className="space-y-2">
                                    <Label htmlFor="province">Provincia</Label>
                                    <Input
                                        id="province"
                                        name="province"
                                        value={site.province}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>

                                <div className="space-y-2">
                                    <Label htmlFor="postalCode">CAP</Label>
                                    <Input
                                        id="postalCode"
                                        name="postalCode"
                                        value={site.postalCode}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>

                                <div className="space-y-2 md:col-span-4">
                                    <Label htmlFor="address">Indirizzo</Label>
                                    <Input
                                        id="address"
                                        name="address"
                                        value={site.address}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="flex justify-end space-x-2 mt-4">
                                <Button
                                    type="button"
                                    onClick={() => navigate('/cantieri')}
                                    variant="outline"
                                >
                                    Annulla
                                </Button>
                                <Button
                                    type="submit"
                                    disabled={isUpdating}
                                >
                                    {isUpdating ? 'Aggiornamento...' : 'Salva'}
                                </Button>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </form>
        </div>
    );
}

export default Informazioni;