import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TbEdit } from "react-icons/tb";
import { MdOutlineDelete } from "react-icons/md";
import { FaArrowRight, FaFilter, FaEye, FaEdit, FaTrash, FaEnvelope } from 'react-icons/fa';
import { fetchUtenti, deleteUser, updateUser, inviteUser, resendInvitation } from '../../../apis/Utenti';
import { toast } from 'react-toastify';

import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import Pagination from '../sharedComponent/Pgination';
import SearchBar from '../sharedComponent/SearchBar';
import InviteUserModal from './modal/InviteUserModal';
import DeleteUserModal from './modal/DeleteUserModal';

function TableComponent() {
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user?.companyId;
  const isAdmin = user?.role === 'admin';
  const userId = user?.id;
  const isInvitedUser = user?.isInvitedUser;

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [totalItems, setTotalItems] = useState(0);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const getItems = async () => {
    try {
      const response = await fetchUtenti(companyId, currentPage, limit);
      setTotalItems(response.data.totalItems);
      setTotalPage(Math.ceil(response.data.totalItems / limit));
      // Filter out admin users and the current user from the list
      const filteredUsers = response.data.users.filter(user =>
        user.role !== 'admin' &&
        user._id !== userId
      );
      setData(filteredUsers);
      setFilteredData(filteredUsers);
      setDataIsLoaded(true);
      setCurrentPage(response.data?.currentPage);
    } catch (error) {
      console.error('There was an error fetching the data!', error);
      toast.error('Error fetching users');
    }
  };

  const handleInvite = async (userData) => {
    try {
      if (!isAdmin) {
        toast.error('Only admins can invite users');
        return;
      }
      const response = await inviteUser({ email: userData.email });


      setIsInviteUserModalOpen(false);
      getItems();
      toast.success('Invitation sent successfully');
    } catch (error) {
      console.error('There was an error inviting the user!', error);
      toast.error(error.response?.data?.message || 'Error inviting user');
    }
  };

  const handleDelete = async (id) => {
    if (!isAdmin) {
      toast.error('Only admins can delete users');
      return;
    }
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        const response = await deleteUser(id);
        getItems();
        toast.success(response.data.message);
      } catch (error) {
        console.error('There was an error deleting the user!', error);
        toast.error('Error deleting user');
      }
    }
  };

  const handleResendInvitation = async (userId) => {
    if (!isAdmin) {
      toast.error('Only admins can resend invitations');
      return;
    }
    try {
      const response = await resendInvitation(userId);
      toast.success(response.data.message);
      getItems();
    } catch (error) {
      console.error('Error resending invitation:', error);
      toast.error(error.response?.data?.message || 'Error resending invitation');
    }
  };

  useEffect(() => {
    getItems();
  }, [currentPage]);

  const handleSearch = (searchTerm) => {
    if (searchTerm.length >= 3) {
      const filtered = data.filter(item =>
        item.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.email?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  };

  // If the user is not an admin and is an invited user, don't show the management section
  if (!isAdmin && isInvitedUser) {
    return (
      <Card className="m-4 border-0">
        <CardContent>
          <div className="text-center py-8">
            You don't have permission to access this section.
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="m-4 border-0">
      <CardHeader>
        <CardTitle>User Management</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex justify-between mb-4">
          {isAdmin && ( // Only show invite button for admins
            <Button onClick={() => setIsInviteUserModalOpen(true)}>
              Invite User
            </Button>
          )}
          <div className="w-[20%]">
            <SearchBar onSearch={handleSearch} />
          </div>
        </div>

        <div className="rounded-lg border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>ID</TableHead>
                <TableHead>Email</TableHead>
                <TableHead>Status</TableHead>
                {isAdmin && <TableHead>Action</TableHead>}
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredData.length > 0 ? (
                filteredData.map((item, index) => (
                  <TableRow key={item._id}>
                    <TableCell>{(currentPage - 1) * limit + index + 1}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    {isAdmin && (
                      <TableCell>
                        <div className="flex space-x-2">
                          {item.status === 'Invited' && (
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => handleResendInvitation(item._id)}
                              title="Resend Invitation"
                            >
                              <FaEnvelope />
                            </Button>
                          )}
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => handleDelete(item._id)}
                            title="Delete User"
                          >
                            <MdOutlineDelete className='text-red-600' />
                          </Button>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={isAdmin ? 4 : 3} className="text-center">
                    No invited users found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        {filteredData?.length > 0 && (
          <Pagination
            totalPages={totalPages}
            totalItems={totalItems}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
            setLimit={setLimit}
          />
        )}

        {isAdmin && (
          <>
            <InviteUserModal
              isOpen={isInviteUserModalOpen}
              onClose={() => setIsInviteUserModalOpen(false)}
              onInvite={handleInvite}
            />

            <DeleteUserModal
              isOpen={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
              userId={selectedUser?._id}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
}

export default TableComponent;