import { createJsonTypeInstance } from "./index";
const axios = createJsonTypeInstance();

export const createNode = async (data) => {
  try {
    const response = await axios.post("/node", data);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateNode = async (nodeId, data) => {
  try {
    const response = await axios.patch(`/node/${nodeId}`, data);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const getTree = async () => {
  try {
    const response = await axios.get(`/node`);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteNode = async (nodeId) => {
  try {
    const response = await axios.delete(`/node/${nodeId}`);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
