import { GoChevronRight, GoChevronDown } from "react-icons/go";
import { useRef, useEffect, useState } from "react";

const AccordionItem = ({ id, label, children, isExpanded, onClick,}) => {
  const contentRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState("0px");

  useEffect(() => {
    setMaxHeight(isExpanded ? `${contentRef.current.scrollHeight}px` : "0px");
  }, [isExpanded]);

  const icon = (
    <span className="text-2xl">{isExpanded ? <GoChevronDown /> : <GoChevronRight />}</span>
  );

  return (
    <div key={id} className="border-b">
      <div
        onClick={onClick}
        className="flex justify-between p-4 font-bold cursor-pointer hover:bg-gray-100 rounded-md"
      >
        {label}
        {icon}
      </div>
      <div
        ref={contentRef}
        style={{ maxHeight }}
        className="overflow-hidden transition-max-height duration-700 ease-in-out"
      >
        <div className="p-5">{children}</div>
      </div>
    </div>
  );
};

export default AccordionItem;
