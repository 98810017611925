export const COMPANY_INFORMATION_LABELS = {
  Business_name: "Business name",
  Nickname: "Nickname",
  Address: "Address",
  City: "City",
  Province: "Province",
  POSTAL_CODE: "POSTAL CODE",
  Nation: "Nation",
  Address_notes: "Address notes",
  Tax_ID_code: "Tax ID code",
  Business_email: "Business email",
  Secondary_Emails: "Secondary Emails",
  Telephone_number: "Telephone number",
  Mobile_number: "Mobile number",
  Fax: "Fax",
  Website: "Website",
  Type_of_business: "Type of business",
  INPS_position: "INPS position",
  INAIL_position: "INAIL position",
  Construction_Fund_Location: "Construction Fund Location",
  Bank: "Bank",
  SWIFT_BIC: "SWIFT/BIC",
  IBAN: "IBAN",
};

export const ADD_EMPLOYEE_STEPPER_HEADERS = [
  "Dati generali",
  "Contratto",
  "Retribuzione",
];
export const AGENCY_TAB = ["Dati azienda", "Documenti", "Struttura", "Proprietà"];

const IMAGE_STRUCTURE = (
  <label>
    <div
      className={`flex justify-center items-center w-[150px] h-[150px] p-3 border-2 border-gray-300 border-dashed `}
    >
      <div className="flex flex-col justify-center items-center">
        <svg
          className="w-6 h-6 text-gray-500 dark:text-gray-400"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 16"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
          />
        </svg>
        <p className="text-sm text-gray-500 dark:text-gray-400">Carica logo</p>
        <p className="text-[8px] text-gray-400 mt-1 text-center">
          PNG, JPG, SVG, WEBP, and GIF are allowed.
        </p>
      </div>
    </div>
  </label>
);

export default IMAGE_STRUCTURE;
