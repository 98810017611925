import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import ComputimetriciaddrecComponent from '../../../components/admin/dashboard/computi-metrici/computimetriciaddrec'

const Computimetriciaddrec = () => {
  return (
    <Layout>
      <ComputimetriciaddrecComponent />
    </Layout>
  )
}

export default Computimetriciaddrec