import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../../ui/tabs";
import Information from "./information/Information";
import Document from "./document";
import Property from "./property";
import Structure from "./structure";
import Licence from "./licence";

const AGENCY_TAB = ["Dati Azienda", "Documenti", "Proprietà", "Patente a punti", "Organigramma"];
const DEFAULT_TAB = "dati azienda";

const AgencyComponent = () => {
  const { tab } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(DEFAULT_TAB);

  useEffect(() => {
    if (tab) {
      const lowercaseTab = tab.toLowerCase();
      if (AGENCY_TAB.map(t => t.toLowerCase()).includes(lowercaseTab)) {
        setActiveTab(lowercaseTab);
      } else {
        navigate(`/agency/${DEFAULT_TAB}`, { replace: true });
      }
    } else {
      navigate(`/agency/${DEFAULT_TAB}`, { replace: true });
    }
  }, [tab, navigate]);

  const handleTabChange = (value) => {
    navigate(`/agency/${value.toLowerCase()}`);
  };

  return (
    <div className="h-full md:p-6 p-2 pt-1 pb-7">
      <Tabs value={activeTab} onValueChange={handleTabChange} className="w-full">
        <TabsList className="bg-[#06052C] rounded w-full flex justify-start h-auto p-1">
          {AGENCY_TAB.map((data) => (
            <TabsTrigger
              key={data}
              value={data.toLowerCase()}
              className="py-2 px-4 text-white data-[state=active]:bg-white data-[state=active]:text-blue-950"
            >
              {data}
            </TabsTrigger>
          ))}
        </TabsList>
        <div className="py-4">
          <TabsContent value="dati azienda">
            <Information />
          </TabsContent>
          <TabsContent value="documenti">
            <Document />
          </TabsContent>
          <TabsContent value="proprietà">
            <Property />
          </TabsContent>
          <TabsContent value="patente a punti">
            <Licence />
          </TabsContent>
          <TabsContent value="organigramma">
            <Structure />
          </TabsContent>
        </div>
      </Tabs>
    </div>
  );
};

export default AgencyComponent;