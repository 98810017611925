import { createJsonTypeInstance } from './index';
const axios = createJsonTypeInstance();

export const createWorkJournalEntry = async (siteId, data) => {
  try {
    const response = await axios.post(`/construction-sites/${siteId}/work-journal`, data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export const getWorkJournalEntries = async (siteId) => {
  try {
    const response = await axios.get(`/construction-sites/${siteId}/work-journal`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getWorkJournalEntry = async (siteId, date) => {
  try {
    const formattedDate = date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    const response = await axios.get(`/construction-sites/${siteId}/work-journal/${formattedDate}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null; // Return null if no entry found
    }
    throw error.response ? error.response.data : error;
  }
};

export const updateWorkJournalEntry = async (siteId, entryId, data) => {
  try {
    const response = await axios.put(`/construction-sites/${siteId}/work-journal/${entryId}`, data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const deleteWorkJournalEntry = async (siteId, entryId) => {
  try {
    const response = await axios.delete(`/construction-sites/${siteId}/work-journal/${entryId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const exportWorkJournalToPDF = async (siteId, entryId) => {
  try {
    const response = await axios.get(`/construction-sites/${siteId}/work-journal/${entryId}/export`, {
      responseType: 'blob'
    });
    return response.data;
  } catch (error) {
    console.error('Error in exportWorkJournalToPDF:', error);
    if (error.response) {
      const errorMessage = error.response.data instanceof Blob 
        ? await error.response.data.text() 
        : JSON.stringify(error.response.data);
      throw new Error(errorMessage);
    } else if (error.request) {
      throw new Error('No response from server');
    } else {
      throw error;
    }
  }
};