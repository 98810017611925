import React from 'react'
import loginImage from "../../assest/singup.jpg";
import Content from "./Content";
import { useNavigate } from "react-router-dom";

const RequestSuccess = () => {
  const navigate = useNavigate();
    const contentData = {
        title: "Building with Passion, Creating with Precision",
        description:
          "Crafting inspirational spaces with precision, passion, and a commitment to excellence in construction.",
        image: loginImage,
      };
  return (
    <div className="min-h-[100vh]">
      <div className="flex">
        <Content contentData={contentData}/>
        <div className="h-[100vh] flex flex-col justify-center items-center w-[55%] bg-gradient-to-r from-blue-950 to-blue-800">
          <h1 className="mb-10 text-white font-nunito text-xl font-bold text-center w-[70%]">
           Password recovery email has been sent to your email address. Please check your inbox for further details.
          </h1>
         
        </div>
      </div>
    </div>
  )
}

export default RequestSuccess