import { createJsonTypeInstance } from './index';
import { setAuthToken, clearAuth } from './index';

const axios = createJsonTypeInstance();

export const Signup = async (data) => {
  try {
    const response = await axios.post('/user/register', data);
    return response;
  } catch (error) {
    if (error.response?.data) {
      throw error.response.data;
    }
    throw { message: 'Network error occurred' };
  }
};

export const login = async (data) => {
  try {
    const response = await axios.post('/user/login', data);
    
    if (response.status === 200 && response.data.user) {
      const { user } = response.data;
      
      // Store token in both cookie and localStorage
      if (user.token) {
        setAuthToken(user.token);
      }
      
      // Store user data in localStorage
      localStorage.setItem('user', JSON.stringify(user));
    }
    
    return response;
  } catch (error) {
    // Clear any existing auth data on login failure
    clearAuth();
    
    if (error.response?.data) {
      throw error.response.data;
    }
    throw { message: 'Network error occurred' };
  }
};

export const forgetPassword = async (data) => {
  try {
    const response = await axios.post('/user/forgot-password', data);
    return response;
  } catch (error) {
    if (error.response?.data) {
      throw error.response.data;
    }
    throw { message: 'Network error occurred' };
  }
};

export const resetPassword = async (token, data) => {
  try {
    const response = await axios.post(`/user/reset-password/${token}`, data);
    return response;
  } catch (error) {
    if (error.response?.data) {
      throw error.response.data;
    }
    throw { message: 'Network error occurred' };
  }
};

export const addUser = async (data) => {
  try {
    const response = await axios.post('/user/adduser', data);
    return response;
  } catch (error) {
    if (error.response?.data) {
      throw error.response.data;
    }
    throw { message: 'Network error occurred' };
  }
};

// Add logout function to properly clear auth data
export const logout = () => {
  clearAuth();
  window.location.href = '/login';
};

// Add a function to check if user is authenticated
export const isAuthenticated = () => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    return !!user?.token;
  } catch (error) {
    return false;
  }
};

// Optional: Add a function to refresh token if needed
export const refreshToken = async () => {
  try {
    const response = await axios.post('/user/refresh-token');
    if (response.data?.token) {
      setAuthToken(response.data.token);
    }
    return response;
  } catch (error) {
    clearAuth();
    throw error;
  }
};