import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import EmployeeInfo from './EmployeeInfo';
import Contact from './Contact';
import Remuneration from './Remuneration';
import { ADD_EMPLOYEE_STEPPER_HEADERS } from '../../../../../../constants';
import { useNavigate, useParams } from 'react-router-dom';
import { AddEmployee, singleEmployee, updateEmployee } from "../../../../../../apis/Employees"
import { toast } from 'react-toastify';
import { Button } from '../../../../../ui/button';

const AddEmployeeComponent = () => {
  const { id } = useParams();
  const [stepperName, setStepperName] = useState('Dati generali');
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState();
  const { control, handleSubmit, reset, setValue, watch } = useForm();

  const imagePreview = watch('file');
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setValue('file', file);
    }
  };

  const getEmployee = async () => {
    try {
      const response = await singleEmployee(id);
      if (response.status === 200) {
        setEmployeeData(response.data?.employee);
        reset(response.data?.employee);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      getEmployee();
    }
    // Set the default tab to "Dati generali" when component mounts
    setStepperName('Dati generali');
  }, [id, reset]);

  const onSubmit = async (data) => {
    const sanitizePayload = (payload) => {
      const sanitized = {};
      Object.keys(payload).forEach((key) => {
        const value = payload[key];
        if (value !== undefined && value !== '' && value !== null) {
          sanitized[key] = value;
        }
      });
      return sanitized;
    };

    const sanitizedData = sanitizePayload(data);
    if (id) {
      try {
        const response = await updateEmployee(id, sanitizedData);
        if (response.status === 200) {
          toast.success("Employee Updated");
          navigate("/employees/all-employees");
          reset();
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      try {
        console.log('Form Data:', sanitizedData);
        const response = await AddEmployee(sanitizedData);
        if (response.status === 201) {
          toast.success("Employee added");
          navigate("/employees/all-employees");
          reset();
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const renderContent = () => {
    switch (stepperName) {
      case 'Dati generali':
        return (
          <EmployeeInfo
            control={control}
            handleImageChange={handleImageChange}
            imagePreview={imagePreview}
            image={employeeData?.image}
          />
        );
      case 'Contratto':
        return (
          <Contact
            control={control}
            handleImageChange={handleImageChange}
            imagePreview={imagePreview}
          />
        );
      case 'Retribuzione':
        return <Remuneration control={control} />;
      default:
        return null;
    }
  };

  return (
    <div className='h-full p-6 space-y-7'>
      <div className='bg-[#06052C] rounded w-full flex gap-[3rem] py-2 px-5 text-[16px]'>
        {ADD_EMPLOYEE_STEPPER_HEADERS?.map((data, index) => (
          <div
            onClick={() => setStepperName(data)}
            key={index}
            className={`py-[2px] px-3 ${
              stepperName === data ? 'bg-white text-blue-950' : 'text-white'
            } rounded-md cursor-pointer`}
          >
            {data}
          </div>
        ))}
      </div>
      <div className='py-4'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='col-span-2 flex justify-end space-x-2 mb-5'>
            <Button
              variant='destructive'
              type='button'
              onClick={() => {
                navigate("/employees/all-employees")
                reset()
              }}
            >
              Annulla
            </Button>
            <Button
              type='submit'
            >
              Salva
            </Button>
          </div>
          {renderContent()}
        </form>
      </div>
    </div>
  );
};

export default AddEmployeeComponent;