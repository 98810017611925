import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaFolder, FaImage, FaVideo, FaTimes, FaTrash, FaUpload, FaPlus, FaArrowLeft, FaEllipsisV, FaEdit } from 'react-icons/fa';
import { getMediaFolders, createMediaFolder, uploadMedia, deleteMedia, updateMediaFolder, deleteMediaFolder } from '../../../apis/ConstructionSite';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConstructionSiteNavbar from './ConstructionSiteNavbar';

import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu";
import { Label } from "../../ui/label";

function Media() {
  const { id } = useParams();
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [newFolderName, setNewFolderName] = useState('');
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [editingFolder, setEditingFolder] = useState(null);
  const [editedFolderName, setEditedFolderName] = useState('');
  const [folderToDelete, setFolderToDelete] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [mediaToDelete, setMediaToDelete] = useState(null);

  useEffect(() => {
    fetchFolders();
  }, [id]);

  const fetchFolders = async () => {
    setIsLoading(true);
    try {
      const fetchedFolders = await getMediaFolders(id);
      setFolders(fetchedFolders);
    } catch (error) {
      console.error('Error fetching folders:', error);
      toast.error('Errore nel recupero delle cartelle');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) {
      toast.error('Il nome della cartella non può essere vuoto');
      return;
    }
    setIsLoading(true);
    try {
      const newFolder = await createMediaFolder(id, newFolderName);
      setFolders([...folders, newFolder]);
      setNewFolderName('');
      toast.success('Cartella creata con successo');
    } catch (error) {
      console.error('Error creating folder:', error);
      toast.error('Errore nella creazione della cartella');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

  const handleUpload = async () => {
    if (!selectedFolder) {
      toast.error('Seleziona una cartella prima di caricare i file');
      return;
    }
    if (files.length === 0) {
      toast.error('Seleziona almeno un file da caricare');
      return;
    }
    setIsUploading(true);
    const formData = new FormData();
    files.forEach(file => formData.append('media', file));
    try {
      const updatedFolder = await uploadMedia(id, selectedFolder._id, formData);
      setFolders(folders.map(f => f._id === updatedFolder._id ? updatedFolder : f));
      setSelectedFolder(updatedFolder);
      setFiles([]);
      toast.success('File caricati con successo');
    } catch (error) {
      console.error('Error uploading media:', error);
      toast.error('Errore nel caricamento dei file');
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeleteMedia = async () => {
    if (!mediaToDelete || !selectedFolder) return;
    setIsLoading(true);
    try {
      await deleteMedia(id, selectedFolder._id, mediaToDelete._id);
      const updatedFolder = {
        ...selectedFolder,
        media: selectedFolder.media.filter(m => m._id !== mediaToDelete._id)
      };
      setFolders(folders.map(f => f._id === selectedFolder._id ? updatedFolder : f));
      setSelectedFolder(updatedFolder);
      toast.success('File eliminato con successo');
    } catch (error) {
      console.error('Error deleting media:', error);
      toast.error('Errore nell\'eliminazione del file');
    } finally {
      setIsLoading(false);
      setMediaToDelete(null);
    }
  };

  const handleEditFolder = (folder) => {
    setEditingFolder(folder);
    setEditedFolderName(folder.name);
  };

  const handleUpdateFolder = async () => {
    if (!editedFolderName.trim()) {
      toast.error('Il nome della cartella non può essere vuoto');
      return;
    }
    setIsLoading(true);
    try {
      const updatedFolder = await updateMediaFolder(id, editingFolder._id, editedFolderName);
      setFolders(folders.map(f => f._id === updatedFolder._id ? updatedFolder : f));
      setEditingFolder(null);
      toast.success('Cartella aggiornata con successo');
    } catch (error) {
      console.error('Error updating folder:', error);
      toast.error('Errore nell\'aggiornamento della cartella');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteFolder = async () => {
    if (!folderToDelete) return;
    setIsLoading(true);
    try {
      await deleteMediaFolder(id, folderToDelete._id);
      setFolders(folders.filter(f => f._id !== folderToDelete._id));
      toast.success('Cartella eliminata con successo');
    } catch (error) {
      console.error('Error deleting folder:', error);
      toast.error('Errore nell\'eliminazione della cartella');
    } finally {
      setIsLoading(false);
      setFolderToDelete(null);
    }
  };

  const renderFolderContent = () => (
    <>
      <div className="flex items-center mb-4">
        <Button
          onClick={() => setSelectedFolder(null)}
          variant="outline"
          className="mr-4"
        >
          <FaArrowLeft className="mr-2" /> Indietro
        </Button>
        <h3 className="font-bold text-xl">{selectedFolder.name}</h3>
      </div>
      <div className="mb-4 flex items-center">
        <Input
          type="file"
          multiple
          onChange={handleFileChange}
          className="mr-4"
        />
        <Button
          onClick={handleUpload}
          disabled={isUploading}
        >
          {isUploading ? (
            <>
              <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Caricamento...
            </>
          ) : (
            <>
              <FaUpload className="mr-2" /> Carica
            </>
          )}
        </Button>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {selectedFolder.media.map(media => (
          <Card key={media._id}>
            <CardContent className="p-2">
              {media.type === 'image' ? (
                <img
                  src={`${process.env.REACT_APP_BACKEND_URL}/${media.path}`}
                  alt={media.name}
                  className="w-full h-48 object-cover rounded cursor-pointer"
                  onClick={() => setPreviewImage(media)}
                />
              ) : (
                <video
                  src={`${process.env.REACT_APP_BACKEND_URL}/${media.path}`}
                  className="w-full h-48 object-cover rounded"
                  controls
                />
              )}
              <Dialog>
                <DialogTrigger asChild>
                  <Button
                    variant="destructive"
                    size="icon"
                    className="absolute top-2 right-2"
                  >
                    <FaTrash className='text-red-600' />
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Conferma eliminazione</DialogTitle>
                    <DialogDescription>
                      Sei sicuro di voler eliminare il file "{media.name}"?
                      Questa azione non può essere annullata.
                    </DialogDescription>
                  </DialogHeader>
                  <DialogFooter>
                    <Button variant="outline" onClick={() => setMediaToDelete(null)}>Annulla</Button>
                    <Button variant="destructive" onClick={() => handleDeleteMedia(media)}>Elimina</Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
              <p className="mt-2 text-sm truncate">{media.name}</p>
            </CardContent>
          </Card>
        ))}
      </div>
    </>
  );

  const renderFolderList = () => (
    <>
      <div className="flex my-4">
        <Input
          type="text"
          value={newFolderName}
          onChange={(e) => setNewFolderName(e.target.value)}
          placeholder="Nome Nuova Cartella"
          className="mr-4"
        />
        <Button
          onClick={handleCreateFolder}
          disabled={isLoading}
        >
          Crea Cartella
        </Button>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {folders.map(folder => (
          <Card key={folder._id}>
            <CardContent className="p-4">
              {editingFolder && editingFolder._id === folder._id ? (
                <div className="flex items-center">
                  <Input
                    type="text"
                    value={editedFolderName}
                    onChange={(e) => setEditedFolderName(e.target.value)}
                    className="mr-2"
                  />
                  <Button onClick={handleUpdateFolder} variant="outline">
                    Salva
                  </Button>
                </div>
              ) : (
                <>
                  <div
                    onClick={() => setSelectedFolder(folder)}
                    className="flex items-center cursor-pointer hover:bg-gray-100 transition-colors"
                  >
                    <FaFolder className="mr-2 text-yellow-500 flex-shrink-0" size={24} />
                    <span className="truncate">{folder.name}</span>
                  </div>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" size="icon" className="absolute top-2 right-2">
                        <FaEllipsisV />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuItem onClick={() => handleEditFolder(folder)}>
                        <FaEdit className="mr-2" /> Modifica
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => setFolderToDelete(folder)}>
                        <FaTrash className="mr-2" /> Elimina
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </>
              )}
            </CardContent>
          </Card>
        ))}
      </div>
    </>
  );

  return (
    <div className=" mx-auto ">
      <ToastContainer position="top-right" autoClose={3000} />
      <ConstructionSiteNavbar />
      {/*<h2 className="text-2xl font-bold my-4">Media</h2>*/}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <svg className="animate-spin h-10 w-10 text-primary" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      ) : selectedFolder ? renderFolderContent() : renderFolderList()}

      <Dialog open={!!previewImage} onOpenChange={() => setPreviewImage(null)}>
        <DialogContent className="sm:max-w-[80vw]">
          <DialogHeader>
            <DialogTitle>{previewImage?.name}</DialogTitle>
          </DialogHeader>
          <img
            src={previewImage ? `${process.env.REACT_APP_BACKEND_URL}/${previewImage.path}` : ''}
            alt={previewImage?.name}
            className="max-w-full max-h-[80vh] object-contain"
          />
        </DialogContent>
      </Dialog>

      <Dialog open={!!folderToDelete} onOpenChange={() => setFolderToDelete(null)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Conferma eliminazione</DialogTitle>
            <DialogDescription>
              Sei sicuro di voler eliminare la cartella "{folderToDelete?.name}"?
              Questa azione non può essere annullata.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setFolderToDelete(null)}>Annulla</Button>
            <Button variant="destructive" onClick={handleDeleteFolder}>Elimina</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Media;