import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import Edit from "../../../components/admin/agency/information/Edit"


const EditInformation = () => {
  return (
    <Layout>
    <Edit />
   </Layout>
  )
}

export default EditInformation