import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import PrezzariRegioniComponent from '../../../components/admin/prezzari_regioni/prezzari_regioni'

const PrezzariRegioniDetails = () => {
  return (
    <Layout>
      <PrezzariRegioniComponent />
    </Layout>
  )
}

export default PrezzariRegioniDetails