import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import AddEmployeeComponent from '../../../components/admin/personalData/employees/allEmployess/addEmployee'


const AddEmployee = () => {
  return (
    <Layout>
      <AddEmployeeComponent />
    </Layout>
  )
}

export default AddEmployee