
import React, { useEffect, useState } from 'react';
//import axios from 'axios';
import { Link } from 'react-router-dom';
import SearchBar from '../sharedComponent/SearchBar'
import { TbEdit } from "react-icons/tb";
import { MdOutlineDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { FaArrowRight, FaCircle, FaFilter, FaPrint, FaFile, FaArrowUp, FaArrowDown } from "react-icons/fa";


function TableComponent() {
  return (
    <div className="clientifirstsec">
      <div className="flex flex-col md:flex-row my-5">
          <div className="md:w-3/4">
              <div className="flex flex-col">
                  <div className="w-full p-4 bg-[#06052c]">
                  </div>
                  <div className="overflow-x-auto as_table">
                      <div id="clientitabledata">
                          <div className="p-4 flex hover:bg-gray-100 cursor-pointer" >
                              <div className="w-[3%] flex items-center"><FaCircle size={21}/></div>
                              <div className="w-[87%] flex items-center">Regioni</div>
                              <div className="w-[10%] text-right"><FaArrowRight size={21}/></div>
                          </div>
                          <div className="p-4 flex hover:bg-gray-100 cursor-pointer">
                              <div className="w-[3%] flex items-center"><FaCircle size={21}/></div>
                              <div className="w-[87%] flex items-center">Capitoli_Abruzzo_2024</div>
                              <div className="w-[10%] text-right"><FaArrowRight size={21}/></div>
                          </div>
                          <div className="p-4 flex hover:bg-gray-100 cursor-pointer">
                              <div className="w-[3%] flex items-center"><FaCircle size={21}/></div>
                              <div className="w-[87%] flex items-center">Capitoli_Basilicata_2024</div>
                              <div className="w-[10%] text-right"><FaArrowRight size={21}/></div>
                          </div>
                      </div>
                  </div>
                  <div className="flex mt-4">
                      <div className="w-1/2" id="showing-entries">
                          <div className="text-center"></div>
                      </div>
                      <div className="w-1/2">
                          <ul className="pagination justify-end clientipagination" id="pagination"></ul>
                      </div>
                  </div>
              </div>
          </div>
          <div className="rightsidebar md:w-1/4 p-4 bg-gray-100">
              <div className="my-3 text-black">
                  <h4>Filtra i risultati per:</h4>
              </div>
              <span>
                  <h5 className="font-light">Codice o parola chiave</h5>
              </span>
              <div className="flex flex-col">
                  <div className="w-full">
                      <div className="input-group mb-3 py-1 relative">
                          <input type="text" id="live-search" className="form-control as_border-radis" placeholder="Cerca ..." name="cerca"/>
                          <span className="cursor-pointer">
                              <i className="fa-solid fa-magnifying-glass as_searchIcon" aria-hidden="true"></i>
                          </span>
                      </div>
                  </div>
                  <div className="hidden">
                      <div className="search-bar">
                          <select type="text" className="form-control" id="year">
                              <option>2024</option> 
                          </select>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

  );
};

export default TableComponent;
