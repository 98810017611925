import React, { useState, useEffect, useRef } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";
import { PDFDownloadLink } from "@react-pdf/renderer";
import AttendanceDocument from "./AttendanceDocumentPdf";
import { formatWorkingHours } from "../../../../../config/helper";
import {
  getCompnayEmployeeAttendence,
  markAttendance,
  updateAttendance,
} from "../../../../../apis/Attendence";
import { getCompanyPlans } from "../../../../../apis/Planning";
import { Button } from "../../../../ui/button";
import { Input } from "../../../../ui/input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogTrigger,
  DialogDescription,
} from "../../../../ui/dialog";
import { Label } from "../../../../ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../../ui/select";
import { Checkbox } from "../../../../ui/checkbox";
import SearchBar from "../../../sharedComponent/SearchBar";
import HelpSheet from "../../../sharedComponent/HelpSheet";
import { IoCaretBack, IoCaretForward } from "react-icons/io5";

const AttendanceSheet = () => {
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [attendanceData, setAttendanceData] = useState([]);
  const [filteredAttendanceData, setFilteredAttendanceData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSelectEmployeeDialogOpen, setSelectEmployeeDialogOpen] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [companyPlans, setCompanyPlans] = useState([]);
  const componentRef = useRef();

  useEffect(() => {
    fetchAttendanceData();
    fetchCompanyPlans();
  }, [searchQuery, selectedMonth]);

  useEffect(() => {
    if (attendanceData.length > 0 && selectedEmployees.length === 0) {
      setSelectedEmployees(attendanceData.map(emp => emp._id));
    }
    filterAttendanceData();
  }, [attendanceData, selectedEmployees, searchQuery]);

  const fetchAttendanceData = async () => {
    try {
      const response = await getCompnayEmployeeAttendence(JSON.parse(localStorage.getItem("user")).companyId);
      setAttendanceData(response.data?.employees || []);
    } catch (error) {
      console.error("Failed to fetch attendance data", error);
      toast.error("Unable to fetch attendance data");
    }
  };

  const fetchCompanyPlans = async () => {
    try {
      const response = await getCompanyPlans();
      setCompanyPlans(response.data.plans);
    } catch (error) {
      console.error("Unable to fetch company plans", error);
      toast.error("Unable to fetch company plans");
    }
  };

  const filterAttendanceData = () => {
    let filtered = attendanceData.filter(emp => selectedEmployees.includes(emp._id));
    if (searchQuery) {
      filtered = filtered.filter(emp =>
        emp.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        emp.lastName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    setFilteredAttendanceData(filtered);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(new Date(e.target.value));
  };

  const incrementMonth = () => {
    setSelectedMonth((prev) => new Date(prev.setMonth(prev.getMonth() + 1)));
  };

  const decrementMonth = () => {
    setSelectedMonth((prev) => new Date(prev.setMonth(prev.getMonth() - 1)));
  };

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const daysInMonth = getDaysInMonth(
    selectedMonth.getFullYear(),
    selectedMonth.getMonth()
  );

  const openModal = (employee, date) => {
    const formattedDate = format(new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), date), "yyyy-MM-dd");
    setSelectedEmployee(employee);
    setSelectedDate(formattedDate);
    setModalOpen(true);
  };

  const handleModalSubmit = async (data) => {
    try {
      const response = data._id ? await updateAttendance(data) : await markAttendance(data);
      if (response.status === 200 || response.status === 201) {
        toast.success("Attendance updated successfully");
        fetchAttendanceData();
      }
    } catch (error) {
      console.error("Failed to update attendance", error);
      toast.error("Unable to update attendance");
    }
    setModalOpen(false);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleEmployeeSelect = () => {
    setSelectEmployeeDialogOpen(false);
    filterAttendanceData();
  };

  const getDayOfWeek = (date) => {
    const daysOfWeek = ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"];
    return daysOfWeek[date.getDay()];
  };

  return (
    <div className="mx-auto space-y-4">
      <ToastContainer />
      <div className="flex flex-col md:flex-row justify-between my-2">
        <div className="flex items-center gap-2 mb-4 md:mb-0">
          <Button onClick={decrementMonth} variant="outline">
            <IoCaretBack className="text-lg" />
          </Button>
          <Input
            type="month"
            value={selectedMonth.toISOString().substring(0, 7)}
            onChange={handleMonthChange}
            className="border border-gray-300 text-gray-900 text-sm rounded w-full p-2.5"
          />
          <Button onClick={incrementMonth} variant="outline">
            <IoCaretForward className="text-lg" />
          </Button>
        </div>
        <div className="flex items-center gap-2">
          <Dialog open={isSelectEmployeeDialogOpen} onOpenChange={setSelectEmployeeDialogOpen}>
            <DialogTrigger asChild>
              <Button variant="outline">Seleziona Dipendenti</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle>Seleziona Dipendenti</DialogTitle>
                <DialogDescription>
                  Scegli i dipendenti che desideri visualizzare nel foglio presenze.
                </DialogDescription>
              </DialogHeader>
              <div className="grid gap-4 py-4">
                {attendanceData.map((employee) => (
                  <div key={employee._id} className="flex items-center space-x-2">
                    <Checkbox
                      id={employee._id}
                      checked={selectedEmployees.includes(employee._id)}
                      onCheckedChange={(checked) => {
                        setSelectedEmployees(prev =>
                          checked
                            ? [...prev, employee._id]
                            : prev.filter(id => id !== employee._id)
                        );
                      }}
                    />
                    <Label htmlFor={employee._id}>{employee.firstName} {employee.lastName}</Label>
                  </div>
                ))}
              </div>
              <DialogFooter>
                <Button onClick={handleEmployeeSelect}>Applica</Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          <PDFDownloadLink
            document={
              <AttendanceDocument
                attendanceData={filteredAttendanceData}
                selectedMonth={selectedMonth}
                daysInMonth={daysInMonth}
              />
            }
            fileName={`Foglio_Presenze_${selectedMonth.getFullYear()}-${String(
              selectedMonth.getMonth() + 1
            ).padStart(2, "0")}.pdf`}
          >
            <Button>Esporta</Button>
          </PDFDownloadLink>
          <HelpSheet route="/employees" />
        </div>
      </div>

      <div ref={componentRef} className="relative overflow-x-auto shadow-md sm:rounded-lg mx-auto">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border-collapse table-fixed-first-column">
          <thead className="text-xs text-gray-700 bg-gray-300 dark:bg-gray-700 dark:text-gray-400">
            <tr className="text-center">
              <th scope="col" className="px-6 py-3 border border-gray-300 bg-gray-300 sticky left-0 z-10">
                Nome
              </th>
              {Array.from({ length: daysInMonth }, (_, i) => {
                const currentDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), i + 1);
                return (
                  <th key={i} scope="col" className="px-3 py-3 border border-gray-300 min-w-[80px]">
                    {`${i + 1}-${getDayOfWeek(currentDate)}`}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {filteredAttendanceData.map((employee) => (
              <tr key={employee._id} className="odd:bg-white text-center odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                <td className="px-6 py-4 text-center border border-gray-300 font-semibold bg-white sticky left-0 z-10">
                  {employee.firstName}
                </td>
                {Array.from({ length: daysInMonth }, (_, i) => {
                  const currentDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), i + 1);
                  const dateString = format(currentDate, "dd-MM-yyyy");

                  const attendanceForDate = employee.attendance.find((a) => a.date === dateString);
                  const planDetails = companyPlans.find(p => p._id === attendanceForDate?.planId);

                  return (
                    <td
                      key={i}
                      className="px-3 py-4 text-center border border-gray-300 cursor-pointer min-w-[80px]"
                      style={{ backgroundColor: planDetails?.color || "white" }}
                      onClick={() => openModal(employee, i + 1)}
                    >
                      {attendanceForDate ? (
                        <>
                          <span className={`font-semibold ${planDetails ? 'text-white' : 'text-green-500'}`}>
                            {planDetails ? planDetails.acronym : 'P'}
                          </span>
                          <br />
                          <span className={`text-nowrap ${planDetails ? 'text-white' : 'text-green-500'}`}>
                            {formatWorkingHours(attendanceForDate.workingHours)}
                          </span>
                        </>
                      ) : (
                        <span>{""}</span>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Dialog open={isModalOpen} onOpenChange={setModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Utente selezionato {selectedEmployee?.firstName}</DialogTitle>
          </DialogHeader>
          <ModalContent
            employee={selectedEmployee}
            date={selectedDate}
            onSubmit={handleModalSubmit}
            companyPlans={companyPlans}
            initialAttendanceData={selectedEmployee?.attendance.find((a) => a.date === format(new Date(selectedDate), "dd-MM-yyyy"))}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

const ModalContent = ({
  employee,
  date,
  onSubmit,
  companyPlans,
  initialAttendanceData,
}) => {
  const [selectedPlan, setSelectedPlan] = useState(initialAttendanceData?.planId || "");
  const [startTime, setStartTime] = useState(initialAttendanceData?.startTime || "");
  const [exitTime, setExitTime] = useState(initialAttendanceData?.exitTime || "");

  const handleSubmit = () => {
    const selectedPlanDetails = companyPlans.find(p => p._id === selectedPlan);
    onSubmit({
      _id: initialAttendanceData?._id,
      employeeId: employee._id,
      date: format(new Date(date), "dd-MM-yyyy"),
      attendanceState: "present",
      startTime: startTime || selectedPlanDetails?.startTime,
      exitTime: exitTime || selectedPlanDetails?.endTime,
      planId: selectedPlan,
      companyId: JSON.parse(localStorage.getItem("user")).companyId,
    });
  };

  const selectedPlanDetails = companyPlans.find(p => p._id === selectedPlan);

  return (
    <div className="space-y-4">
      <p>Data: {date}</p>
      <div className="space-y-2">
        <Label htmlFor="planSelect">Seleziona turno</Label>
        <Select value={selectedPlan} onValueChange={setSelectedPlan}>
          <SelectTrigger id="planSelect">
            <SelectValue placeholder="Seleziona un turno" />
          </SelectTrigger>
          <SelectContent>
            {companyPlans.map((plan) => (
              <SelectItem key={plan._id} value={plan._id}>
                {`${plan.name} (${plan.acronym}) - ${plan.workSite}`}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {selectedPlanDetails && (
        <div className="space-y-2">
          <h3 className="font-bold">Plan Details:</h3>
          <p>Name: {selectedPlanDetails.name}</p>
          <p>Acronym: {selectedPlanDetails.acronym}</p>
          <p>Work Site: {selectedPlanDetails.workSite}</p>
          <p>Default Time: {selectedPlanDetails.startTime} - {selectedPlanDetails.endTime}</p>
          {selectedPlanDetails.isContinuous ? (
            <p>Continuous Shift</p>
          ) : (
            <p>Break: {selectedPlanDetails.breakStartTime} - {selectedPlanDetails.breakEndTime}</p>
          )}
        </div>
      )}

      <DialogFooter>
        <Button onClick={handleSubmit} disabled={!selectedPlan}>
          {initialAttendanceData ? 'Aggiornamento' : 'Salva'}
        </Button>
      </DialogFooter>
    </div>
  );
};

export default AttendanceSheet;