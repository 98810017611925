import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import AssistenzaComponent from '../../../components/admin/assistenza/index'

const AssistenzaDetails = () => {
  return (
    <Layout>
      <AssistenzaComponent />
    </Layout>
  )
}

export default AssistenzaDetails