import {createJsonTypeInstance}  from './index';
const axios =createJsonTypeInstance();

export const addClient = async (data) => {
  try {
    const response = await axios.post('/client' , data);
    return response;
  } catch (error) {
    throw error.response.data; 
  }
};

export const importClientList = async (data) => {
  try {
    const response = await axios.post('/client/import-clients' , data);
    return response;
  } catch (error) {
    throw error.response.data; 
  }
};

export const fetchClients = async (companyId, currentPage, limit ,searchQuery ,filter) => {
    try {
      const response = await axios.get(`/client/all-Clients/${companyId}?page=${currentPage}&limit=${limit}&search=${searchQuery}&filter=${filter}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  };


  export const getSingleClient = async (id) => {
    try {
      const response = await axios.get(`/client/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  };

  export const updateClient = async (id, updateData) => {
    try {
      const response = await axios.put(`/client/${id}`, updateData);
      return response;
    } catch (error) {
      throw error.response;
    }
  };

  export const deleteClient = async (id) => {
    try {
      const response = await axios.delete(`/client/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  };