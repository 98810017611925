import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  getContabilita, getAccountingItems,
  addAccountingItem,
  updateAccountingItem,
  deleteAccountingItem
} from '../../../apis/ConstructionSite';
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { Loader2, Plus, Trash2, Edit } from "lucide-react";
import ConstructionSiteNavbar from './ConstructionSiteNavbar';

const categories = [
  { id: 'materiali', name: 'Materiali' },
  { id: 'manodopera', name: 'Manodopera' },
  { id: 'attrezzatura', name: 'Attrezzatura' }
];

const Accounting = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [totalWorkCost, setTotalWorkCost] = useState(0);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [newItem, setNewItem] = useState({
    category: '',
    name: '',
    description: '',
    unit: '',
    quantity: 0,
    price: 0,
    total: 0
  });

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const [contabilitaData, accountingData] = await Promise.all([
        getContabilita(id),
        getAccountingItems(id)
      ]);

      setRemainingAmount(contabilitaData.amountPaid || 0);
      setItems(accountingData.items || []);
      setTotalWorkCost(accountingData.workCost || 0);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedItem = editingItem || newItem;
    const newValues = { ...updatedItem, [name]: value };

    if (name === 'quantity' || name === 'price') {
      newValues.total = newValues.quantity * newValues.price;
    }

    if (editingItem) {
      setEditingItem(newValues);
    } else {
      setNewItem(newValues);
    }
  };

  const handleAddItem = async () => {
    try {
      await addAccountingItem(id, newItem);
      await fetchData();
      setNewItem({
        category: '',
        name: '',
        description: '',
        unit: '',
        quantity: 0,
        price: 0,
        total: 0
      });
      setIsAddModalOpen(false);
    } catch (error) {
      console.error('Error adding item:', error);
    }
  };

  const handleEditClick = (item) => {
    setEditingItem(item);
    setIsEditModalOpen(true);
  };

  const handleEditSave = async () => {
    try {
      await updateAccountingItem(id, editingItem._id, editingItem);
      await fetchData();
      setEditingItem(null);
      setIsEditModalOpen(false);
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleDeleteItem = async (itemId) => {
    try {
      await deleteAccountingItem(id, itemId);
      await fetchData();
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR'
    }).format(amount);
  };

  return (
    <>
      <ConstructionSiteNavbar />
      <div className="p-6 space-y-6">
        {/* Summary Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Card>
            <CardHeader>
              <CardTitle>Importo Pagato</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-bold text-red-600">
                {formatCurrency(remainingAmount)}
              </p>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Costo dei Lavori</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-bold">
                {formatCurrency(totalWorkCost)}
              </p>
            </CardContent>
          </Card>
        </div>

        {/* Items Table */}
        <div>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Dettaglio Costi</h2>
            <Button onClick={() => setIsAddModalOpen(true)}>
               Aggiungi
            </Button>
          </div>

          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Categoria</TableHead>
                <TableHead>Nome</TableHead>
                <TableHead>Descrizione</TableHead>
                <TableHead>U.M.</TableHead>
                <TableHead className="text-right">Q.tà</TableHead>
                <TableHead className="text-right">Prezzo</TableHead>
                <TableHead className="text-right">Totale</TableHead>
                <TableHead className="text-center">Azioni</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{categories.find(c => c.id === item.category)?.name}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.unit}</TableCell>
                  <TableCell className="text-right">{item.quantity}</TableCell>
                  <TableCell className="text-right">{formatCurrency(item.price)}</TableCell>
                  <TableCell className="text-right">{formatCurrency(item.total)}</TableCell>
                  <TableCell className="text-center">
                    <div className="flex justify-center space-x-2">
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => handleEditClick(item)}
                      >
                        <Edit className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => handleDeleteItem(item._id)}
                      >
                        <Trash2 className="h-4 w-4 text-red-500" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {items.length === 0 && (
                <TableRow>
                  <TableCell colSpan={8} className="text-center py-4">
                    Nessun elemento registrato
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        {/* Add Item Modal */}
        <Dialog open={isAddModalOpen} onOpenChange={setIsAddModalOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Aggiungi Elemento</DialogTitle>
              <DialogDescription>
                Inserisci i dettagli del nuovo elemento.
              </DialogDescription>
            </DialogHeader>

            <div className="grid gap-4 py-4">
              <div className="grid gap-2">
                <Label>Categoria</Label>
                <Select
                  value={newItem.category}
                  onValueChange={(value) => handleInputChange({ target: { name: 'category', value } })}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Seleziona categoria" />
                  </SelectTrigger>
                  <SelectContent>
                    {categories.map((category) => (
                      <SelectItem key={category.id} value={category.id}>
                        {category.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="grid gap-2">
                <Label>Nome</Label>
                <Input
                  name="name"
                  value={newItem.name}
                  onChange={handleInputChange}
                />
              </div>

              <div className="grid gap-2">
                <Label>Descrizione</Label>
                <Input
                  name="description"
                  value={newItem.description}
                  onChange={handleInputChange}
                />
              </div>

              <div className="grid gap-2">
                <Label>Unità di Misura</Label>
                <Input
                  name="unit"
                  value={newItem.unit}
                  onChange={handleInputChange}
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div className="grid gap-2">
                  <Label>Quantità</Label>
                  <Input
                    type="number"
                    name="quantity"
                    value={newItem.quantity}
                    onChange={handleInputChange}
                    min="0"
                    step="0.01"
                  />
                </div>

                <div className="grid gap-2">
                  <Label>Prezzo Unitario</Label>
                  <Input
                    type="number"
                    name="price"
                    value={newItem.price}
                    onChange={handleInputChange}
                    min="0"
                    step="0.01"
                  />
                </div>
              </div>

              <div className="grid gap-2">
                <Label>Totale</Label>
                <Input
                  type="number"
                  value={newItem.quantity * newItem.price}
                  readOnly
                  disabled
                />
              </div>
            </div>

            <DialogFooter>
              <Button variant="outline" onClick={() => setIsAddModalOpen(false)}>
                Annulla
              </Button>
              <Button onClick={handleAddItem}>
                Aggiungi
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        {/* Edit Item Modal */}
        <Dialog open={isEditModalOpen} onOpenChange={setIsEditModalOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Modifica Elemento</DialogTitle>
              <DialogDescription>
                Modifica i dettagli dell'elemento.
              </DialogDescription>
            </DialogHeader>

            <div className="grid gap-4 py-4">
              <div className="grid gap-2">
                <Label>Categoria</Label>
                <Select
                  value={editingItem?.category}
                  onValueChange={(value) => handleInputChange({ target: { name: 'category', value } })}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Seleziona categoria" />
                  </SelectTrigger>
                  <SelectContent>
                    {categories.map((category) => (
                      <SelectItem key={category.id} value={category.id}>
                        {category.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="grid gap-2">
                <Label>Nome</Label>
                <Input
                  name="name"
                  value={editingItem?.name || ''}
                  onChange={handleInputChange}
                />
              </div>

              <div className="grid gap-2">
                <Label>Descrizione</Label>
                <Input
                  name="description"
                  value={editingItem?.description || ''}
                  onChange={handleInputChange}
                />
              </div>

              <div className="grid gap-2">
                <Label>Unità di Misura</Label>
                <Input
                  name="unit"
                  value={editingItem?.unit || ''}
                  onChange={handleInputChange}
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div className="grid gap-2">
                  <Label>Quantità</Label>
                  <Input
                    type="number"
                    name="quantity"
                    value={editingItem?.quantity || 0}
                    onChange={handleInputChange}
                    min="0"
                    step="0.01"
                  />
                </div>

                <div className="grid gap-2">
                  <Label>Prezzo Unitario</Label>
                  <Input
                    type="number"
                    name="price"
                    value={editingItem?.price || 0}
                    onChange={handleInputChange}
                    min="0"
                    step="0.01"
                  />
                </div>
              </div>

              <div className="grid gap-2">
                <Label>Totale</Label>
                <Input
                  type="number"
                  value={(editingItem?.quantity || 0) * (editingItem?.price || 0)}
                  readOnly
                  disabled
                />
              </div>
            </div>

            <DialogFooter>
              <Button
                variant="outline"
                onClick={() => {
                  setIsEditModalOpen(false);
                  setEditingItem(null);
                }}
              >
                Annulla
              </Button>
              <Button onClick={handleEditSave}>
                Salva Modifiche
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

      </div>
    </>
  );
};

export default Accounting;