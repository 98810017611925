import {createJsonTypeInstance,createMultipartInstance}  from './index';
const axios = createJsonTypeInstance();

export const fetchCompany = async (id) => {
  try {
    const response = await axios.get(`/company/${id}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const fetchCompanies = async () => {
  try {
    const response = await axios.get("/company");
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const addPoints = async (companyId, points) => {
  try {
    const response = await axios.patch(`/company/${companyId}/points`, { points });
    return response;
  } catch (error) {
    throw error.response;
  }
}

export const updateCompany = async (id, data) => {
  try {
    const axiosInstance = createMultipartInstance();
    const uploadData = new FormData();
    Object.keys(data).forEach(key => {
      if ((key === 'file') && data[key]) {
        uploadData.append(key, data[key][0]); 
      } else {
        uploadData.append(key, data[key]);
      }
    });

    const response = await axiosInstance.patch(`/company/${id}`, uploadData);
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    throw error.response;
  }
};
