import React from 'react';
import Placeholder from "../../../assest/placeholder.jpeg";
import Construct from "../../../assest/construct.jpg";
import VideoHero from '../../VideoHero';

const RestructFeaturesSection = () => {
  return (
    <section className="py-16 bg-primary">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 text-white">
            Dai preventivi alla gestione del cantiere:
            <br />
            il tuo progetto completo con Restruct
          </h2>
          <p className="text-lg text-gray-200">
            Con Restruct, hai il supporto che ti serve per ogni fase: dalla preparazione dei
            preventivi alla gestione quotidiana del cantiere. Un'unica piattaforma per
            semplificare il tuo lavoro e rendere più efficace ogni progetto.
          </p>
        </div>

        <VideoHero />
      </div>
    </section>
  );
};

const FeatureItem = ({ number, title, description }) => {
  return (
    <div className="flex items-start">
      <div className="flex-shrink-0 w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center text-blue-600 font-bold text-lg mr-4">
        {number}
      </div>
      <div>
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  );
};

export default RestructFeaturesSection;