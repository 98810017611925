// src/components/ConstructionSiteLayout.jsx
import React from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';

function ConstructionSiteLayout() {

  return (
    <div className="m-1">
      <Outlet />
    </div>
  );
}

export default ConstructionSiteLayout;