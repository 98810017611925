// apis/index.js
import axios from "axios";
import Cookies from 'js-cookie';

// Auth Utils
export const TOKEN_COOKIE_NAME = 'auth_token';
export const TOKEN_STORAGE_KEY = 'user';

export const getAuthToken = () => {
  try {
    const cookieToken = Cookies.get(TOKEN_COOKIE_NAME);
    if (cookieToken) return cookieToken;
    
    const userData = JSON.parse(localStorage.getItem(TOKEN_STORAGE_KEY));
    return userData?.token;
  } catch (error) {
    console.error('Error getting auth token:', error);
    return null;
  }
};

export const setAuthToken = (token) => {
  try {
    // Set cookie with domain for production
    Cookies.set(TOKEN_COOKIE_NAME, token, {
      expires: 1,
      path: '/',
      domain: window.location.hostname === 'localhost' ? 'localhost' : '.glds.it', // Add your domain
      secure: window.location.protocol === 'https:',
      sameSite: 'strict'
    });
    
    const userData = JSON.parse(localStorage.getItem(TOKEN_STORAGE_KEY) || '{}');
    localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify({
      ...userData,
      token
    }));
  } catch (error) {
    console.error('Error setting auth token:', error);
  }
};



export const clearAuth = () => {
  try {
    Cookies.remove(TOKEN_COOKIE_NAME, { path: '/' });
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    localStorage.removeItem('breadcrumb');
  } catch (error) {
    console.error('Error clearing auth:', error);
  }
};

// Modified axios instance creation
export const createJsonTypeInstance = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json"
    },
    // Add additional config for production
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN'
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      const token = getAuthToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      // Remove manual cookie setting as it's handled by withCredentials
      return config;
    },
    (error) => Promise.reject(error)
  );

  return axiosInstance;
};

export const createMultipartInstance = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      const token = getAuthToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        config.headers.Cookie = `${TOKEN_COOKIE_NAME}=${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        clearAuth();
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

// Create instance for use in API functions
const axiosInstance = createJsonTypeInstance();

// Export configured axios instances
export const api = {
  auth: createJsonTypeInstance(),
  upload: createMultipartInstance()
};

// Auth state helper functions
export const isAuthenticated = () => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    return !!user?.token;
  } catch (error) {
    return false;
  }
};

// Custom Error Class
export class ApiError extends Error {
  constructor(message, status, data) {
    super(message);
    this.name = 'ApiError';
    this.status = status;
    this.data = data;
  }
}