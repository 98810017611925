import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import UtentiComponent from '../../../components/admin/utenti/index'

function Utenti() {
  return (
    <Layout>
      <UtentiComponent />
    </Layout>
  )
}

export default Utenti