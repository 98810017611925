import React from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import { Button } from "../../ui/button";

const Confirmation = ({ isOpen, onClose, deleteFunction, deleteContent }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-xl">
            {deleteContent?.title}
          </DialogTitle>
          <DialogDescription className="text-base pt-2">
            {deleteContent?.des}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="flex justify-between sm:justify-between gap-4">
          <Button
            type="button"
            variant="outline"
            onClick={onClose}
            className="flex-1"
          >
            Annulla
          </Button>
          <Button
            type="button"
            variant="destructive"
            onClick={deleteFunction}
            className="flex-1"
          >
            Elimina
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default Confirmation;