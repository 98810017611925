// apis/searchApi.js
import { createJsonTypeInstance } from './index';

export const performSearch = async (query) => {
  try {
    const axiosInstance = createJsonTypeInstance();
    const response = await axiosInstance.get(`/search?query=${encodeURIComponent(query)}`);
    return response.data;
  } catch (error) {
    console.error("Search error:", error);
    throw error;
  }
};