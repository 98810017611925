import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight, FaPrint, FaPlus, FaCopy, FaTrash, FaCalendarAlt } from 'react-icons/fa';
import { format } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createWorkJournalEntry, getWorkJournalEntries, exportWorkJournalToPDF, getWorkJournalEntry, updateWorkJournalEntry } from '../../../apis/ConstructionWorkJournal';
import ConstructionSiteNavbar from './ConstructionSiteNavbar';

import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Textarea } from "../../ui/textarea";
import { Label } from "../../ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import { Calendar } from "../../ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../ui/popover";
import { fetchCompany } from '../../../apis/CompanyDetail';

function Produzione() {
  const { id } = useParams();
  const [entries, setEntries] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [annotations, setAnnotations] = useState('');
  const [temperature, setTemperature] = useState('');
  const [weather, setWeather] = useState('');
  const [tableData, setTableData] = useState([]);
  const [currentEntryId, setCurrentEntryId] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    fetchEntries();
    fetchCompanyDetails();
  }, [id]);

  const fetchCompanyDetails = async () => {
    try {
      if (user?.companyId) {
        const response = await fetchCompany(user.companyId);
        if (response.data?.company?.companyName) {
          setCompanyName(response.data.company.companyName);
        }
      }
    } catch (error) {
      console.error('Errore nel recupero dei dettagli azienda:', error);
      toast.error('Errore nel recupero dei dettagli azienda');
    }
  };

  useEffect(() => {
    fetchEntryForDate(selectedDate);
  }, [selectedDate]);

  const fetchEntries = async () => {
    try {
      const data = await getWorkJournalEntries(id);
      setEntries(data);
    } catch (error) {
      console.error('Errore nel recupero delle voci:', error);
      toast.error('Errore nel recupero delle voci');
    }
  };

  const fetchEntryForDate = async (date) => {
    try {
      const entry = await getWorkJournalEntry(id, date);
      if (entry) {
        setAnnotations(entry.annotations || '');
        setTemperature(entry.temperature || '');
        setWeather(entry.weatherConditions || '');
        setTableData(entry.entries || []);
        setCurrentEntryId(entry._id);
      } else {
        resetForm();
      }
    } catch (error) {
      console.error('Errore nel recupero della voce per la data selezionata:', error);
      toast.error('Errore nel recupero della voce per la data selezionata');
      resetForm();
    }
  };

  const resetForm = () => {
    setAnnotations('');
    setTemperature('');
    setWeather('');
    setTableData([]);
    setCurrentEntryId(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const entryData = {
        date: selectedDate,
        annotations,
        temperature,
        weatherConditions: weather,
        entries: tableData
      };
      if (currentEntryId) {
        await updateWorkJournalEntry(id, currentEntryId, entryData);
        toast.success('Voce aggiornata con successo');
      } else {
        await createWorkJournalEntry(id, entryData);
        toast.success('Nuova voce creata con successo');
      }
      await fetchEntries();
    } catch (error) {
      console.error('Errore nel salvataggio della voce:', error);
      toast.error('Errore nel salvataggio della voce');
    }
  };

  const handlePrint = async () => {
    try {
      if (currentEntryId) {
        const pdfBlob = await exportWorkJournalToPDF(id, currentEntryId);
        const url = window.URL.createObjectURL(new Blob([pdfBlob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `work_journal_${format(selectedDate, 'yyyy-MM-dd')}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        toast.success('PDF generato con successo');
      } else {
        toast.error('Nessuna voce trovata per la data selezionata');
      }
    } catch (error) {
      console.error('Errore nella stampa:', error);
      toast.error(`Errore nella generazione del PDF: ${error.message || 'Errore sconosciuto'}`);
    }
  };

  const addTableRow = () => {
    setTableData([...tableData, { name: '', company: companyName || 'Sagia Costruzioni', quantity: 1 }]);
  };

  const duplicateTableRow = (index) => {
    const newRow = { ...tableData[index] };
    setTableData([...tableData.slice(0, index + 1), newRow, ...tableData.slice(index + 1)]);
  };

  const deleteTableRow = (index) => {
    setTableData(tableData.filter((_, i) => i !== index));
  };

  return (
    <div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <ConstructionSiteNavbar />
      <div className="p-2">
        <Button onClick={handlePrint} className="my-4">
          <FaPrint className="mr-2" /> Stampa giornale
        </Button>
        <div className="flex justify-center items-center h-full w-full">
          <Card className=" border-0 w-full max-w-md">
            <CardContent className="flex justify-between items-center p-4">
              <div className="flex items-center gap-2">
                <Button
                  variant="outline"
                  size="icon"
                  onClick={() =>
                    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() - 1)))
                  }
                >
                  <FaArrowLeft />
                </Button>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button variant="outline">
                      <FaCalendarAlt className="mr-2" />
                      {format(selectedDate, "PP")}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0">
                    <Calendar
                      mode="single"
                      selected={selectedDate}
                      onSelect={(date) => date && setSelectedDate(date)}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <Button
                  variant="outline"
                  size="icon"
                  onClick={() =>
                    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() + 1)))
                  }
                >
                  <FaArrowRight />
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            <Card className='border-0'>
              <CardHeader>
                <CardTitle>Annotazioni ed osservazioni sull'andamento e l'esecuzione dei lavori</CardTitle>
              </CardHeader>
              <CardContent>
                <Textarea
                  value={annotations}
                  onChange={(e) => setAnnotations(e.target.value)}
                  className="w-full"
                />
              </CardContent>
            </Card>
            <Card className='border-0'>
              <CardHeader>
                <CardTitle>Condizioni meteorologiche</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="temperature">Temperatura</Label>
                    <Select
                      id="temperature"
                      value={temperature}
                      onValueChange={(value) => setTemperature(value)}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Seleziona temperatura" />
                      </SelectTrigger>
                      <SelectContent className="max-h-[200px]">
                        {[...Array(91)].map((_, index) => {
                          const value = (index / 2).toFixed(1);
                          return (
                            <SelectItem key={value} value={value}>
                              {value.replace('.', ',')}°C
                            </SelectItem>
                          );
                        })}
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="weather">Meteo</Label>
                    <Select
                      id="weather"
                      value={weather}
                      onValueChange={(value) => setWeather(value)}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Seleziona condizioni meteo" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Caldo intenso">Caldo intenso</SelectItem>
                        <SelectItem value="Gelo">Gelo</SelectItem>
                        <SelectItem value="Meteo estremo">Meteo estremo</SelectItem>
                        <SelectItem value="Nebbia">Nebbia</SelectItem>
                        <SelectItem value="Neve">Neve</SelectItem>
                        <SelectItem value="Nuvoloso">Nuvoloso</SelectItem>
                        <SelectItem value="Parzialmente nuvoloso">Parzialmente nuvoloso</SelectItem>
                        <SelectItem value="Pioggia">Pioggia</SelectItem>
                        <SelectItem value="Sereno">Sereno</SelectItem>
                        <SelectItem value="Siccità">Siccità</SelectItem>
                        <SelectItem value="Temperature variabili">Temperature variabili</SelectItem>
                        <SelectItem value="Umidità alta">Umidità alta</SelectItem>
                        <SelectItem value="Vento forte">Vento forte</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <Card className="mt-4 border-0">
            <CardHeader className="flex flex-row items-center justify-between">
              <CardTitle>Operai, Mezzi ed Attrezzatura utilizzati</CardTitle>
              <div className="flex items-center space-x-2">
                <Button onClick={addTableRow}>
                  Aggiungi
                </Button>
                <Button onClick={() => duplicateTableRow(tableData.length - 1)} variant="secondary">
                  Duplica
                </Button>
                <Button onClick={() => deleteTableRow(tableData.length - 1)} variant="destructive">
                  Elimina
                </Button>
              </div>
            </CardHeader>
            <CardContent>
              <div>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-[40%]">Nome</TableHead>
                      <TableHead className="w-[40%]">Azienda</TableHead>
                      <TableHead className="w-[20%]">Q.tà</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {tableData.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Input
                            value={row.name}
                            onChange={(e) => {
                              const newData = [...tableData];
                              newData[index].name = e.target.value;
                              setTableData(newData);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Select
                            value={row.company}
                            onValueChange={(value) => {
                              const newData = [...tableData];
                              newData[index].company = value;
                              setTableData(newData);
                            }}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Seleziona azienda" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value={companyName || "Sagia Costruzioni"}>
                                {companyName || "Sagia Costruzioni"}
                              </SelectItem>
                            </SelectContent>
                          </Select>
                        </TableCell>
                        <TableCell>
                          <Input
                            type="number"
                            value={row.quantity}
                            onChange={(e) => {
                              const newData = [...tableData];
                              newData[index].quantity = parseInt(e.target.value);
                              setTableData(newData);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </CardContent>
          </Card>
          <div className="flex justify-end mt-6">
            <Button type="submit">Salva</Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Produzione;