import React, { useState, useEffect, useRef } from "react";
import { getCompanySharesData } from "../../../../apis/companyShares";
import Ownership from "./Ownership";
import Composition from "./Composition";
import { useReactToPrint } from "react-to-print";
import { Button } from "../../../ui/button";

const Property = () => {
  const [dataUpdated, setDataUpdated] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user.companyId;
  const [SharesData, setSharesData] = useState([
    {
      role: "Amministratore",
      name: "",
      shares: "",
      labelColor: "#FFFFFF",
    },
  ]);

  const fetchCompanyShares = async () => {
    try {
      const response = await getCompanySharesData(companyId);
      if (response.data.length > 0) {
        setSharesData(response.data);
      }
    } catch (error) {
      console.error("Error fetching company shares:", error);
    }
  };

  useEffect(() => {
    fetchCompanyShares();
  }, [dataUpdated]);

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Company Shares"
  });

  return (
    <div className="p-4">
      <div
        ref={printRef}
        className="flex flex-col sm:flex-row gap-4 justify-center items-start"
      >
        <Ownership
          setSharesData={setSharesData}
          SharesData={SharesData}
          dataUpdated={dataUpdated}
          setDataUpdated={setDataUpdated}
          printRef={printRef}
          handlePrint={handlePrint}
        />
        <Composition SharesData={SharesData} />
      </div>
    </div>
  );
};

export default Property;  