import React, { useEffect, useState } from "react";
import { Edit, Building, Phone, Mail, MapPin, Briefcase, CreditCard, Globe, Hash, User, Bank, FileText } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { fetchCompany } from "../../../../apis/CompanyDetail";
import { Button } from "../../../ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card";
import { Skeleton } from "../../../ui/skeleton";
import HelpSheet from "../../sharedComponent/HelpSheet";

const Information = () => {
  const navigate = useNavigate();
  const [companyInfo, setCompanyInfo] = useState({});
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user.companyId;

  const getCompanyData = async () => {
    try {
      const response = await fetchCompany(companyId);
      setCompanyInfo(response.data.company);
      console.log("Company Info:", response.data.company); // Add this line
      setDataIsLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompanyData();
  }, []);

  const keys = companyInfo
    ? Object.keys(companyInfo).filter(
        (key) =>
          !["__v", "_id", "adminId", "subtitleLine1", "subtitleLine2", "logo1", "logo2"].includes(key)
      )
    : [];

  const translateName = (key) => {
    const italianLanguage = {
      companyName: "Ragione Sociale",
      address: "Indirizzo",
      state: "Regione",
      postalCode: "CAP",
      addressNote: "Note indirizzo",
      fax: "Fax",
      website: "Sito web",
      iban: "IBAN",
      nickName: "Soprannome",
      country: "Nazione",
      city: "Città",
      province: "Provincia",
      taxIdCode: "Partita IVA",
      businessEmail: "Email principale",
      secondaryEmail: "Email secondaria",
      pec: "PEC",
      telephoneNumber: "Telefono",
      mobileNumber: "Cellulare",
      typeOfBusiness: "Tipologia azienda",
      inpsPosition: "Posizione INPS",
      inailPosition: "Posizione INAIL",
      edilcassaPosition: "Posizione Cassa Edile / Edilcassa",
      bank: "Banca",
    };
    return italianLanguage[key] || key;
  };

  const renderLogo = (logoKey, label) => {
    return (
      <div className="flex flex-col items-center">
        <span className="text-sm font-medium text-gray-500 mb-2">{label}</span>
        {!companyInfo[logoKey] ? (
          <div className="w-[120px] h-[120px] rounded-full bg-gray-200 flex items-center justify-center text-gray-400">
            <Building size={48} />
          </div>
        ) : (
          <img
            src={`${process.env.REACT_APP_BACKEND_URL}/${companyInfo[logoKey]}`}
            alt={`Logo ${logoKey}`}
            className="w-[120px] h-[120px] object-cover rounded-full border-4 border-gray-200"
          />
        )}
      </div>
    );
  };

  // Create an ordered array of fields
  const orderedFields = [
    'companyName',
    'nickName',
    'typeOfBusiness',
    'businessEmail',
    'secondaryEmail',
    'pec',
    'telephoneNumber',
    'mobileNumber',
    'fax',
    'website',
    'address',
    'postalCode',
    'city',
    'province',
    'state',
    'country',
    'taxIdCode',
    'iban',
    'bank',
    'inpsPosition',
    'inailPosition',
    'edilcassaPosition',
    'addressNote',
  ];

  // Use this order in the renderInfoGrid function
  const renderInfoGrid = (keys) => {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {orderedFields.map((key) => (
          keys.includes(key) && (
            <div key={key} className="flex items-start space-x-3">
              {getIconForKey(key)}
              <div>
                <span className="font-medium text-gray-700 block">{translateName(key)}</span>
                <p className="text-gray-600">{companyInfo[key] || ""}</p>
              </div>
            </div>
          )
        ))}
      </div>
    );
  };

  const getIconForKey = (key) => {
    const iconClass = "text-blue-500 mt-1";
    const iconSize = 20;

    switch (key) {
      case 'companyName':
      case 'typeOfBusiness':
        return <Briefcase className={iconClass} size={iconSize} />;
      case 'telephoneNumber':
      case 'mobileNumber':
      case 'fax':
        return <Phone className={iconClass} size={iconSize} />;
      case 'businessEmail':
      case 'secondaryEmail':
      case 'pec':
        return <Mail className={iconClass} size={iconSize} />;
      case 'address':
      case 'city':
      case 'province':
      case 'country':
      case 'state':
      case 'postalCode':
      case 'addressNote':
        return <MapPin className={iconClass} size={iconSize} />;
      case 'website':
        return <Globe className={iconClass} size={iconSize} />;
      case 'iban':
      case 'bank':
        return <CreditCard className={iconClass} size={iconSize} />;
      case 'taxIdCode':
        return <Hash className={iconClass} size={iconSize} />;
      case 'nickName':
        return <User className={iconClass} size={iconSize} />;
      case 'inpsPosition':
      case 'inailPosition':
        return <FileText className={iconClass} size={iconSize} />;
      default:
        return <Building className={iconClass} size={iconSize} />;
    }
  };
  console.log(companyInfo)
  return (
    <div className="mx-auto bg-gray-50">
      <div className="flex justify-between items-center mb-8">
        <Button
          onClick={() => navigate(`/agency/edit-information/${companyInfo?._id}`)}
          variant="outline"
        >
          <Edit className="mr-2 h-4 w-4" /> Modifica
        </Button>
        <HelpSheet route="/agency" />
      </div>

      {!dataIsLoaded ? (
        <Skeleton className="h-[800px] w-full" />
      ) : (
        <Card className="border-none">
          <CardHeader className="pb-4">
            <div className="flex justify-start space-x-8 mb-4">
              {renderLogo("logo1", "Logo 1")}
              {renderLogo("logo2", "Logo 2")}
            </div>
          </CardHeader>
          <CardContent>
            {renderInfoGrid(keys)}
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default Information;