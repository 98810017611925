import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import Luoghi_di_depositoComponent from '../../../components/admin/magazzino/luoghi_di_deposito'

function Luoghi_di_depositoDetails(){
  return (
    <Layout>
      <Luoghi_di_depositoComponent />
    </Layout>
  )
}

export default Luoghi_di_depositoDetails