import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../../../ui/tabs";
import { toast } from "react-toastify";
import AllEmployees from "./allEmployess/AllEmployees";
import Training from "./training";
import Absence from "./absence";
import Salary from "./salary";
import Planning from "./planning";
import AttendanceSheet from "./attendanceSheet.jsx";
import { Employees } from "../../../../apis/Employees";
import { ChevronLeft, ChevronRight } from "lucide-react";

const PERSONAL_TAB = [
  { name: "Tutti i dipendenti", value: "tutti-i-dipendenti" },
  { name: "Foglio presenze", value: "foglio-presenze" },
  { name: "Formazione", value: "formazione" },
  { name: "Assenze", value: "assenze" },
  { name: "Retribuzione", value: "retribuzione" },
  { name: "Pianificazione", value: "pianificazione" },
];

const ScrollableTabsList = ({ children }) => {
  const scrollRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 1);
    };

    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll);
      handleScroll();

      const resizeObserver = new ResizeObserver(handleScroll);
      resizeObserver.observe(scrollElement);

      return () => {
        scrollElement.removeEventListener('scroll', handleScroll);
        resizeObserver.disconnect();
      };
    }
  }, []);

  const scroll = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = direction === 'left' ? -100 : 100;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <div className="relative bg-[#06052C] rounded overflow-hidden">
      {showLeftArrow && (
        <button 
          onClick={() => scroll('left')} 
          className="absolute left-0 top-1/2 -translate-y-1/2 bg-white rounded-md p-1 z-10"
        >
          <ChevronLeft size={20} />
        </button>
      )}
      <div ref={scrollRef} className="overflow-x-auto scrollbar-hide">
        {children}
      </div>
      {showRightArrow && (
        <button 
          onClick={() => scroll('right')} 
          className="absolute right-0 top-1/2 -translate-y-1/2 bg-white rounded-md p-1 z-10"
        >
          <ChevronRight size={20} />
        </button>
      )}
    </div>
  );
};

const EmployeeComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("tutti-i-dipendenti");
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeTransformData, setEmployeeTransformData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isDelete, setIsDelete] = useState(false);
  const [dataUpdate, setDataUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Get companyId from localStorage
  const getCompanyId = () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.companyId;
    } catch (error) {
      console.error("Error parsing user data:", error);
      return null;
    }
  };

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    const tab = PERSONAL_TAB.find(t => t.value === path);
    if (tab) {
      setActiveTab(tab.value);
    } else {
      navigate("/employees/tutti-i-dipendenti", { replace: true });
    }
  }, [location, navigate]);

  const fetchEmployees = async () => {
    const companyId = getCompanyId();
    
    if (!companyId) {
      toast.error("Company ID not found. Please log in again.");
      navigate("/login");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await Employees(companyId, currentPage, limit, searchQuery);
      if (response.status === 200) {
        const employees = response.data?.employees || [];
        setEmployeeData(employees);
        setTotalPage(response.data?.totalPages);
        setCurrentPage(response.data?.currentPage);

        const dataTransform = employees?.map((employee) => ({
          employeeId: employee._id,
          name: employee.firstName,
        }));
        setEmployeeTransformData(dataTransform);
      }
    } catch (error) {
      console.error("Error fetching employees:", error);
      setError(error?.message || "Failed to fetch employees");
      toast.error("Failed to fetch employees. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, [searchQuery, isDelete, dataUpdate, currentPage, limit]);

  const handleTabChange = (value) => {
    navigate(`/employees/${value}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Early return if there's no companyId
  if (!getCompanyId()) {
    return null;
  }

  return (
    <div className="h-full md:p-6 p-2 pt-1 pb-7">
      <Tabs value={activeTab} onValueChange={handleTabChange} className="w-full">
        <ScrollableTabsList>
          <TabsList className="w-full flex justify-start h-auto p-1 bg-transparent">
            {PERSONAL_TAB.map((tab) => (
              <TabsTrigger
                key={tab.value}
                value={tab.value}
                className="py-2 px-4 text-white whitespace-nowrap data-[state=active]:bg-white data-[state=active]:text-blue-950"
              >
                {tab.name}
              </TabsTrigger>
            ))}
          </TabsList>
        </ScrollableTabsList>
        <div className="py-4">
          <TabsContent value="tutti-i-dipendenti">
            <AllEmployees
              setSearchQuery={setSearchQuery}
              employeeData={employeeData}
              setIsDelete={setIsDelete}
              isDelete={isDelete}
              currentPage={currentPage}
              setLimit={setLimit}
              totalPages={totalPages}
              limit={limit}
              handlePageChange={handlePageChange}
              setCurrentPage={setCurrentPage}
              setDataUpdate={setDataUpdate}
              dataUpdate={dataUpdate}
              isLoading={isLoading}
              error={error}
            />
          </TabsContent>
          <TabsContent value="foglio-presenze">
            <AttendanceSheet />
          </TabsContent>
          <TabsContent value="formazione">
            <Training employeeData={employeeTransformData} />
          </TabsContent>
          <TabsContent value="assenze">
            <Absence employeeData={employeeTransformData} />
          </TabsContent>
          <TabsContent value="retribuzione">
            <Salary companyId={getCompanyId()} />
          </TabsContent>
          <TabsContent value="pianificazione">
            <Planning employeeData={employeeTransformData} />
          </TabsContent>
        </div>
      </Tabs>
    </div>
  );
};

export default EmployeeComponent;