import React from "react";
import { Controller } from "react-hook-form";
import { FaUpload } from "react-icons/fa";
import { ADD_EMPLOYEE_INFO_FIELDS } from "../../../../../../constants/formFields";
import ImageController from "../../../../sharedComponent/ImageController";

const EmployeeInfo = ({ control, handleImageChange, imagePreview, image }) => {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="bg-white  rounded-lg p-8 grid grid-cols-2 gap-4 w-full">
        <div className="col-span-2 flex justify-center items-center">
          <ImageController
            handleImageChange={handleImageChange}
            label=""
            name="file"
            control={control}
            rounded={"rounded-full"}
            defaultValue={image ? `${process.env.REACT_APP_BACKEND_URL}/${image}` : ""}
          />
        </div>
        {ADD_EMPLOYEE_INFO_FIELDS?.map((data) => (

          <div key={data.name} className="col-span-2 sm:col-span-1">
            <label
              className="block text-gray-700 text-sm font-semibold"
              htmlFor={data.name}
            >
              {data.label}
            </label>
            {data.type === "select" ? (
              <Controller
                name={data.name}
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <select
                    id={data.name}
                    ref={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    className="appearance-none border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value="">---Select---</option>
                    {data.options?.map((option) => (
                      <option key={option.key} value={option.key}>
                        {option.value}
                      </option>
                    ))}
                  </select>
                )}
              />
            ) : (
              <Controller
                name={data.name}
                control={control}
                render={({ field }) => {
                  return (
                    <input
                      type={data.type}
                      id={data.name}
                      placeholder={data.placeholder}
                      {...field}
                      className="appearance-none border border-gray-200  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  );
                }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmployeeInfo;
