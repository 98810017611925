import React from 'react';
import { Controller } from 'react-hook-form';
import { ADD_EMPLOYEE_CONTACT_FIELDS } from "../../../../../../constants/formFields"

const Contact = ({ control }) => {
  return (
    <div className='h-full bg-gray-100 flex items-center justify-center'>
      <div className='bg-white  rounded-lg p-8 grid grid-cols-2 gap-4 w-full'>
        {ADD_EMPLOYEE_CONTACT_FIELDS.map((data) => (
          <div
            key={data.name}
            className={`col-span-1 ${data.type === 'time' ? 'sm:col-span-1' : ''}`}
          >
            <label className='block text-gray-700 text-sm font-semibold '>{data.label}</label>
            {data.type === 'select' ? (
              <Controller
                name={data.name}
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <select
                    id={data.name}
                    ref={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    className='border rounded border-gray-200 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                  >
                    <option value=''>---Select---</option>
                    {data.options.map((option) => (
                      <option key={option.key} value={option.key}>
                        {option.value}
                      </option>
                    ))}
                  </select>
                )}
              />
            ) : data.type === 'time' ? (
              <div className='flex justify-between space-x-3'>
                {data.times.map((time) => (
                  <Controller
                    key={`${data.name}${time.key}`}
                    name={`${data.name}${time.key}`}
                    control={control}
                    render={({ field }) => (
                      <input
                        type='time'
                        {...field}
                        className='appearance-none border  border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      />
                    )}
                  />
                ))}
              </div>
            ) : (
              <Controller
                name={data.name}
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    type={data.type}
                    placeholder={data.placeholder}
                    className='appearance-none border border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                  />
                )}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Contact;
