import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import Ddt_editComponent from '../../../components/admin/ddt/ddt_edit'

const Ddt_editDetails = () => {
  return (
    <Layout>
      <Ddt_editComponent />
    </Layout>
  )
}

export default Ddt_editDetails