import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ImageController from "../../../sharedComponent/ImageController";
import { AddTraining, updateTraining } from "../../../../../apis/training";
import { toast } from "react-toastify";

const AddCertificate = ({
  isOpen,
  onClose,
  setIsModalOpen,
  employeeData,
  selectedId,
  setIsUpdated,
  isUpdated,
  companyId,
  singleTraining,
}) => {
  const { register, handleSubmit, control, reset } = useForm({
    defaultValues: singleTraining,
  });

  useEffect(() => {
    if (singleTraining) {
      reset(singleTraining);
    } else {
      reset({
        attestedName: "",
        expiration: "",
        employee: "",
        image: null,
      });
    }
  }, [reset, singleTraining]);

  const handleDocument = async (data, e) => {
    e.preventDefault();
    try {
      const payload = {
        ...data,
        companyId
      };
      let response;

      if (selectedId) {
        response = await updateTraining(selectedId, payload);
      } else {
        response = await AddTraining(payload);
      }

      if (response.status === 201 || response.status === 200) {
        toast.success(response.data.message);
        setIsModalOpen(false);
        setIsUpdated(!isUpdated);
      }
      reset();
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while processing the request.");
    }
  };
  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <div
      id="authentication-modal"
      tabIndex="-1"
      aria-hidden={!isOpen}
      className={`${isOpen ? "flex" : "hidden"
        } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-black bg-opacity-50`}
    >
      <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 w-[35%]">
        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            {selectedId ? "Aggiornamento certificato" : "Aggiungi certificato"}
          </h3>
          <button
            type="button"
            className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            onClick={handleClose}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <div className="p-4 md:p-5">
          <form className="space-y-4" onSubmit={handleSubmit(handleDocument)}>
            <div className="flex justify-center">
              <ImageController label="" name="file" control={control} />
            </div>
            <div>
              <label
                htmlFor="Attested_name"
                className="block text-sm font-medium text-gray-900 dark:text-white"
              >
                Nome attestato
              </label>
              <input
                type="text"
                id="Attested_name"
                className="border border-gray-300 text-gray-900 text-sm rounded w-full p-2.5"
                {...register("attestedName")}
              />
            </div>
            <div>
              <label
                htmlFor="expiration_date"
                className="block text-sm font-medium text-gray-900 dark:text-white"
              >
                Data di scadenza
              </label>
              <input
                type="date"
                id="expiration_date"
                className="border border-gray-300 text-gray-900 text-sm rounded w-full p-2.5"
                {...register("expiration")}
                required
              />
            </div>
            <div>
              <label
                htmlFor="employee"
                className="block text-sm font-medium text-gray-900 dark:text-white"
              >
                Dipendente
              </label>
              <select
                id="employee"
                className="border border-gray-300 text-gray-900 text-sm rounded w-full p-2.5"
                {...register("employee")}
                required
              >
                <option value="">Select employee</option>
                {employeeData?.map((employee) => (
                  <option key={employee.employeeId} value={employee.employeeId}>
                    {employee.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-end items-end gap-3">
              <button
                type="button"
                onClick={handleClose}
                className="text-white bg-[#eb4343] hover:opacity-90 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-1.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Annulla
              </button>
              <button
                type="submit"
                className="text-white bg-[#06052C] hover:opacity-90 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-1.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {selectedId ? "Aggiornamento" : "Aggiungi"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCertificate;
