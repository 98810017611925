import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import Provider from "../../../components/admin/personalData/supplier"

const SupplierScreen = () => {
  return (
    <Layout><Provider /></Layout>
  )
}

export default SupplierScreen