import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import FotoComponent from '../../../components/admin/cantieri/foto'

const FotoDetails = () => {
  return (
    <Layout>
      <FotoComponent />
    </Layout>
  )
}

export default FotoDetails