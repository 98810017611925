import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import MovimentiComponent from '../../../components/admin/movimenti/index'

const MovimentiDetails = () => {
  return (
    <Layout>
      <MovimentiComponent />
    </Layout>
  )
}

export default MovimentiDetails