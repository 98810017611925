import React from 'react';
import loginImage from "../../assest/singup.jpg";
import Content from "./Content";
import { useForm } from "react-hook-form";
import { forgetPassword } from "../../apis/Auth";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import logo from "../../assest/logo1.png";
import { Button } from '../../components/ui/button';

const ForgetPassword = () => {
  const navigate = useNavigate();

  const contentData = {
    title: "Building with Passion, Creating with Precision",
    description: "Crafting inspirational spaces with precision, passion, and a commitment to excellence in construction.",
    image: loginImage,
  };

  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await forgetPassword(data)
      if (response.status === 200) {
        toast.success(response.data.message)
        navigate("/Forget-Password-Request")
      }
    } catch (error) {
      console.log("error", error)
      toast.error("An error occurred. Please try again.")
    }
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      <div className="md:hidden w-full bg-gradient-to-r from-blue-950 to-blue-800 p-4 flex justify-center">
        <img src={logo} className="w-[180px]" alt="Logo" />
      </div>
      <div className="hidden md:block md:w-[45%]">
        <Content contentData={contentData} />
      </div>
      <div className="flex-1 flex flex-col justify-center items-center p-4 md:p-8 bg-gradient-to-r from-blue-950 to-blue-800">
        <h1 className="mb-6 md:mb-10 text-white font-nunito text-xl md:text-2xl font-bold text-center px-4">
          Hai dimenticato la password? Inserisci la tua email per recuperarla.
        </h1>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4 w-full max-w-[350px] md:max-w-[400px]">
          <input
            className="h-12 p-4 rounded-full text-normal text-black w-full"
            type="email"
            placeholder="Email"
            {...register("email", { required: true })}
          />
          <button
            className="h-12 p-2 w-full rounded-full text-normal bg-green-500 hover:opacity-80 text-white"
            type="submit"
          >
            Recupera Password
          </button>
          <p className="text-center text-white mt-4">
            Ricordi la password?{" "}
            <a
              onClick={() => navigate("/login")}
              className="hover:opacity-75 font-semibold cursor-pointer"
            >
              Accedi
            </a>
          </p>
          <Link to="/" className="absolute top-1 left-4">
            <Button variant="link" className="flex items-center gap-2 text-white border-white hover:bg-white/10">
              Torna alla Home
            </Button>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default ForgetPassword;