import {createJsonTypeInstance}  from './index';
const axios =createJsonTypeInstance();


export const AddLeave = async (data) => {
    try {
      const response = await axios.post('/attendance/leave' , data);
      return response;
    } catch (error) {
      throw error.response.data;
    }
  };

  export const GetCompanyLeaves = async (companyId,searchQuery) => {
    try {
      const response = await axios.get(`/leave/company-leaves/${companyId}?search=${searchQuery}`);
      return response;
    } catch (error) {
      throw error.response.data;
    }
  };

  export const UpdateLeave = async (id ,data) => {
    try {
      const response = await axios.put(`/leave/${id}`, data);
      return response;
    } catch (error) {
      throw error.response.data;
    }
  };

  export const GetSingleLeaves = async (id) => {
    try {
      const response = await axios.get(`/leave/${id}`);
      return response;
    } catch (error) {
      throw error.response.data;
    }
  };

  export const deleteLeave = async (id) => {
    try {
      const response = await axios.delete(`/leave/${id}`);
      return response;
    } catch (error) {
      throw error.response.data;
    }
  };