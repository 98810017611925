import { Input } from "../../../../ui/input";
import { Label } from "../../../../ui/label";
import { Textarea } from "../../../../ui/textarea";

const Extra = ({ register }) => (
  <div className="space-y-4">
    <div className="space-y-2">
      <Label htmlFor="note">Note</Label>
      <Textarea
        {...register("note")}
        id="note"
        placeholder="Scrivi qui le tue osservazioni..."
        className="min-h-[100px]"
      />
    </div>
    <div className="space-y-2">
      <Label htmlFor="billingCode">Codice Univoco di Fatturazione</Label>
      <Input
        {...register("billingCode")}
        type="text"
        id="billingCode"
        placeholder="Inserisci il codice univoco"
      />
    </div>
  </div>
);

export default Extra;