const MainData = ({ register }) => (
  <div className="flex flex-col gap-3">
    <div className="w-1/2">
      <label htmlFor="typology" className="block mb-1 text-sm font-medium text-gray-900">Typology</label>
      <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
        <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
          <div className="flex items-center ps-3">
            <input
              id="typology-private"
              type="radio"
              value="Private"
              {...register("typology")}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
            />
            <label htmlFor="typology-private" className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Private</label>
          </div>
        </li>
        <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
          <div className="flex items-center ps-3">
            <input
              id="typology-agency"
              type="radio"
              value="Agency"
              {...register("typology")}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
            />
            <label htmlFor="typology-agency" className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Agency</label>
          </div>
        </li>
      </ul>
    </div>

    <div className="flex flex-wrap gap-3">
      <div className="flex-1">
        <label htmlFor="fullName" className="block mb-1 text-sm font-medium text-gray-900">Full name</label>
        <input
          {...register("fullName")}
          type="text"
          id="fullName"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          placeholder="John"

        />
      </div>

      <div className="flex-1">
        <label htmlFor="companyName" className="block mb-1 text-sm font-medium text-gray-900">Company name</label>
        <input
          {...register("companyName")}
          type="text"
          id="companyName"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          placeholder="Company"

        />
      </div>
    </div>

    <div className="flex flex-wrap gap-3">
      <div className="flex-1">
        <label htmlFor="vatNumber" className="block mb-1 text-sm font-medium text-gray-900">VAT number</label>
        <input
          {...register("vatNumber")}
          type="text"
          id="vatNumber"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          placeholder="VAT Number"

        />
      </div>

      <div className="flex-1">
        <label htmlFor="taxId" className="block mb-1 text-sm font-medium text-gray-900">Tax ID code</label>
        <input
          {...register("taxId")}
          type="text"
          id="taxId"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          placeholder="Tax ID"

        />
      </div>
    </div>
  </div>
);

export default MainData;
