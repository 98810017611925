import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateDob } from '../../../../apis/ProfilioEdit';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../../ui/dialog";
import { Button } from "../../../ui/button";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";

function EditDobModal({ isOpen, onClose, id, currentDob, onSuccess, description }) {
  const [dob, setDob] = useState(currentDob || '');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
      setDob(currentDob || '');
  }, [currentDob]);

  const handleSave = async () => {
      if (!id) {
          setError('User ID not found');
          return;
      }

      if (!dob) {
          setError('Data di nascita è richiesta');
          return;
      }

      setLoading(true);
      setError(null);
      try {
          const response = await updateDob(id, { dob });

          if (response.status === 200) {
              toast.success("Data di nascita aggiornata con successo");
              onSuccess(dob);
              onClose();
          }
      } catch (error) {
          console.error('Update error:', error);
          setError(error.response?.data?.error || "Errore nell'aggiornamento della data di nascita");
          toast.error("Errore nell'aggiornamento della data di nascita");
      } finally {
          setLoading(false);
      }
  };


  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Modifica Data di Nascita</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-1 items-center gap-4">
            <Label htmlFor="dob" className="text-left">
              Nuova Data di Nascita
            </Label>
            <Input
              id="dob"
              type="date"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
              className="col-span-3"
            />
          </div>
          {error && (
            <p className="text-red-500 text-sm">{error}</p>
          )}
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Annulla
          </Button>
          <Button onClick={handleSave} disabled={loading}>
            {loading ? "Salvataggio..." : "Salva"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default EditDobModal;