import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Edit, Trash2, Download, Upload } from "lucide-react";
import { fetchSuppliers, deleteSupplier, importSupplierList, addSupplier, getSingleSupplier, updateSupplier } from "../../../../apis/supplier";
import { exportToExcel, exportToPDF } from "../../../../config/helper";

import { Button } from "../../../ui/button";
import { ScrollArea } from "../../../ui/scroll-area";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select";

import SearchBar from "../../sharedComponent/SearchBar";
import ImportFile from "../../sharedComponent/ImportFile";
import Pagination from "../../sharedComponent/Pgination";
import HelpSheet from "../../sharedComponent/HelpSheet";

const AddSupplierForm = ({ register }) => {
  return (
    <div className="space-y-6 p-2">
      {/* Main Data */}
      <div className="space-y-4">
        <h3 className="font-semibold">Dati Principali</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label htmlFor="businessName">Ragione Sociale</Label>
            <Input
              {...register("businessName")}
              id="businessName"
              placeholder="Inserisci la ragione sociale"
              required
            />
          </div>
          <div>
            <Label htmlFor="internalCode">Codice Interno</Label>
            <Input
              {...register("internalCode")}
              id="internalCode"
              placeholder="Inserisci il codice interno"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label htmlFor="vatNumber">Partita IVA</Label>
            <Input
              {...register("vatNumber")}
              id="vatNumber"
              placeholder="Inserisci la partita IVA"
            />
          </div>
          <div>
            <Label htmlFor="taxId">Codice Fiscale</Label>
            <Input
              {...register("taxId")}
              id="taxId"
              placeholder="Inserisci il codice fiscale"
            />
          </div>
        </div>
      </div>

      {/* Position */}
      <div className="space-y-4">
        <h3 className="font-semibold">Posizione</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label htmlFor="address">Indirizzo</Label>
            <Input
              {...register("address")}
              id="address"
              placeholder="Inserisci l'indirizzo"
            />
          </div>
          <div>
            <Label htmlFor="city">Città</Label>
            <Input
              {...register("city")}
              id="city"
              placeholder="Inserisci la città"
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <Label htmlFor="postalCode">CAP</Label>
            <Input
              {...register("postalCode")}
              id="postalCode"
              placeholder="Inserisci il CAP"
            />
          </div>
          <div>
            <Label htmlFor="province">Provincia</Label>
            <Input
              {...register("province")}
              id="province"
              placeholder="Inserisci la provincia"
            />
          </div>
          <div>
            <Label htmlFor="country">Nazione</Label>
            <Input
              {...register("country")}
              id="country"
              placeholder="Inserisci la nazione"
            />
          </div>
        </div>
      </div>

      {/* Contacts */}
      <div className="space-y-4">
        <h3 className="font-semibold">Contatti</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label htmlFor="email">Email</Label>
            <Input
              {...register("email")}
              type="email"
              id="email"
              placeholder="Inserisci l'email"
            />
          </div>
          <div>
            <Label htmlFor="pec">PEC</Label>
            <Input
              {...register("pec")}
              type="email"
              id="pec"
              placeholder="Inserisci la PEC"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label htmlFor="telephone">Telefono</Label>
            <Input
              {...register("telephone")}
              id="telephone"
              placeholder="Inserisci il telefono"
            />
          </div>
          <div>
            <Label htmlFor="website">Sito Web</Label>
            <Input
              {...register("website")}
              id="website"
              placeholder="Inserisci il sito web"
            />
          </div>
        </div>
      </div>

      {/* Extra */}
      <div className="space-y-4">
        <h3 className="font-semibold">Extra</h3>
        <div>
          <Label htmlFor="notes">Note</Label>
          <Input
            {...register("notes")}
            id="notes"
            placeholder="Inserisci eventuali note"
          />
        </div>
      </div>
    </div>
  );
};

const Provider = () => {
  const navigate = useNavigate();
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [dataUpdate, setDataUpdate] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user.companyId;
  const [suppliersList, setSuppliersList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingSupplier, setEditingSupplier] = useState(null);

  const { register, handleSubmit, reset } = useForm();

  const getALLSuppliers = async (page = currentPage, pageLimit = limit) => {
    try {
      const response = await fetchSuppliers(companyId, page, pageLimit, searchQuery);
      if (response.status === 200) {
        setSuppliersList(response.data?.suppliers || []);
        setTotalPages(response.data?.totalPages || 1);
      }
    } catch (error) {
      console.error("Errore nel recupero dei fornitori:", error);
      toast.error("Impossibile recuperare l'elenco dei fornitori");
    }
  };

  useEffect(() => {
    getALLSuppliers();
  }, [dataUpdate, currentPage, limit, searchQuery]);

  const handleDeleteSupplier = async () => {
    try {
      const response = await deleteSupplier(selectedId);
      if (response.status) {
        toast.success(response.data.message);
        setIsDeleteModalOpen(false);
        setDataUpdate(!dataUpdate);
      }
    } catch (error) {
      console.error("Errore nell'eliminazione del fornitore:", error);
      toast.error("Impossibile eliminare il fornitore");
    }
  };

  const handleExport = (value) => {
    const columns = [
      { header: "Id", key: "id" },
      { header: "Nome/Azienda", key: "companyName" },
      { header: "Indirizzo", key: "address" },
      { header: "Email", key: "email" },
      { header: "Telefono", key: "telephone" },
    ];

    if (value === "excel") {
      exportToExcel(suppliersList, "fornitori");
    } else if (value === "pdf") {
      exportToPDF(suppliersList, columns, "Elenco Fornitori");
    }
  };

  const handleSearch = async (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const importSupplier = async (fileData) => {
    let payload = {
      companyId: companyId,
      supplier: fileData,
    };
    try {
      const response = await importSupplierList(payload);
      if (response.status === 201) {
        toast.success("Fornitori importati con successo!");
        setDataUpdate(!dataUpdate);
        setIsImportModalOpen(false);
      }
    } catch (error) {
      console.error("Errore nell'importazione dei fornitori:", error);
      toast.error("Impossibile importare i fornitori");
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleEditClick = async (supplierId) => {
    try {
      const response = await getSingleSupplier(supplierId);
      if (response.status === 200) {
        setEditingSupplier(response.data.supplier);
        reset(response.data.supplier); // Pre-fill form with supplier data
        setIsEditModalOpen(true);
      }
    } catch (error) {
      console.error("Error fetching supplier details:", error);
      toast.error("Impossibile recuperare i dati del fornitore");
    }
  };

  const onSubmit = async (data) => {
    try {
      const payload = {
        ...data,
        companyId: companyId,
      };

      let response;
      if (editingSupplier) {
        response = await updateSupplier(editingSupplier._id, payload);
        if (response.status === 200) {
          toast.success("Fornitore aggiornato con successo!");
          setIsEditModalOpen(false);
        }
      } else {
        response = await addSupplier(payload);
        if (response.status === 201) {
          toast.success("Fornitore aggiunto con successo!");
          setIsAddModalOpen(false);
        }
      }

      setDataUpdate(!dataUpdate);
      reset();
      setEditingSupplier(null);
    } catch (error) {
      console.error(error);
      toast.error("Errore nel salvataggio dei dati");
    }
  };

  return (
    <div className="p-6 space-y-6">
      <div className="flex flex-col space-y-4 sm:flex-row sm:justify-between sm:items-center">
        <div className="flex flex-wrap items-center gap-2">
          <Button onClick={() => setIsAddModalOpen(true)} className="w-full sm:w-auto">
            Aggiungi
          </Button>
          <Select onValueChange={handleExport}>
            <SelectTrigger className="w-full sm:w-[180px]">
              <SelectValue placeholder="Esporta" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="excel">Esporta in Excel</SelectItem>
              <SelectItem value="pdf">Esporta in PDF</SelectItem>
            </SelectContent>
          </Select>
          <Button onClick={() => setIsImportModalOpen(true)} variant="secondary" className="w-full sm:w-auto">
            Importa
          </Button>
        </div>
        <div className="md:w-1/6 flex justify-center items-center gap-x-2">
          <SearchBar onSearch={handleSearch} />
          <HelpSheet route="/suppliers" />
        </div>
      </div>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-center">Id</TableHead>
              <TableHead className="text-center">Nome/Azienda</TableHead>
              <TableHead className="text-center">Indirizzo</TableHead>
              <TableHead className="text-center">Email</TableHead>
              <TableHead className="text-center">Telefono</TableHead>
              <TableHead className="text-center">Azioni</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {suppliersList.length > 0 ? (
              suppliersList.map((data, index) => (
                <TableRow key={index}>
                  <TableCell className="text-center">{index + 1}</TableCell>
                  <TableCell className="text-center">{data.businessName}</TableCell>
                  <TableCell className="text-center">{data.address}</TableCell>
                  <TableCell className="text-center">{data.email}</TableCell>
                  <TableCell className="text-center">{data.telephone}</TableCell>
                  <TableCell className="text-center">
                    <div className="flex justify-center space-x-2">
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => handleEditClick(data._id)}
                      >
                        <Edit className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => {
                          setSelectedId(data._id);
                          setIsDeleteModalOpen(true);
                        }}
                      >
                        <Trash2 className='h-4 w-4 text-red-600' />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} className="text-center h-24">
                  Nessun dato disponibile
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {suppliersList.length > 0 && (
        <div className="flex justify-end mt-4">
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            limit={limit}
            setLimit={setLimit}
          />
        </div>
      )}

      <Dialog open={isAddModalOpen} onOpenChange={setIsAddModalOpen}>
        <DialogContent className="sm:max-w-4xl max-h-[95vh]"> {/* Added max-h-[95vh] */}
          <DialogHeader>
            <DialogTitle>Aggiungi Nuovo Fornitore</DialogTitle>
            <DialogDescription>
              Inserisci i dettagli del nuovo fornitore qui sotto.
            </DialogDescription>
          </DialogHeader>
          <form onSubmit={handleSubmit(onSubmit)} >
            <ScrollArea className="h-[calc(85vh-200px)] pr-4"> {/* Fixed height calculation and added right padding */}
              <AddSupplierForm register={register} />
            </ScrollArea>
            <DialogFooter className="mt-6 border-t pt-4"> {/* Added border and padding */}
              <Button type="button" variant="outline" onClick={() => setIsAddModalOpen(false)}>
                Annulla
              </Button>
              <Button type="submit">Aggiungi</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={isDeleteModalOpen} onOpenChange={setIsDeleteModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Elimina Fornitore</DialogTitle>
            <DialogDescription>
              Sei sicuro di voler eliminare questo fornitore? Questa azione è irreversibile.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsDeleteModalOpen(false)}>
              Annulla
            </Button>
            <Button variant="destructive" onClick={handleDeleteSupplier}>
              Elimina
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={isEditModalOpen} onOpenChange={setIsEditModalOpen}>
        <DialogContent className="sm:max-w-4xl max-h-[95vh]">
          <DialogHeader>
            <DialogTitle>Modifica Fornitore</DialogTitle>
            <DialogDescription>
              Modifica i dettagli del fornitore qui sotto.
            </DialogDescription>
          </DialogHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ScrollArea className="h-[calc(85vh-200px)] pr-4">
              <AddSupplierForm register={register} />
            </ScrollArea>
            <DialogFooter className="mt-6 border-t pt-4">
              <Button
                type="button"
                variant="outline"
                onClick={() => {
                  setIsEditModalOpen(false);
                  setEditingSupplier(null);
                  reset();
                }}
              >
                Annulla
              </Button>
              <Button type="submit">Aggiorna</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <ImportFile
        isOpen={isImportModalOpen}
        onClose={() => setIsImportModalOpen(false)}
        setIsModalOpen={setIsImportModalOpen}
        setDataUpdate={setDataUpdate}
        dataUpdate={dataUpdate}
        importFunction={importSupplier}
        vendorType="Fornitori"
      />
    </div>
  );
};

export default Provider;