import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import ImpostazioniComponent from '../../../components/admin/impostazioni/Impostazioni'

const ImpostazioniDetails = () => {
  return (
    <Layout>
      <ImpostazioniComponent />
    </Layout>
  )
}

export default ImpostazioniDetails