import React, { useState, useEffect } from 'react';
import { createTicket, updateTicket } from '../../../apis/TicketApi';
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";
import { Textarea } from "../../ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/select";
import { Label } from "../../ui/label";
import { AlertCircle } from "lucide-react";
import { Alert, AlertDescription } from "../../ui/alert";
import { Loader2 } from "lucide-react";

const TicketForm = ({ onClose, onSuccess, ticket, isSupport = false }) => {
  const [formData, setFormData] = useState({
    subject: '',
    description: '',
    status: 'Aperto',
    priority: 'Medium',
    category: ''
  });
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const categories = ['Bug', 'Feature', 'Support', 'Other'];

  useEffect(() => {
    if (ticket) {
      setFormData(ticket);
    }
  }, [ticket]);

  const handleChange = (name, value) => {
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    try {
      let response;
      if (ticket) {
        // When updating, set the appropriate status based on the actor and action
        let updatedStatus = formData.status;
        if (!isSupport && updatedStatus === 'Aperto') {
          updatedStatus = 'Chiuso';
        }
        
        response = await updateTicket(ticket._id, {
          ...formData,
          status: updatedStatus
        });
      } else {
        // New tickets are always created with 'Aperto' status
        response = await createTicket({
          ...formData,
          status: 'Aperto'
        });
      }
      onSuccess(response);
    } catch (error) {
      console.error('Errore durante l\'invio del ticket:', error);
      setError('Si è verificato un errore durante l\'invio del ticket. Riprova più tardi.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {error && (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      
      <div className="space-y-2">
        <Label htmlFor="subject">Oggetto</Label>
        <Input
          id="subject"
          name="subject"
          value={formData.subject}
          onChange={(e) => handleChange('subject', e.target.value)}
          placeholder="Inserisci l'oggetto del ticket"
          required
          disabled={isSubmitting}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="description">Descrizione</Label>
        <Textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={(e) => handleChange('description', e.target.value)}
          placeholder="Descrivi il problema o la richiesta"
          required
          disabled={isSubmitting}
        />
      </div>

      {/* Status field is only shown for support staff */}
      {isSupport && (
        <div className="space-y-2">
          <Label htmlFor="status">Stato</Label>
          <Select
            name="status"
            value={formData.status}
            onValueChange={(value) => handleChange('status', value)}
            disabled={isSubmitting}
          >
            <SelectTrigger>
              <SelectValue placeholder="Seleziona lo stato" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Aperto">Aperto</SelectItem>
              <SelectItem value="In Corso">In Corso</SelectItem>
              <SelectItem value="Chiuso">Chiuso</SelectItem>
            </SelectContent>
          </Select>
        </div>
      )}

      <div className="space-y-2">
        <Label htmlFor="priority">Priorità</Label>
        <Select
          name="priority"
          value={formData.priority}
          onValueChange={(value) => handleChange('priority', value)}
          disabled={isSubmitting || (!isSupport && ticket)}
        >
          <SelectTrigger>
            <SelectValue placeholder="Seleziona la priorità" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="Low">Bassa</SelectItem>
            <SelectItem value="Medium">Media</SelectItem>
            <SelectItem value="High">Alta</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="space-y-2">
        <Label htmlFor="category">Categoria</Label>
        <Select
          name="category"
          value={formData.category}
          onValueChange={(value) => handleChange('category', value)}
          disabled={isSubmitting || (!isSupport && ticket)}
        >
          <SelectTrigger>
            <SelectValue placeholder="Seleziona la categoria" />
          </SelectTrigger>
          <SelectContent>
            {categories.map((category) => (
              <SelectItem key={category} value={category}>{category}</SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="flex justify-end space-x-2 pt-4">
        <Button type="button" variant="outline" onClick={onClose} disabled={isSubmitting}>
          Annulla
        </Button>
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Invio in corso...
            </>
          ) : (
            ticket ? (
              !isSupport && ticket.status === 'Aperto' ? 'Chiudi Ticket' : 'Aggiorna Ticket'
            ) : 'Crea Ticket'
          )}
        </Button>
      </div>
    </form>
  );
};

export default TicketForm;