import { createJsonTypeInstance } from './index';
const axios = createJsonTypeInstance();

export const fetchDdt = async (companyId, currentPage, limit) => {
  try {
    const response = await axios.get(`/ddt/${companyId}?page=${currentPage}&limit=${limit}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const deleteDdt = async (id) => {
  try {
    const response = await axios.delete(`ddt/${id}`);
    console.log('Deleted Preventivi frontend:', response);
    return response;
    } catch (error) {
    throw error.response;
  }
};
