import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { fetchClients, deleteClient, importClientList, addClient, updateClient, getSingleClient } from "../../../../apis/client";
import { exportToExcel, exportToPDF } from "../../../../config/helper";
import { Edit, Trash2, Filter } from "lucide-react";
import { useForm } from "react-hook-form";
import { Button } from "../../../ui/button";
import { Input } from "../../../ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select";
import { Card, CardContent } from "../../../ui/card";
import { Label } from "../../../ui/label";
import { Textarea } from "../../../ui/textarea";
import { ScrollArea } from "../../../ui/scroll-area";

import SearchBar from "../../../../components/admin/sharedComponent/SearchBar";
import ImportFile from "../../sharedComponent/ImportFile";
import Pagination from "../../sharedComponent/Pgination";
import FilterModal from "./FilterModal";
import HelpSheet from "../../sharedComponent/HelpSheet";

const deleteContent = {
  title: "Elimina Cliente",
  description: "Sei sicuro di voler eliminare questo cliente? Questa azione è irreversibile.",
};

const Client = ({ employeeData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [dataUpdate, setDataUpdate] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user.companyId;
  const [clientsList, setClientsList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const { register, handleSubmit, reset, setValue } = useForm();

  const GetALLClients = async () => {
    const filterObj = {
      typology: filterValue
    };
    const filterString = JSON.stringify(filterObj);
    try {
      const response = await fetchClients(
        companyId,
        currentPage,
        limit,
        searchQuery,
        filterString
      );
      if (response.status === 200) {
        setClientsList(response.data?.clients);
        setTotalPage(response.data?.totalPages);
        setCurrentPage(response.data?.currentPage);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    GetALLClients();
  }, [dataUpdate, currentPage, limit, searchQuery, filterValue]);

  const handleDeleteModal = (id) => {
    setSelectedId(id);
    setDeleteModal(true);
  };

  const handleDeleteClient = async () => {
    try {
      const response = await deleteClient(selectedId);
      if (response.status) {
        toast.success(response.data.message);
        setDeleteModal(false);
        setDataUpdate(!dataUpdate);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleExport = (value) => {
    const columns = [
      { header: "Id", key: "id" },
      { header: "Nome/Regione", key: "fullName" },
      { header: "Indirizzo", key: "address" },
      { header: "Email", key: "email" },
      { header: "Telefono", key: "telephone" },
    ];

    if (value === "excel") {
      exportToExcel(clientsList, "clienti");
    } else if (value === "pdf") {
      exportToPDF(clientsList, columns, "Elenco Clienti");
    }
  };

  const handleSearch = async (query) => {
    setSearchQuery(query);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const importClient = async (fileData) => {
    let payload = {
      companyId: companyId,
      clients: fileData,
    };
    const response = await importClientList(payload);
    if (response.status === 201) {
      toast.success("Clienti importati con successo!");
      setDataUpdate(!dataUpdate);
      setIsModalOpen(false);
    }
  };

  const handleEditClient = async (id) => {
    setSelectedId(id);
    setIsEditing(true);
    try {
      const response = await getSingleClient(id);
      if (response.status === 200) {
        const clientData = response.data.client;
        Object.keys(clientData).forEach((key) => {
          setValue(key, clientData[key]);
        });
        setIsAddClientModalOpen(true);
      }
    } catch (error) {
      console.error(error);
      toast.error("Impossibile recuperare i dati del cliente");
    }
  };

  const onSubmit = async (data) => {
    try {
      const payload = {
        ...data,
        companyId: companyId,
      };
      let response;
      if (isEditing) {
        response = await updateClient(selectedId, payload);
      } else {
        response = await addClient(payload);
      }
      if (response.status === 200 || response.status === 201) {
        toast.success(response.data.message);
        setIsAddClientModalOpen(false);
        setDataUpdate(!dataUpdate);
        reset();
        setIsEditing(false);
      }
    } catch (error) {
      console.error(error);
      toast.error(`Impossibile ${isEditing ? 'aggiornare' : 'aggiungere'} il cliente`);
    }
  };

  return (
    <Card className="shadow-none border-0">
      <CardContent>
        <div className="sm:flex justify-between items-center space-y-4 sm:space-y-0 my-7">
          <div className="flex flex-wrap items-center gap-2">
            <Button onClick={() => setIsAddClientModalOpen(true)} className="flex-grow sm:flex-grow-0">
              Aggiungi
            </Button>
            <Button onClick={() => setIsModalOpen(true)} variant="secondary" className="flex-grow sm:flex-grow-0">
              Importa
            </Button>
            <Select onValueChange={handleExport}>
              <SelectTrigger className="w-full sm:w-[180px]">
                <SelectValue placeholder="Esporta" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="excel">Esporta in Excel</SelectItem>
                <SelectItem value="pdf">Esporta in PDF</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="flex flex-wrap items-center gap-2 mt-4 sm:mt-0">
            <SearchBar onSearch={handleSearch} className="w-full sm:w-auto sm:flex-grow" />
            {/* <Button variant="outline" onClick={() => setIsFilterModalOpen(true)} className="w-full sm:w-auto flex items-center">
              <Filter className="h-4 w-4 mr-2" /> Filtri
            </Button> */}
            <HelpSheet route="/clients" />
          </div>
        </div>

        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="text-center rounded-l-lg">Id</TableHead>
                <TableHead className="text-center">Ragione sociale</TableHead>
                <TableHead className="text-center">Indirizzo</TableHead>
                <TableHead className="text-center">Email</TableHead>
                <TableHead className="text-center">Telefono</TableHead>
                <TableHead className="text-center rounded-r-lg">Azioni</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {clientsList.length > 0 ? (
                clientsList.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell className="text-center">{index + 1}</TableCell>
                    <TableCell className="text-center">{data.fullName}</TableCell>
                    <TableCell className="text-center">{data.address}</TableCell>
                    <TableCell className="text-center">{data.email}</TableCell>
                    <TableCell className="text-center">{data.telephone}</TableCell>
                    <TableCell className="text-center border-b">
                      <div className="flex justify-center gap-2">
                        <Button variant="ghost" size="sm" onClick={() => handleEditClient(data._id)}>
                          <Edit className="h-4 w-4" />
                        </Button>
                        <Button variant="ghost" size="sm" onClick={() => handleDeleteModal(data._id)}>
                          <Trash2 className='h-4 w-4 text-red-600' />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} className="text-center py-8">
                    Nessun dato
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        {clientsList.length > 0 && (
          <div className="mt-4 flex justify-end">
            <Pagination
              totalPages={totalPages}
              onPageChange={handlePageChange}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              limit={limit}
              setLimit={setLimit}
            />
          </div>
        )}
      </CardContent>

      <Dialog open={deleteModal} onOpenChange={setDeleteModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{deleteContent.title}</DialogTitle>
            <DialogDescription>{deleteContent.description}</DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setDeleteModal(false)}>Annulla</Button>
            <Button variant="destructive" onClick={handleDeleteClient}>Elimina</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <ImportFile
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        setIsModalOpen={setIsModalOpen}
        setDataUpdate={setDataUpdate}
        dataUpdate={dataUpdate}
        importFunction={importClient}
        vendorType="Clienti"
      />

      {/* <FilterModal
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        setFilterValue={setFilterValue}
        filterValue={filterValue}
      /> */}

      <Dialog open={isAddClientModalOpen} onOpenChange={(open) => {
        setIsAddClientModalOpen(open);
        if (!open) {
          setIsEditing(false);
          reset();
        }
      }}>
        <DialogContent className="max-w-4xl max-h-[90vh]">
          <DialogHeader>
            <DialogTitle>{isEditing ? 'Modifica Cliente' : 'Aggiungi Nuovo Cliente'}</DialogTitle>
            <DialogDescription>
              {isEditing ? 'Modifica i dettagli del cliente.' : 'Inserisci i dettagli per aggiungere un nuovo cliente.'}
            </DialogDescription>
          </DialogHeader>
          <ScrollArea className="max-h-[calc(90vh-200px)] overflow-y-auto">
            <form onSubmit={handleSubmit(onSubmit)} className="px-1">
              <div className="grid gap-4 py-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label htmlFor="fullName">Ragione Sociale</Label>
                    <Input id="fullName" {...register("fullName")} placeholder="Inserisci la ragione sociale" />
                  </div>
                  <div>
                    <Label htmlFor="vatNumber">Partita IVA</Label>
                    <Input id="vatNumber" {...register("vatNumber")} placeholder="IT12345678901" />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label htmlFor="taxId">Codice Fiscale</Label>
                    <Input id="taxId" {...register("taxId")} placeholder="RSSMRA80A01H501U" />
                  </div>
                  <div>
                    <Label htmlFor="billingCode">Codice Univoco di Fatturazione</Label>
                    <Input id="billingCode" {...register("billingCode")} placeholder="ABCDEF1" />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label htmlFor="address">Indirizzo</Label>
                    <Input id="address" {...register("address")} placeholder="Via Roma, 1" />
                  </div>
                  <div>
                    <Label htmlFor="city">Città</Label>
                    <Input id="city" {...register("city")} placeholder="Roma" />
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <Label htmlFor="postalCode">CAP</Label>
                    <Input id="postalCode" {...register("postalCode")} placeholder="00100" />
                  </div>
                  <div>
                    <Label htmlFor="province">Provincia</Label>
                    <Input id="province" {...register("province")} placeholder="RM" />
                  </div>
                  <div>
                    <Label htmlFor="nation">Nazione</Label>
                    <Input id="nation" {...register("nation")} placeholder="Italia" />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label htmlFor="email">Email</Label>
                    <Input id="email" type="email" {...register("email")} placeholder="info@esempio.com" />
                  </div>
                  <div>
                    <Label htmlFor="pec">PEC</Label>
                    <Input id="pec" type="email" {...register("pec")} placeholder="pec@esempio.it" />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label htmlFor="telephone">Telefono</Label>
                    <Input id="telephone" {...register("telephone")} placeholder="+39 06 1234567" />
                  </div>
                  <div>
                    <Label htmlFor="website">Sito Web</Label>
                    <Input id="website" {...register("website")} placeholder="https://www.esempio.it" />
                  </div>
                </div>
                <div>
                  <Label htmlFor="note">Note</Label>
                  <Textarea id="note" {...register("note")} placeholder="Note aggiuntive..." />
                </div>
              </div>
            </form>
          </ScrollArea>
          <DialogFooter>
            <Button type="button" variant="outline" onClick={() => setIsAddClientModalOpen(false)}>
              Annulla
            </Button>
            <Button type="submit" onClick={handleSubmit(onSubmit)}>
              {isEditing ? 'Aggiorna' : 'Aggiungi'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default Client;