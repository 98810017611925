import { useEffect } from 'react';

export const useIubenda = () => {
  useEffect(() => {
    const loadIubendaScript = async () => {
      // Check if script is already loaded
      if (document.querySelector('script[src*="cdn.iubenda.com/iubenda.js"]')) {
        return;
      }

      try {
        const script = document.createElement('script');
        script.src = 'https://cdn.iubenda.com/iubenda.js';
        script.async = true;
        
        await new Promise((resolve, reject) => {
          script.onload = resolve;
          script.onerror = reject;
          document.body.appendChild(script);
        });
      } catch (error) {
        console.warn('Iubenda policy script loading failed:', error);
      }
    };

    loadIubendaScript();
  }, []);
};