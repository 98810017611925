import React, { useEffect, useState } from 'react';
import { Edit, Trash2, Filter, ArrowUp, ArrowDown } from "lucide-react";
import { fetchMovimenti, addMovimenti, updateMovimenti, sumEntra, sumEntraPrevious, sumUscita, sumUscitaPrevious, sumAll, sumAllPrevious, importMovimenti, deleteMovimenti } from '../../../apis/Movimenti';
import { toast } from 'react-toastify';
import { exportToExcel, exportToPDF } from "../../../config/helper";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../ui/tooltip";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { Card, CardContent } from "../../ui/card";

import SearchBar from '../sharedComponent/SearchBar';
import Pagination from "../../admin/sharedComponent/Pgination";
import HelpSheet from '../sharedComponent/HelpSheet';

function TableComponent() {
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user?.companyId;
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalEntraImporto, setTotalEntraImporto] = useState(0);
  const [totalEntraPreviousImporto, setTotalEntraPreviousImporto] = useState(0);
  const [totalUscitaImporto, setTotalUscitaImporto] = useState(0);
  const [totalUscitaPreviousImporto, setTotalUscitaPreviousImporto] = useState(0);
  const [totalAllImporto, setTotalAllImporto] = useState(0);
  const [totalAllPreviousImporto, setTotalAllPreviousImporto] = useState(0);
  const [formData, setFormData] = useState({
    entraUscita: '',
    movdata: '',
    nome: '',
    importo: '',
    riferimento: '',
    companyId: companyId,
    idAzienda: '2424'
  });
  const [formDataEdit, setFormDataEdit] = useState({
    _id: '',
    entraUscita: '',
    movdata: '',
    nome: '',
    importo: '',
    riferimento: '',
    idAzienda: '2424',
  });
  const [showFiltersDialog, setShowFiltersDialog] = useState(false);
  const [filters, setFilters] = useState({
    dateFrom: '',
    dateTo: '',
    status: ''
  });

  const [importFile, setImportFile] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleFileChange = (e) => {
    setImportFile(e.target.files[0]);
  };

  const handleImport = async () => {
    if (!importFile) {
      toast.error("Please select a file to import");
      return;
    }

    const formData = new FormData();
    formData.append('file', importFile);
    formData.append('companyId', companyId);

    try {
      const response = await importMovimenti(formData);
      toast.success(response.data.message);
      fetchData(); // Refresh the data after import
    } catch (error) {
      console.error("Error importing data:", error);
      toast.error("Error importing data");
    }
  };

  const handleExport = async (value) => {
    try {
      const formattedData = filteredData.map((item, index) => ({
        nr: (index + 1).toString(),
        data: item.movdata ? new Date(item.movdata).toLocaleDateString('it-IT', { 
          day: '2-digit', 
          month: '2-digit', 
          year: 'numeric' 
        }).replace(/\//g, '-') : '',
        nome: item.nome || '',
        importo: item.importo ? `€ ${parseFloat(item.importo).toLocaleString('it-IT', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}` : '€ 0,00',
        riferimento: item.riferimento || '',
        stato: item.entraUscita || ''
      }));
  
      const columns = [
        { header: "Nr", key: "nr", width: 0.5 },
        { header: "Data", key: "data", width: 1 },
        { header: "Nome", key: "nome", width: 2 },
        { header: "Importo", key: "importo", width: 1 },
        { header: "Riferimento", key: "riferimento", width: 2 },
        { header: "Stato", key: "stato", width: 1 }
      ];
  
      // Calculate totals
      const totals = filteredData.reduce((acc, item) => {
        const amount = parseFloat(item.importo) || 0;
        if (item.entraUscita === 'Entrata') {
          acc.entrate += amount;
        } else if (item.entraUscita === 'Uscita') {
          acc.uscite += amount;
        }
        return acc;
      }, { entrate: 0, uscite: 0 });
  
      const stats = [
        { label: "Totale Movimenti", value: filteredData.length },
        { label: "Periodo", value: filters.dateFrom && filters.dateTo ? 
          `Dal ${new Date(filters.dateFrom).toLocaleDateString('it-IT').replace(/\//g, '-')} al ${new Date(filters.dateTo).toLocaleDateString('it-IT').replace(/\//g, '-')}` : 
          new Date().toLocaleDateString('it-IT').replace(/\//g, '-')
        }
      ];
  
      const summaries = [
        {
          label: "Totale Entrate",
          value: `€ ${totals.entrate.toLocaleString('it-IT', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
        },
        {
          label: "Totale Uscite",
          value: `€ ${totals.uscite.toLocaleString('it-IT', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
        },
        {
          label: "Saldo",
          value: `€ ${(totals.entrate - totals.uscite).toLocaleString('it-IT', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
        }
      ];
  
      if (value === "excel") {
        exportToExcel(formattedData, "Movimenti");
      } else if (value === "pdf") {
        await exportToPDF(
          formattedData,
          columns,
          "Lista Movimenti",
          stats,
          undefined,
          summaries
        );
      }
  
      toast.success(`Esportazione ${value.toUpperCase()} completata con successo`);
    } catch (error) {
      console.error('Errore durante l\'esportazione:', error);
      toast.error(`Errore durante l'esportazione ${value.toUpperCase()}`);
    }
  };
  function handleAddClick() {
    setShowModal(true);
  }

  const handleEditClick = (item) => {
    setShowModalEdit(true);
    setFormDataEdit({
      _id: item._id || '',
      entraUscita: item.entraUscita || '',
      movdata: item.movdata || '',
      nome: item.nome || '',
      importo: item.importo || '',
      riferimento: item.riferimento || '',
      idAzienda: item.idAzienda || '2424',
    });
  };

  function handleCloseModal() {
    setShowModal(false);
    setShowModalEdit(false);
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeEdit = (e) => {
    setFormDataEdit({
      ...formDataEdit,
      [e.target.name]: e.target.value,
    });
  };

  const fetchData = async () => {
    try {
      const response = await fetchMovimenti(companyId, currentPage, limit);
      
      if (response.data) {
        setData(response.data.movimentis);
        setFilteredData(response.data.movimentis);
        setDataIsLoaded(true);
        setTotalPage(response.data.totalPages);
        
        // If current page is greater than total pages, reset to page 1
        if (currentPage > response.data.totalPages) {
          setCurrentPage(1);
        } else {
          setCurrentPage(response.data.currentPage);
        }
      }
    } catch (error) {
      console.error('There was an error fetching the data!', error);
      toast.error('Error fetching data');
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, limit]);



  const sumEntradata = async () => {
    try {
      const response = await sumEntra(companyId);
      setTotalEntraImporto(response.data.totalImporto);
    } catch (error) {
      console.error('There was an error fetching the data!', error);
    }
  };

  const sumEntraPreviousdata = async () => {
    try {
      const response = await sumEntraPrevious(companyId);
      setTotalEntraPreviousImporto(response.data.totalImportoPrevious);
    } catch (error) {
      console.error('There was an error fetching the data!', error);
    }
  };

  const sumUscitadata = async () => {
    try {
      const response = await sumUscita(companyId);
      setTotalUscitaImporto(response.data.totalImporto);
    } catch (error) {
      console.error('There was an error fetching the data!', error);
    }
  };

  const sumUscitaPreviousdata = async () => {
    try {
      const response = await sumUscitaPrevious(companyId);
      setTotalUscitaPreviousImporto(response.data.totalImportoPrevious);
    } catch (error) {
      console.error('There was an error fetching the data!', error);
    }
  };

  const sumAlldata = async () => {
    try {
      const response = await sumAll(companyId);
      setTotalAllImporto(response.data.totalImporto);
    } catch (error) {
      console.error('There was an error fetching the data!', error);
    }
  };

  const sumAllPreviousdata = async () => {
    try {
      const response = await sumAllPrevious(companyId);
      setTotalAllPreviousImporto(response.data.totalImportoPrevious);
    } catch (error) {
      console.error('There was an error fetching the data!', error);
    }
  };

  useEffect(() => {
    fetchData();
    sumEntradata();
    sumEntraPreviousdata();
    sumUscitadata();
    sumUscitaPreviousdata();
    sumAlldata();
    sumAllPreviousdata();
  }, []);

  const calculatePercentageChange = (current, previous) => {
    if (previous === 0) return '0.00%';
    const change = ((current - previous) / previous) * 100;
    return `${change.toFixed(2)}%`;
  };

  const handleSearch = (searchTerm) => {
    if (searchTerm.length >= 3) {
      const filtered = data.filter(item =>
        item.nome?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.riferimento?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
      setCurrentPage(1); // Reset to first page when searching
    } else {
      setFilteredData(data);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await addMovimenti(formData);
      if (response) {
        toast.success(response.data.message);
        fetchData();
        setShowModal(false);
      } else {
        toast.error("Failed to add movimenti!");
      }
    } catch (error) {
      console.error("Error adding movimenti:", error);
      toast.error("Error creating movimenti!");
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateMovimenti(formDataEdit._id, formDataEdit);
      setShowModalEdit(false);
      fetchData();
      toast.success(response.data.message);
    } catch (error) {
      console.error("Error editing movimenti:", error);
      toast.error("Error editing movimenti!");
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const applyFilters = () => {
    let filtered = data;
    if (filters.dateFrom) {
      filtered = filtered.filter(item => new Date(item.movdata) >= new Date(filters.dateFrom));
    }
    if (filters.dateTo) {
      filtered = filtered.filter(item => new Date(item.movdata) <= new Date(filters.dateTo));
    }
    if (filters.status && filters.status !== 'all') {
      filtered = filtered.filter(item => item.entraUscita === filters.status);
    }
    setFilteredData(filtered);
    setShowFiltersDialog(false);
    // Reset to first page when applying filters
    setCurrentPage(1);
  };
  const handleDeleteClick = (item) => {
    setItemToDelete(item);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    try {
      await deleteMovimenti(itemToDelete._id);
      toast.success("Movimenti deleted successfully");
      fetchData();
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error deleting movimenti:", error);
      toast.error("Error deleting movimenti");
    }
  };

  const handlePageChange = async (page) => {
    setCurrentPage(page);
    try {
      const response = await fetchMovimenti(companyId, page, limit);
      if (response.data) {
        setData(response.data.movimentis);
        setFilteredData(response.data.movimentis);
        setTotalPage(response.data.totalPages);
      }
    } catch (error) {
      console.error('Error changing page:', error);
      toast.error('Error loading page data');
    }
  };

  return (
    <div className="p-6 space-y-6">

      <div className="flex flex-col space-y-4 sm:flex-row sm:justify-between sm:items-center">
        <div className="grid grid-cols-2 gap-2 sm:flex sm:flex-row sm:items-center">
          <Button onClick={handleAddClick} className="w-full">
            Aggiungi
          </Button>
          <Label htmlFor="import-file" className="cursor-pointer w-full">
            <Input
              id="import-file"
              type="file"
              className="hidden"
              onChange={handleFileChange}
              accept=".csv, .xlsx"
            />
            <Button variant="outline" as="span" onClick={() => document.getElementById('import-file').click()} className="w-full">
              Importa
            </Button>
          </Label>
          <Select onValueChange={handleExport}>
            <SelectTrigger className="w-full sm:w-[180px]">
              <SelectValue placeholder="Esporta" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="excel">Esporta in Excel</SelectItem>
              <SelectItem value="pdf">Esporta in PDF</SelectItem>
            </SelectContent>
          </Select>

          <Button variant="outline" onClick={() => setShowFiltersDialog(true)} className="w-full sm:hidden">
            <Filter className="mr-2 h-4 w-4" /> Filtri
          </Button>
          {importFile ? (
            <Button onClick={handleImport} className="w-full">
              Import {importFile.name.slice(0, 10)}...
            </Button>
          ) : (
            <div className="w-full sm:hidden"></div> // Placeholder for grid layout
          )}
        </div>
        <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
          <div className="w-full sm:w-auto">
            <SearchBar onSearch={handleSearch} />
          </div>
          <Button variant="outline" onClick={() => setShowFiltersDialog(true)} className="hidden sm:flex">
            <Filter className="mr-2 h-4 w-4" /> Filtri
          </Button>
          <HelpSheet route="/movimenti" />
        </div>
      </div>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[100px]">Nr</TableHead>
              <TableHead>Data</TableHead>
              <TableHead>Nome</TableHead>
              <TableHead>Importo</TableHead>
              <TableHead>Riferimento</TableHead>
              <TableHead>Stato</TableHead>
              <TableHead className="text-right">Azioni</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell className="font-medium">{index + 1}</TableCell>
                  <TableCell>{item.movdata}</TableCell>
                  <TableCell>{item.nome}</TableCell>
                  <TableCell>€{item.importo}</TableCell>
                  <TableCell>{item.riferimento}</TableCell>
                  <TableCell>{item.entraUscita}</TableCell>
                  <TableCell className="text-right">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => handleEditClick(item)}
                          >
                            <Edit className="h-4 w-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Modifica</p>
                        </TooltipContent>
                      </Tooltip>

                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="ghost"
                            size="sm"
                            className="text-red-500"
                            onClick={() => handleDeleteClick(item)}
                          >
                            <Trash2 className='h-4 w-4 text-red-600' />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Elimina</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} className="text-center py-4">
                  No data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {filteredData?.length > 0 && (
        <div className="flex justify-end mt-4">
          <Pagination
            totalPages={totalPages} // Use the totalPages from API
            totalItems={data.length} // Use total items count from API if available
            onPageChange={handlePageChange}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
            setLimit={(newLimit) => {
              setLimit(newLimit);
              setCurrentPage(1); // Reset to first page when limit changes
            }}
          />
        </div>
      )}

      <Dialog open={showModal} onOpenChange={setShowModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Aggiungi nuova voce</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="entraUscita">Entrata Uscita</Label>
                <Select name="entraUscita" value={formData.entraUscita} onValueChange={(value) => handleChange({ target: { name: 'entraUscita', value } })}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Entrata">Entrata</SelectItem>
                    <SelectItem value="Uscita">Uscita</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="movdata">Data</Label>
                <Input type="date" name="movdata" onChange={handleChange} />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="nome">Nome</Label>
                <Input type="text" name="nome" id="nome" onChange={handleChange} />
              </div>
              <div className="space-y-2">
                <Label htmlFor="importo">Importo</Label>
                <Input
                  type="number"
                  step="0.01"
                  name="importo"
                  id="importo"
                  value={formData.importo}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="riferimento">Riferimento</Label>
              <Input type="text" name="riferimento" id="riferimento" onChange={handleChange} />
            </div>
            <DialogFooter>
              <Button type="button" variant="outline" onClick={handleCloseModal}>Cancel</Button>
              <Button type="submit">Add Movimenti</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={showDeleteModal} onOpenChange={setShowDeleteModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Conferma eliminazione</DialogTitle>
          </DialogHeader>
          <p>Sei sicuro di voler eliminare questo movimento?</p>
          <DialogFooter>
            <Button variant="outline" onClick={() => setShowDeleteModal(false)}>Annulla</Button>
            <Button variant="destructive" onClick={handleDelete}>Elimina</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={showModalEdit} onOpenChange={setShowModalEdit}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Modifica voce</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmitEdit} className="space-y-4">
            <input type="hidden" value={formDataEdit._id} />
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="entraUscita">Entrata Uscita</Label>
                <Select name="entraUscita" value={formDataEdit.entraUscita} onValueChange={(value) => handleChangeEdit({ target: { name: 'entraUscita', value } })}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Entrata">Entrata</SelectItem>
                    <SelectItem value="Uscita">Uscita</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="movdata">Data</Label>
                <Input type="date" name="movdata" value={formDataEdit.movdata} onChange={handleChangeEdit} />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="nome">Nome</Label>
                <Input type="text" name="nome" id="nome" value={formDataEdit.nome} onChange={handleChangeEdit} />
              </div>
              <div className="space-y-2">
                <Label htmlFor="importo">Importo</Label>
                <Input type="text" name="importo" id="importo" value={formDataEdit.importo} onChange={handleChangeEdit} />
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="riferimento">Riferimento</Label>
              <Input type="text" name="riferimento" id="riferimento" value={formDataEdit.riferimento} onChange={handleChangeEdit} />
            </div>
            <DialogFooter>
              <Button type="button" variant="outline" onClick={handleCloseModal}>Cancel</Button>
              <Button type="submit">Edit</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={showFiltersDialog} onOpenChange={setShowFiltersDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Filtri</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="dateFrom">Da Data</Label>
                <Input
                  type="date"
                  id="dateFrom"
                  name="dateFrom"
                  value={filters.dateFrom}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="dateTo">A Data</Label>
                <Input
                  type="date"
                  id="dateTo"
                  name="dateTo"
                  value={filters.dateTo}
                  onChange={handleFilterChange}
                />
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="status">Stato</Label>
              <Select
                name="status"
                value={filters.status}
                onValueChange={(value) => handleFilterChange({ target: { name: 'status', value } })}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona stato" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">Tutti</SelectItem>
                  <SelectItem value="Entrata">Entrata</SelectItem>
                  <SelectItem value="Uscita">Uscita</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setShowFiltersDialog(false)}>Annulla</Button>
            <Button onClick={applyFilters}>Applica Filtri</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default TableComponent;