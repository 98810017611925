import { createJsonTypeInstance} from './index';
const axios = createJsonTypeInstance();

export const fetchCurrencies = async () => {
  try {
    const response = await axios.get('/valuta');
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const fetchUm = async () => {
  try {
    const response = await axios.get('/um');
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const addPreventivi = async (data) => {
  try {
    const response = await axios.post('/preventivi' , data);
    return response;
  } catch (error) {
    throw error.response.data; 
  }
};


export const addPreventiviInvoice = async (data) => {
  try {
    const response = await axios.post('/preventivi_invoice' , data);
    return response;
  } catch (error) {
    throw error.response.data; 
  }
};

export const fetchClients = async (companyId) => {
  try {
    const response = await axios.get(`/client/all-Clients/${companyId}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};
