import { createJsonTypeInstance } from './index';
const axios = createJsonTypeInstance();

export const createReport = async (data) => {
  try {
    const response = await axios.post('/reports', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const getReports = async (constructionSiteId) => {
  try {
    const response = await axios.get(`/reports/construction-site/${constructionSiteId}`);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const getReport = async (id) => {
  try {
    const response = await axios.get(`/reports/${id}`);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateReport = async (id, data) => {
  try {
    const response = await axios.patch(`/reports/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteReport = async (id) => {
  try {
    const response = await axios.delete(`/reports/${id}`);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const exportAllReportsToPDF = async (constructionSiteId) => {
  try {
    const response = await axios.get(`/reports/construction-site/${constructionSiteId}/export-pdf`, {
      responseType: 'blob'
    });
    
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'rapporti_cantiere.pdf');
    document.body.appendChild(link);
    link.click();
    
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error exporting PDF:', error);
    throw error.response ? error.response.data : error;
  }
};
