import { Input } from "../../../../ui/input";
import { Label } from "../../../../ui/label";

const Position = ({ register }) => (
  <div className="flex flex-col gap-4">
    <div className="flex flex-wrap gap-4">
      <div className="flex-1 space-y-2">
        <Label htmlFor="address">Indirizzo</Label>
        <Input
          {...register("address")}
          id="address"
          placeholder="Inserisci l'indirizzo"
        />
      </div>
      <div className="flex-1 space-y-2">
        <Label htmlFor="city">Città</Label>
        <Input
          {...register("city")}
          id="city"
          placeholder="Inserisci la città"
        />
      </div>
    </div>
    <div className="flex flex-wrap gap-4">
      <div className="flex-1 space-y-2">
        <Label htmlFor="postalCode">Codice Postale</Label>
        <Input
          {...register("postalCode")}
          id="postalCode"
          placeholder="Inserisci il codice postale"
        />
      </div>
      <div className="flex-1 space-y-2">
        <Label htmlFor="province">Provincia</Label>
        <Input
          {...register("province")}
          id="province"
          placeholder="Inserisci la provincia"
        />
      </div>
      <div className="flex-1 space-y-2">
        <Label htmlFor="nation">Nazione</Label>
        <Input
          {...register("nation")}
          id="nation"
          placeholder="Inserisci la nazione"
        />
      </div>
    </div>
  </div>
);

export default Position;