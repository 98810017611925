import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { vehicleService } from '../../../services/vehicleService';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../ui/card";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { Button } from "../../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { Checkbox } from "../../ui/checkbox";
import { Skeleton } from "../../ui/skeleton";
import { Alert, AlertDescription } from "../../ui/alert";
import { ArrowLeft } from "lucide-react";

const DetailComponent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [vehicle, setVehicle] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.companyId) {
      setCompanyId(user.companyId);
    } else {
      setError("Nessun ID azienda trovato. Per favore, effettua nuovamente l'accesso.");
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (id && companyId) {
      fetchVehicleDetails();
    }
  }, [id, companyId]);

  const fetchVehicleDetails = async () => {
    try {
      setLoading(true);
      const data = await vehicleService.getVehicleById(id, companyId);
      setVehicle(data);
    } catch (error) {
      console.error('Errore nel recupero dei dettagli del veicolo:', error);
      setError("Impossibile recuperare i dettagli del veicolo. Riprova più tardi.");
    } finally {
      setLoading(false);
    }
  };

  const renderField = (label, value) => (
    <div className="space-y-2">
      <Label>{label}</Label>
      <Input value={value || ''} readOnly />
    </div>
  );

  if (loading) {
    return (
      <Card className="w-full">
        <CardHeader>
          <Skeleton className="h-8 w-64" />
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {[...Array(3)].map((_, sectionIndex) => (
              <div key={sectionIndex} className="space-y-4">
                <Skeleton className="h-6 w-32" />
                {[...Array(6)].map((_, fieldIndex) => (
                  <div key={fieldIndex} className="space-y-2">
                    <Skeleton className="h-4 w-24" />
                    <Skeleton className="h-10 w-full" />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  if (!vehicle) {
    return (
      <Alert>
        <AlertDescription>Nessun veicolo trovato.</AlertDescription>
      </Alert>
    );
  }

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Dettagli del Veicolo</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="space-y-4">
            <h4 className="font-medium">Dati del veicolo</h4>
            {renderField("Marca", vehicle.make)}
            {renderField("Modello", vehicle.model)}
            {renderField("Tipo", vehicle.type)}
            {renderField("Targa", vehicle.licensePlate)}
            {renderField("Alimentazione", vehicle.fuelType)}
            {renderField("Data acquisto", new Date(vehicle.purchaseDate).toLocaleDateString())}
            {renderField("Prezzo acquisto", vehicle.purchasePrice)}
            {renderField("Immatricolazione", new Date(vehicle.registrationDate).toLocaleDateString())}
            {renderField("Numero telaio", vehicle.vinNumber)}
          </div>

          <div className="space-y-4">
            <h4 className="font-medium">Caratteristiche del veicolo</h4>
            {renderField("Cilindrata", vehicle.engineCapacity)}
            {renderField("Cavalli", vehicle.horsepower)}
            {renderField("Colore", vehicle.color)}
            {renderField("Posti a sedere", vehicle.seatingCapacity)}
            {renderField("Peso", vehicle.weight)}
            {renderField("Pneumatici", vehicle.tires)}
            {renderField("Numero assi", vehicle.axleCount)}
          </div>

          <div className="space-y-4">
            <h4 className="font-medium">Stato</h4>
            <div className="space-y-2">
              <Label>Attivo</Label>
              <div>
                <Checkbox checked={vehicle.active} disabled />
              </div>
            </div>

            <div className="space-y-2">
              <Label>Assicurazione</Label>
              <Select defaultValue={vehicle.insurance} disabled>
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="valid">Valida</SelectItem>
                  <SelectItem value="expired">Scaduta</SelectItem>
                </SelectContent>
              </Select>
            </div>

            {renderField("Scadenza Assicurazione", vehicle.insuranceExpiryDate?.split('T')[0])}

            <div className="space-y-2">
              <Label>Revisione</Label>
              <Select defaultValue={vehicle.inspection} disabled>
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="present">Presente</SelectItem>
                  <SelectItem value="not_present">Non Presente</SelectItem>
                </SelectContent>
              </Select>
            </div>

            {renderField("Scadenza Revisione", vehicle.nextInspectionDate?.split('T')[0])}
          </div>
        </div>
      </CardContent>
      <CardFooter className="flex justify-end bg-gray-50">
        <Button 
          variant="destructive" 
          onClick={() => navigate('/flotta')}
          className="bg-[#8c060c] hover:bg-[#8c060c]/90"
        >
          <ArrowLeft className="mr-2 h-4 w-4" />
          Indietro
        </Button>
      </CardFooter>
    </Card>
  );
};

export default DetailComponent;