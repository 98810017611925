import { createJsonTypeInstance } from './index';
const axios = createJsonTypeInstance();

export const createTicket = async (data) => {
  try {
    const response = await axios.post('/tickets', data);
    return response.data;
  } catch (error) {
    console.error('Error creating ticket:', error);
    throw error.response ? error.response.data : error;
  }
};

export const updateTicket = async (id, data) => {
  try {
    const response = await axios.put(`/tickets/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating ticket:', error);
    throw error.response ? error.response.data : error;
  }
};

export const getAllTickets = async () => {
  try {
    const response = await axios.get('/tickets');
    return response.data;
  } catch (error) {
    console.error('Error getting all tickets:', error);
    throw error.response ? error.response.data : error;
  }
};

export const getUserTickets = async () => {
  try {
    const response = await axios.get('/tickets/user');
    return response.data;
  } catch (error) {
    console.error('Error getting user tickets:', error);
    throw error.response ? error.response.data : error;
  }
};

export const getTicketById = async (id) => {
  try {
    const response = await axios.get(`/tickets/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error getting ticket by ID:', error);
    throw error.response ? error.response.data : error;
  }
};

export const deleteTicket = async (id) => {
  try {
    const response = await axios.delete(`/tickets/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting ticket:', error);
    throw error.response ? error.response.data : error;
  }
};

export const addComment = async (id, data) => {
  try {
    const response = await axios.post(`/tickets/${id}/comments`, data);
    return response.data;
  } catch (error) {
    console.error('Error adding comment:', error);
    throw error.response ? error.response.data : error;
  }
};