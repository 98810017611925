import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import CapitoliComponent from '../../../components/admin/prezzari_regioni/capitoli'

const CapitoliDetails = () => {
  return (
    <Layout>
      <CapitoliComponent />
    </Layout>
  )
}

export default CapitoliDetails