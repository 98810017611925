// apis/Violation.js
import { createJsonTypeInstance } from './index';
const axios = createJsonTypeInstance();

export const createViolation = async (data) => {
  try {
    const response = await axios.post('/violations', data);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const getViolations = async () => {
  try {
    const response = await axios.get('/violations');
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateViolation = async (id, data) => {
  try {
    const response = await axios.patch(`/violations/${id}`, data);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteViolation = async (id) => {
  try {
    const response = await axios.delete(`/violations/${id}`);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};