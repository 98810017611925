import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteAccount } from '../../../../apis/ProfilioEdit';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../../ui/alert-dialog";
import { Button } from "../../../ui/button";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";

function DeleteAccountModal({ isOpen, onClose, id }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confermarepassword, setConfermarePassword] = useState('');
  const [error, setError] = useState(null);

  const handleDelete = async () => {
    if (password !== confermarepassword) {
      setError("Le password non coincidono.");
      return;
    }
    setLoading(true);
    try {
      const response = await deleteAccount(id, { password, confermarepassword });

      if (!response.ok) {
        throw new Error('Failed to delete account');
      }

      toast.success("Account eliminato con successo");
      navigate('/login'); // Assuming you want to redirect to login page after deletion
      onClose();
    } catch (error) {
      toast.error(`Errore durante l'eliminazione dell'account: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Sei sicuro di voler eliminare il tuo account?</AlertDialogTitle>
          <AlertDialogDescription>
            Questa azione non può essere annullata. Eliminerà permanentemente il tuo account
            e rimuoverà i tuoi dati dai nostri server.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-1 items-center gap-4">
            <Label htmlFor="password" className="text-left">
              Password
            </Label>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="col-span-3"
              placeholder="Inserisci la nuova password"
            />
          </div>
          {error && (
            <p className="text-red-500 text-sm">{error}</p>
          )}
        </div>



        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-1 items-center gap-4">
            <Label htmlFor="password" className="text-left">
              Confermare Password
            </Label>
            <Input
              id="confermarepassword"
              type="password"
              value={confermarepassword}
              onChange={(e) => setConfermarePassword(e.target.value)}
              className="col-span-3"
              placeholder="Inserisci la nuova password"
            />
          </div>
          {error && (
            <p className="text-red-500 text-sm">{error}</p>
          )}
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel asChild>
            <Button variant="outline" onClick={onClose}>Annulla</Button>
          </AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button variant="destructive" onClick={handleDelete} disabled={loading}>
              {loading ? "Eliminazione in corso..." : "Elimina Account"}
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default DeleteAccountModal;