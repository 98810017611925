import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import ProfilioComponent from '../../../components/admin/profilio/index'

const Profilio = () => {
  return (
    <Layout>
      <ProfilioComponent />
    </Layout>
  )
}

export default Profilio