import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import DdtComponent from '../../../components/admin/ddt/index'

const DdtDetails = () => {
  return (
    <Layout>
      <DdtComponent />
    </Layout>
  )
}

export default DdtDetails