import {createJsonTypeInstance ,  createMultipartInstance } from './index';
const axios =createJsonTypeInstance();

export const AddTraining = async (data) => {
  try {
    const axiosInstance = createMultipartInstance();
    const uploadData = new FormData();

    Object.keys(data).forEach(key => {
      if (key === 'file' && data[key]) {
        uploadData.append(key, data[key]);
      } else {
        uploadData.append(key, data[key]);
      }
    });
    const response = await axiosInstance.post("/training", uploadData);
    return response;
  } catch (error) {
    throw error.response;
  }
};


export const fetchTrainings = async (companyId, currentPage, limit ,searchQuery) => {
    try {
      const response = await axios.get(`/training/all-training/${companyId}?page=${currentPage}&limit=${limit}&search=${searchQuery}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  };

  export const fetchSingleTraining = async (id) => {
    try {
      const response = await axios.get(`/training/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  };


  export const deleteTrainingCertificate = async (id) => {
    try {
      const response = await axios.delete(`/training/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  };



  export const updateTraining = async (id, data) => {
    try {
      const axiosInstance = createMultipartInstance();
      const updateData = new FormData();

      Object.keys(data).forEach(key => {
        if (key === 'file' && data[key]) {
          updateData.append(key, data[key][0]);
        } else {
          updateData.append(key, data[key]);
        }
      });
      const response = await axiosInstance.put(`/training/${id}`, updateData);
      return response;
    } catch (error) {
      throw error.response;
    }
  };