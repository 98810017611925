import {createJsonTypeInstance}  from './index';
const axios =createJsonTypeInstance();


export const AddEvent = async (data) => {
    try {
      const response = await axios.post('/events' , data);
      return response;
    } catch (error) {
      throw error.response.data; 
    }
  };

  export const getEvents = async () => {
    try {
      const response = await axios.get('/events');
      return response;
    } catch (error) {
      throw error.response.data; 
    }
  };

  export const getEvent = async (id) => {
    try {
      const response = await axios.get(`/events/${id}`);
      return response;
    } catch (error) {
      throw error.response.data; 
    }
  };


  export const updateEvent = async (id ,data) => {
    try {
      const response = await axios.patch(`/events/${id}` , data);
      return response;
    } catch (error) {
      throw error.response.data; 
    }
  };

  export const deleteEvent = async (id ) => {
    try {
      const response = await axios.delete(`/events/${id}`);
      return response;
    } catch (error) {
      throw error.response.data; 
    }
  };

  