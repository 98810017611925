import React from 'react';
import { deleteUser } from '../../../../apis/Utenti';
import { toast } from 'react-toastify';

import { Button } from "../../../ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog";

function DeleteUserModal({ isOpen, onClose, userId, onDelete }) {
  const handleDelete = async () => {
    try {
      await deleteUser(userId);
      toast.success("User deleted successfully");
      onDelete && onDelete(); // Refresh the list after deletion
      onClose();
    } catch (error) {
      toast.error("Unable to delete user");
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete User</DialogTitle>
          <DialogDescription>
            Are you sure you want to delete this user? This action cannot be undone.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="destructive" onClick={handleDelete}>
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default DeleteUserModal;