import { createJsonTypeInstance } from './index';

export const fetchCurrentSubscription = async () => {
  const axiosInstance = createJsonTypeInstance();
  try {
    const response = await axiosInstance.get('/subscription/current');
    return response.data.subscription;
  } catch (error) {
    console.error('Error fetching current subscription:', error);
    throw error;
  }
};

export const cancelSubscription = async () => {
  const axiosInstance = createJsonTypeInstance();
  try {
    const response = await axiosInstance.post('/subscription/cancel');
    return response.data;
  } catch (error) {
    console.error('Error cancelling subscription:', error);
    throw error;
  }
};

export const initiateStripeCheckout = async (priceId, username, source = 'existing') => {
  const axiosInstance = createJsonTypeInstance();
  try {
    const response = await axiosInstance.post('/subscription/checkout', { priceId, username, source });
    return response.data;
  } catch (error) {
    console.error('Error initiating Stripe checkout:', error);
    throw error;
  }
};

export const changePlan = async (newPriceId) => {
  const axiosInstance = createJsonTypeInstance();
  try {
    const response = await axiosInstance.post('/subscription/change-plan', { newPriceId });
    return response.data;
  } catch (error) {
    console.error('Error changing subscription plan:', error);
    throw error;
  }
};

export const fetchPlans = async () => {
  const axiosInstance = createJsonTypeInstance();
  try {
    const response = await axiosInstance.get('/subscription/plans');
    return response.data;
  } catch (error) {
    console.error('Error fetching plans:', error);
    throw error;
  }
};

export const resetSubscription = async () => {
  const axiosInstance = createJsonTypeInstance();
  try {
    const response = await axiosInstance.post('/subscription/reset');
    return response.data;
  } catch (error) {
    console.error('Error resetting subscription:', error);
    throw error;
  }
};

export const initiateFreeTrial = async (planId, username) => {
  const axiosInstance = createJsonTypeInstance();
  try {
    const response = await axiosInstance.post('/subscription/free-trial', { planId, username });
    return response.data;
  } catch (error) {
    console.error('Error initiating free trial:', error);
    throw error;
  }
};