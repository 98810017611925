import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../../ui/dialog";
import { Button } from "../../../ui/button";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../ui/select";
import { Textarea } from "../../../ui/textarea";

const EditModal = ({ open, item, onClose, onUpdate, fetchClients, companyId }) => {
  const [clients, setClients] = useState([]);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (item) {
      setFormData({ ...item });
    }
  }, [item]);

  useEffect(() => {
    const getClients = async () => {
      try {
        const clientsData = await fetchClients(companyId);
        setClients(clientsData?.data.clients || []);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    getClients();
  }, [companyId, fetchClients]);

  const handleChange = (name, value) => {
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.cliente_id) {
      // Show error message or prevent submission
      return;
    }
    onUpdate(formData);
  };


  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Modifica Computimetric</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="cliente_id">Cliente</Label>
            <Select
              name="cliente_id"
              value={formData.cliente_id || ''}
              onValueChange={(value) => handleChange('cliente_id', value)}
              required
            >

              <SelectTrigger>
                <SelectValue placeholder="Seleziona cliente" />
              </SelectTrigger>
              <SelectContent>
                {clients.map(client => (
                  <SelectItem key={client._id} value={client._id}>
                    {client.companyName || client.fullName}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2">
            <Label htmlFor="descrizione_lavori">Descrizione</Label>
            <Textarea
              id="descrizione_lavori"
              name="descrizione_lavori"
              value={formData.descrizione_lavori || ''}
              onChange={(e) => handleChange('descrizione_lavori', e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="data_computi">Data</Label>
            <Input
              type="date"
              id="data_computi"
              name="data_computi"
              value={formData.data_computi ? new Date(formData.data_computi).toISOString().split('T')[0] : ''}
              onChange={(e) => handleChange('data_computi', e.target.value)}
            />
          </div>
          {/* <div className="space-y-2">
            <Label htmlFor="computi_importo">Importo</Label>
            <Input
              type="number"
              id="computi_importo"
              name="computi_importo"
              value={formData.computi_importo || ''}
              onChange={(e) => handleChange('computi_importo', e.target.value)}
            />
          </div> */}
          <DialogFooter>
            <Button type="button" variant="outline" onClick={onClose}>Annulla</Button>
            <Button type="submit">Aggiorna</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditModal;