import React, { useState, useRef } from "react";
import * as XLSX from "xlsx";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { importClientList } from "../../../apis/client";
import clientExampleFile from "../../../assest/clientFormatFile.xlsx";
import supplierExampleFile from "../../../assest/importSupplierFileFormat.xlsx";
import employeeExampleFile from "../../../assest/importEmployeeFileFomat.xlsx";

const ImportFile = ({ isOpen, onClose, importFunction, vendorType }) => {
  const [fileData, setFileData] = useState(null);
  const [fileName, setFileName] = useState("");
  const { handleSubmit } = useForm();
  const hiddenFileInput = useRef(null);

  const transformKeys = (data) => {
    return data.map((item) => {
      const transformedItem = {};
      for (const key in item) {
        const newKey = key.charAt(0).toLowerCase() + key.slice(1);
        transformedItem[newKey] = item[key];
      }
      return transformedItem;
    });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFileName(file.name);
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      const transformedJson = transformKeys(json);
      setFileData(transformedJson);
      console.log(transformedJson);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileUpload({ target: { files: [file] } });
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const onSubmit = async () => {
    if (fileData) {
      console.log(fileData);
      importFunction(fileData);
      onClose()
    } else {
      toast.error("Please upload a file first.");
    }
  };

  return (
    <div
      id="authentication-modal"
      tabIndex="-1"
      aria-hidden={!isOpen}
      className={`${
        isOpen ? "flex" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-black bg-opacity-50`}
    >
      <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 w-[35%] p-4">
        <h1 className="font-semibold my-2">Carica un file excel per importare</h1>
        <hr></hr>
        {vendorType}
        <h1 className="my-2">
          Non hai un file di esempio?, {" "}
          <a
            download
            href={
              vendorType === "Clienti"
                ? clientExampleFile
                : vendorType === "Suppliers"
                ? supplierExampleFile
                : vendorType === "Employees"
                ? employeeExampleFile
                : "#"
            }
            className="text-blue-600 underline cursor-pointer"
          >
            Scaricalo qui
          </a>
        </h1>

        <div
          className="flex justify-center items-center border-2 border-dashed border-gray-300 rounded-lg p-7 bg-gray-100 cursor-pointer"
          onClick={handleClick}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            ref={hiddenFileInput}
            style={{ display: "none" }}
          />
          <p className="text-center text-gray-700 font-bold italic 2xl:text-[20px]">
            {fileName
              ? `Uploaded file: ${fileName}`
              : "Drop file or click here to upload file"}
          </p>
        </div>

        <div className="flex items-end gap-2 justify-end">
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-3 py-1 mt-4 rounded"
          >
            Chiudi
          </button>
          <button
            onClick={handleSubmit(onSubmit)}
            className="text-white bg-[#06052C] px-3 py-1 mt-4 rounded"
          >
            Importa
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImportFile;
