import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Eye, Edit, Trash, Plus, FileDown } from "lucide-react";
import { fetchComputimetric, updateComputimetric, addComputimetric, deleteComputimetric, fetchClients, downloadComputimetricPDF } from '../../../../apis/Computimetric';
import Pagination from '../../sharedComponent/Pgination';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../ui/tooltip";

import { Button } from "../../../ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table";
import { Alert, AlertDescription } from "../../../ui/alert";

import EditModal from './EditModal';
import AddModal from './AddModal';
import SearchBar from '../../sharedComponent/SearchBar';
import HelpSheet from '../../sharedComponent/HelpSheet';
import { exportToPDF } from '../../../../config/helper';

const TableComponent = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user?.companyId;
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getItems = async () => {
    try {
      const response = await fetchComputimetric(companyId, currentPage, limit);
      const limitedItems = response.data.computimetrics.slice(0, limit);
      setData(limitedItems);
      setFilteredData(limitedItems);
      setDataIsLoaded(true);
      setTotalPage(Math.ceil(response.data.totalItems / limit));
      setCurrentPage(response.data?.currentPage);
    } catch (error) {
      console.error('Errore nel recupero dei dati!', error);
      setAlertMessage('Errore nel recupero dei dati. Riprova più tardi.');
    }
  };

  useEffect(() => {
    getItems();
  }, [currentPage, limit]);

  const handleSearch = (searchTerm) => {
    if (searchTerm.length >= 3) {
      const filtered = data.filter(item =>
        item.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.clienti?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  };

  const handleAddClick = () => {
    setIsAddModalOpen(true);
  };

  const handleAdd = async (newData) => {
    try {
      await addComputimetric(newData);
      setIsAddModalOpen(false);
      getItems();
      setAlertMessage('Elemento aggiunto con successo.');
    } catch (error) {
      console.error('Errore nell\'aggiunta dell\'elemento:', error);
      setAlertMessage('Errore nell\'aggiunta dell\'elemento. Riprova.');
    }
  };

  const handleEditClick = (item) => {
    setSelectedItem(item);
    setIsEditModalOpen(true);
  };

  const handleUpdate = async (updatedData) => {
    try {
      if (!updatedData._id) {
        console.error('ID mancante nei dati aggiornati');
        setAlertMessage('Errore: ID mancante. Impossibile aggiornare.');
        return;
      }

      await updateComputimetric(updatedData._id, updatedData);
      setIsEditModalOpen(false);
      getItems();
      setAlertMessage('Elemento aggiornato con successo.');
    } catch (error) {
      console.error('Errore nell\'aggiornamento dell\'elemento:', error);
      setAlertMessage('Errore nell\'aggiornamento dell\'elemento. Riprova.');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Sei sicuro di voler eliminare questo elemento?')) {
      try {
        await deleteComputimetric(id);
        getItems();
        setAlertMessage('Elemento eliminato con successo.');
      } catch (error) {
        console.error('Errore nella cancellazione dell\'elemento!', error);
        setAlertMessage('Errore nella cancellazione dell\'elemento. Riprova.');
      }
    }
  };

  const handleExport = async (id) => {
    try {
      const formattedData = filteredData.map((item, index) => ({
        nu: (index + 1).toString(),
        numero: item.numero || '-',
        data: new Date(item.data_computi).toLocaleDateString(),
        cliente: item.clientName || '-',
        descrizione: item.descrizione_lavori || '-',
        importo: `€${item.computi_importo}`
      }));

      const columns = [
        { header: "N°", key: "nu" },
        { header: "Numero", key: "numero" },
        { header: "Data", key: "data" },
        { header: "Cliente", key: "cliente" },
        { header: "Descrizione", key: "descrizione" },
        { header: "Importo", key: "importo" }
      ];

      const stats = [
        { label: "Totale Computi", value: filteredData.length },
        { label: "Data", value: new Date().toLocaleDateString() }
      ];

      exportToPDF(formattedData, columns, "Lista Computi Metrici", stats);
      setAlertMessage('PDF esportato con successo.');
    } catch (error) {
      console.error('Errore nel download del PDF:', error);
      setAlertMessage('Errore nel download del PDF. Riprova.');
    }
  };


  return (
    <div className="p-6 space-y-6">
      {alertMessage && (
        <Alert variant="info" className="mb-4">
          <AlertDescription>{alertMessage}</AlertDescription>
        </Alert>
      )}
      <div className="flex justify-between items-center">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button onClick={handleAddClick}>
                Nuovo
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Apri</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <div className="flex items-center space-x-2">
          <SearchBar onSearch={handleSearch} className='md:w-1/6 flex justify-center items-center' />
          <HelpSheet route="/computi-metrici" />
        </div>
      </div>

      <div className="rounded-lg border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Numero</TableHead>
              <TableHead>Data</TableHead>
              <TableHead>Cliente</TableHead>
              <TableHead>Descrizione</TableHead>
              {/* <TableHead>Importo</TableHead> */}
              <TableHead>Azione</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <TableRow key={item._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{new Date(item.data_computi).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-')}</TableCell>
                  <TableCell>{item.clientName || ''}</TableCell>
                  <TableCell>{item.descrizione_lavori}</TableCell>
                  {/* <TableCell>{item.computi_importo}</TableCell> */}
                  <TableCell>
                    <div className="flex space-x-2">
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Link to={`/computrimetriciaddrec/${item._id}`}>
                              <Button variant="ghost" size="sm">
                                <Eye className="h-4 w-4" />
                              </Button>
                            </Link>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Apri</p>
                          </TooltipContent>
                        </Tooltip>

                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => handleEditClick(item)}
                            >
                              <Edit className="h-4 w-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Modifica</p>
                          </TooltipContent>
                        </Tooltip>

                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => handleDelete(item._id)}
                            >
                              <Trash className="h-4 w-4 text-red-500" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Elimina</p>
                          </TooltipContent>
                        </Tooltip>

                        {/* <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => handleExport(item._id)}
                            >
                              <FileDown className="h-4 w-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Scarica</p>
                          </TooltipContent>
                        </Tooltip> */}
                      </TooltipProvider>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} className="text-center py-4">
                  Nessun dato
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {filteredData.length > 0 && (
        <Pagination
          totalPages={totalPages}
          totalItems={filteredData.length}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}

      <AddModal
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onAdd={handleAdd}
        fetchClients={fetchClients}
        companyId={companyId}
      />
      <EditModal
        open={isEditModalOpen}
        item={selectedItem}
        onClose={() => setIsEditModalOpen(false)}
        onUpdate={handleUpdate}
        fetchClients={fetchClients}
        companyId={companyId}
      />
    </div>
  );
};

export default TableComponent;