import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import MagazzionComponent from '../../../components/admin/magazzino/index'

const MagazzinoDetails = () => {
  return (
    <Layout>
      <MagazzionComponent />
    </Layout>
  )
}

export default MagazzinoDetails