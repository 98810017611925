import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import Ddt_addComponent from '../../../components/admin/ddt/ddt_add'

const Ddt_addDetails = () => {
  return (
    <Layout>
      <Ddt_addComponent />
    </Layout>
  )
}

export default Ddt_addDetails