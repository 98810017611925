import { Input } from "../../../../ui/input";
import { Label } from "../../../../ui/label";

const Contacts = ({ register }) => (
  <div className="space-y-4">
    <div className="space-y-2">
      <Label htmlFor="email">Email</Label>
      <Input
        {...register("email")}
        type="email"
        id="email"
        placeholder="esempio@esempio.com"
      />
    </div>
    <div className="flex flex-wrap gap-4">
      <div className="flex-1 space-y-2">
        <Label htmlFor="mobilePhone">Cellulare</Label>
        <Input
          {...register("mobilePhone")}
          type="tel"
          id="mobilePhone"
          placeholder="123-456-7890"
        />
      </div>
      <div className="flex-1 space-y-2">
        <Label htmlFor="telephone">Telefono</Label>
        <Input
          {...register("telephone")}
          type="tel"
          id="telephone"
          placeholder="123-456-7890"
        />
      </div>
    </div>
    <div className="flex flex-wrap gap-4">
      <div className="flex-1 space-y-2">
        <Label htmlFor="pec">PEC</Label>
        <Input
          {...register("pec")}
          type="email"
          id="pec"
          placeholder="esempio@pec.it"
        />
      </div>
      <div className="flex-1 space-y-2">
        <Label htmlFor="website">Sito Web</Label>
        <Input
          {...register("website")}
          type="url"
          id="website"
          placeholder="www.esempio.it"
        />
      </div>
    </div>
  </div>
);

export default Contacts;