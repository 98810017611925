// apis/Planning.js
import { createJsonTypeInstance } from "./index";
const axios = createJsonTypeInstance();

export const getCompanyEmployeePlannings = async (monthQuery, searchQuery) => {
  try {
    const response = await axios.get(`/planning?month=${monthQuery}&search=${searchQuery}`);
    return response;
  } catch (error) {
    console.error("Error fetching employee plannings:", error.response?.data || error.message);
    throw error.response?.data || { error: "An unexpected error occurred" };
  }
};

export const updateCompanyEmployeePlannings = async (data) => {
  try {
    const response = await axios.post(`/planning/`, data);
    return response;
  } catch (error) {
    console.error("Error updating employee planning:", error.response?.data || error.message);
    throw error.response?.data || { error: "An unexpected error occurred" };
  }
};

export const deleteCompanyEmployeePlannings = async (id) => {
  try {
    const response = await axios.delete(`/planning/${id}`);
    return response;
  } catch (error) {
    console.error("Error deleting employee planning:", error.response?.data || error.message);
    throw error.response?.data || { error: "An unexpected error occurred" };
  }
};

export const getCompanyPlans = async (companyId) => {
  try {
    const response = await axios.get(`/plans`, { params: { companyId } });
    return response;
  } catch (error) {
    console.error("Error fetching company plans:", error.response?.data || error.message);
    throw error.response?.data || { error: "An unexpected error occurred" };
  }
};

export const createCompanyPlan = async (planData) => {
  try {
    const response = await axios.post('/plans', planData);
    return response;
  } catch (error) {
    console.error("Error creating company plan:", error.response?.data || error.message);
    throw error.response?.data || { error: "An unexpected error occurred" };
  }
};

export const updateCompanyPlan = async (planId, planData) => {
  try {
    const response = await axios.put(`/plans/${planId}`, planData);
    return response;
  } catch (error) {
    console.error("Error updating company plan:", error.response?.data || error.message);
    throw error.response?.data || { error: "An unexpected error occurred" };
  }
};

export const deleteCompanyPlan = async (planId) => {
  try {
    const response = await axios.delete(`/plans/${planId}`);
    return response;
  } catch (error) {
    console.error("Error deleting company plan:", error.response?.data || error.message);
    throw error.response?.data || { error: "An unexpected error occurred" };
  }
};

// New function to get a single plan
export const getCompanyPlan = async (planId) => {
  try {
    const response = await axios.get(`/plans/${planId}`);
    return response;
  } catch (error) {
    console.error("Error fetching company plan:", error.response?.data || error.message);
    throw error.response?.data || { error: "An unexpected error occurred" };
  }
};

// New function to get plannings for a specific employee
export const getEmployeePlannings = async (employeeId, monthQuery) => {
  try {
    const response = await axios.get(`/planning/employee/${employeeId}?month=${monthQuery}`);
    return response;
  } catch (error) {
    console.error("Error fetching employee plannings:", error.response?.data || error.message);
    throw error.response?.data || { error: "An unexpected error occurred" };
  }
};