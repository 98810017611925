import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getConstructionSite, addDocuments, deleteDocument, updateDocumentName } from '../../../apis/ConstructionSite';
import { FaTrash, FaEdit, FaCheck, FaTimes, FaUpload, FaFile, FaEye } from 'react-icons/fa';
import ConstructionSiteNavbar from './ConstructionSiteNavbar';

import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import { Loader2 } from "lucide-react";

function TableComponent() {
  const { id } = useParams();
  const [site, setSite] = useState({ documents: [] });
  const [files, setFiles] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [editingDocument, setEditingDocument] = useState(null);
  const [newDocumentName, setNewDocumentName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    fetchSite();
  }, [id]);

  const fetchSite = async () => {
    setIsLoading(true);
    try {
      const response = await getConstructionSite(id);
      setSite(response.data);
    } catch (error) {
      console.error('Error fetching construction site:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const handleUpload = async () => {
    if (files.length === 0) return;
    setIsUploading(true);
    try {
      const formData = new FormData();
      files.forEach(file => formData.append('documents', file));
      await addDocuments(id, formData);
      fetchSite();
      setFiles([]);
    } catch (error) {
      console.error('Error uploading documents:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeleteClick = (document) => {
    setDocumentToDelete(document);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    setIsLoading(true);
    try {
      await deleteDocument(id, documentToDelete._id);
      fetchSite();
      setDeleteModalOpen(false);
    } catch (error) {
      console.error('Error deleting document:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditClick = (document) => {
    setEditingDocument(document);
    setNewDocumentName(document.name);
  };

  const handleEditSave = async () => {
    setIsLoading(true);
    try {
      await updateDocumentName(id, editingDocument._id, newDocumentName);
      fetchSite();
      setEditingDocument(null);
    } catch (error) {
      console.error('Error updating document name:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div >
      <ConstructionSiteNavbar />
      <Card className="mt-6">
        <CardHeader>
          <CardTitle>Gestione Documenti</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="mb-6 flex items-center space-x-4">
            <Input
              type="file"
              multiple
              onChange={handleFileChange}
            />
            <Button
              onClick={handleUpload}
              disabled={isUploading || files.length === 0}
            >
              {isUploading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Caricamento...
                </>
              ) : (
                <>
                  <FaUpload className="mr-2" />
                  Carica Documenti
                </>
              )}
            </Button>
          </div>

          <h3 className="text-xl font-semibold mb-4">Documenti Esistenti:</h3>
          {isLoading ? (
            <div className="flex justify-center items-center h-32">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : site.documents.length > 0 ? (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Nome</TableHead>
                  <TableHead>Data di caricamento</TableHead>
                  <TableHead>Azioni</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {site.documents.map((doc) => (
                  <TableRow key={doc._id}>
                    <TableCell>
                      <div className="flex items-center">
                        <FaFile className="flex-shrink-0 h-5 w-5 text-gray-400 mr-3" />
                        <div className="text-sm font-medium">
                          {editingDocument === doc ? (
                            <Input
                              value={newDocumentName}
                              onChange={(e) => setNewDocumentName(e.target.value)}
                              className="w-full"
                            />
                          ) : (
                            doc.name
                          )}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      {new Date(doc.uploadDate).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center space-x-2">
                        <Button variant="ghost" size="sm" asChild>
                          <a
                            href={`${process.env.REACT_APP_BACKEND_URL}/${doc.path}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Visualizza"
                          >
                            <FaEye />
                          </a>
                        </Button>
                        {editingDocument === doc ? (
                          <>
                            <Button variant="ghost" size="sm" onClick={handleEditSave} title="Salva">
                              <FaCheck />
                            </Button>
                            <Button variant="ghost" size="sm" onClick={() => setEditingDocument(null)} title="Annulla">
                              <FaTimes />
                            </Button>
                          </>
                        ) : (
                          <Button variant="ghost" size="sm" onClick={() => handleEditClick(doc)} title="Modifica">
                            <FaEdit />
                          </Button>
                        )}
                        <Button variant="ghost" size="sm" onClick={() => handleDeleteClick(doc)} title="Elimina">
                          <FaTrash className='text-red-600' />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <p className="text-gray-500 italic">Nessun documento caricato.</p>
          )}
        </CardContent>
      </Card>

      <Dialog open={deleteModalOpen} onOpenChange={setDeleteModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Conferma eliminazione</DialogTitle>
            <DialogDescription>
              Sei sicuro di voler eliminare il documento "{documentToDelete?.name}"?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setDeleteModalOpen(false)}>
              Annulla
            </Button>
            <Button variant="destructive" onClick={handleDeleteConfirm}>
              Elimina
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default TableComponent;