import { createJsonTypeInstance, createMultipartInstance } from './index';
const axios = createJsonTypeInstance();


 export const updateEmail = async (id, updateData) => {
    try {
      const response = await axios.put(`/user/email/${id}`, updateData);
      return response;
    } catch (error) {
      throw error.response;
    }
  };

  export const updateUsername = async (id, updateData) => {
    try {
      const response = await axios.put(`/user/username/${id}`, updateData);
      return response;
    } catch (error) {
      throw error.response;
    }
  };

  export const updateDob = async (id, updateData) => {
    try {
      const response = await axios.put(`/user/dob/${id}`, updateData);
      return response;
    } catch (error) {
      throw error.response;
    }
  };

  export const updatePassword = async (id, updateData) => {
    try {
      const response = await axios.put(`/user/password/${id}`, updateData);
      return response;
    } catch (error) {
      throw error.response;
    }
  };

  export const deleteAccount = async (id, updateData) => {
    try {
      const response = await axios.put(`/user/delaccount/${id}`, updateData);
      return response;
    } catch (error) {
      throw error.response;
    }
  };

  