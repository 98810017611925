import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateUsername } from '../../../../apis/ProfilioEdit';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter,  } from "../../../ui/dialog";
import { Button } from "../../../ui/button";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";

function EditUsernameModal({ isOpen, onClose, id, currentUsername, onSuccess, description }) {
  const [username, setUsername] = useState(currentUsername || '');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
      setUsername(currentUsername || '');
  }, [currentUsername]);

  const handleSave = async () => {
      if (!id) {
          setError('User ID not found');
          return;
      }

      if (!username.trim()) {
          setError('Username è richiesto');
          return;
      }

      setLoading(true);
      setError(null);
      try {
          const response = await updateUsername(id, { username: username.trim() });

          if (response.status === 200) {
              toast.success("Username aggiornato con successo");
              onSuccess(username.trim());
              onClose();
          }
      } catch (error) {
          console.error('Update error:', error);
          setError(error.response?.data?.error || "Errore nell'aggiornamento dell'username");
          toast.error("Errore nell'aggiornamento dell'username");
      } finally {
          setLoading(false);
      }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Modifica Username</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-1 items-center gap-4">
            <Label htmlFor="username" className="text-left">
              Nuovo Username
            </Label>
            <Input
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="col-span-3"
              placeholder="Inserisci il nuovo username"
            />
          </div>
          {error && (
            <p className="text-red-500 text-sm">{error}</p>
          )}
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Annulla
          </Button>
          <Button onClick={handleSave} disabled={loading}>
            {loading ? "Salvataggio..." : "Salva"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default EditUsernameModal;