import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { formatWorkingHours } from "../../../../../config/helper";
import { format, parse } from "date-fns";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 2,
  },
  header: {
    marginBottom: 10,
    fontSize: 10,
    textAlign: "center",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    marginBottom: 5,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    padding: 5,
    flex: 1,
  },
  tableCell: {
    fontSize: 8,
    padding: 1,
    textAlign: "center",
  },
  present: {
    color: "green",
  },
  absent: {
    color: "red",
  },
});

const AttendanceDocument = ({ attendanceData, selectedMonth, daysInMonth }) => {
  const getAttendanceForDate = (employee, day) => {
    const targetDate = format(
      new Date(
        selectedMonth.getFullYear(),
        selectedMonth.getMonth(),
        day
      ),
      "dd-MM-yyyy"
    );

    return employee.attendance.find(att => att.date === targetDate);
  };

  return (
    <Document>
      <Page size="A3" orientation="landscape" style={styles.page}>
        <Text style={styles.header}>
          Attendance Sheet -{" "}
          {selectedMonth.toLocaleString("default", { month: "long" })}{" "}
          {selectedMonth.getFullYear()}
        </Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: "10%" }]}>
              <Text style={styles.tableCell}>Name</Text>
            </View>
            {Array.from({ length: daysInMonth }, (_, i) => (
              <View key={i} style={[styles.tableCol, { width: "2%" }]}>
                <Text style={styles.tableCell}>{i + 1}</Text>
              </View>
            ))}
          </View>
          {attendanceData?.map((employee) => (
            <View key={employee._id} style={styles.tableRow}>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>{employee.firstName}</Text>
              </View>
              {Array.from({ length: daysInMonth }, (_, i) => {
                const attendanceForDay = getAttendanceForDate(employee, i + 1);
                const plan = attendanceForDay?.planId ? "P" : "";
                
                return (
                  <View key={i} style={[styles.tableCol, { width: "2%" }]}>
                    <Text style={[styles.tableCell]}>
                      {attendanceForDay 
                        ? `${plan}\n${formatWorkingHours(attendanceForDay.workingHours)}`
                        : ""}
                    </Text>
                  </View>
                );
              })}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default AttendanceDocument;