import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import PreventiviComponent from '../../../components/admin/preventivi/index'

const PreventiviDetails = () => {
  return (
    <Layout>
      <PreventiviComponent />
    </Layout>
  )
}

export default PreventiviDetails