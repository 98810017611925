import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import SettingsComponent from '../../../components/admin/impostazioni/turnidilavoro'

const SettingsDetails = () => {
  return (
    <Layout>
      <SettingsComponent />
    </Layout>
  )
}

export default SettingsDetails