import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchCurrencies, fetchSupplier, fetchClients, addDdt, fetchCantiere, fetchUm } from '../../../apis/DdtAdd';
import { toast } from "react-toastify";

import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { RadioGroup, RadioGroupItem } from "../../ui/radio-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { Textarea } from "../../ui/textarea";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";

function DetailComponent() {
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user?.companyId;
  const [currencies, setCurrencies] = useState([]);
  const [um, setUm] = useState([]);
  const [cantiere, setCantiere] = useState([]);
  const [clients, setClients] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [isClient, setIsClient] = useState(true);
  const [formData, setFormData] = useState({
    clienti_id: '',
    fornitore_id: '',
    data: '',
    numeroddt: '',
    valuta: '',
    cantiere: '',
    companyId: companyId,
    articoli: [
      {
        codice: '',
        nome: '',
        descrizione: '',
        um: '',
        qita: '',
        iva: '22',
        sconto: '',
        importonetto: '',
        importo: ''
      }
    ],
    prodottival: '',
    ivaval: '',
    totaleval: ''
  });

  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      try {
        const currencyData = await fetchCurrencies();
        setCurrencies(currencyData.valuta || []);

        const clientsData = await fetchClients(companyId);
        setClients(clientsData?.data?.clients || []);

        const umData = await fetchUm();
        setUm(umData?.um || []);

        const suppliersData = await fetchSupplier(companyId);
        setSuppliers(suppliersData?.suppliers || []);

        const cantiereData = await fetchCantiere(companyId);
        setCantiere(cantiereData?.data?.cantiere || []);
      } catch (error) {
        console.error('Errore nel recupero dei dati!', error);
        toast.error("Errore nel caricamento dei dati. Riprova più tardi.");
      }
    };

    getData();
  }, [companyId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleArticoliChange = (index, e) => {
    const { name, value } = e.target;
    setFormData(prevState => {
      const newArticoli = [...prevState.articoli];
      const articolo = { ...newArticoli[index], [name]: value };

      // Base calculations
      const quantity = parseFloat(articolo.qita) || 0;
      const unitPrice = parseFloat(articolo.importonetto) || 0;
      const discount = parseFloat(articolo.sconto) || 0;
      const iva = parseFloat(articolo.iva) || 0;

      // Base amount
      const baseAmount = quantity * unitPrice;
      const discountAmount = (baseAmount * discount) / 100;
      const amountAfterDiscount = baseAmount - discountAmount;

      // Calculate VAT amount directly
      const ivaAmount = (amountAfterDiscount * iva) / 100;
      const totalAmount = amountAfterDiscount + ivaAmount;

      articolo.importo = totalAmount.toFixed(2);
      newArticoli[index] = articolo;

      // Calculate totals
      const totals = newArticoli.reduce((acc, curr) => {
        const baseAmount = parseFloat(curr.qita || 0) * parseFloat(curr.importonetto || 0);
        const discount = (baseAmount * parseFloat(curr.sconto || 0)) / 100;
        const amountAfterDiscount = baseAmount - discount;
        const ivaAmount = (amountAfterDiscount * parseFloat(curr.iva || 0)) / 100;

        return {
          prodottival: acc.prodottival + amountAfterDiscount,
          ivaval: acc.ivaval + ivaAmount
        };
      }, { prodottival: 0, ivaval: 0 });

      return {
        ...prevState,
        articoli: newArticoli,
        prodottival: totals.prodottival.toFixed(2),
        ivaval: totals.ivaval.toFixed(2),
        totaleval: (totals.prodottival + totals.ivaval).toFixed(2)
      };
    });
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await addDdt(formData);

      if (response.status === 200) {
        toast.success("Dati salvati con successo");
        navigate(-1);
      }
    } catch (error) {
      console.error('Errore nell\'invio del modulo!', error);
      toast.error("Errore nell'invio del modulo!");
    }
  };

  const handleDuplicate = (index) => {
    setFormData(prevState => ({
      ...prevState,
      articoli: [...prevState.articoli, { ...prevState.articoli[index] }],
    }));
  };

  const handleDelete = (indexToDelete) => {
    if (formData.articoli.length <= 1) return; // Prevent deletion if only one item remains
    setFormData(prev => ({
      ...prev,
      articoli: prev.articoli.filter((_, index) => index !== indexToDelete)
    }));
  };

  return (
    <form className="space-y-8 p-6" onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card className="bg-white">
          <CardHeader>
            <CardTitle>Dati Cliente / Fornitore</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <RadioGroup defaultValue={isClient ? "client" : "supplier"} onValueChange={(value) => setIsClient(value === "client")}>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="client" id="clientRadio" />
                <Label htmlFor="clientRadio">Cliente</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="supplier" id="fornitoreRadio" />
                <Label htmlFor="fornitoreRadio">Fornitore</Label>
              </div>
            </RadioGroup>

            {isClient ? (
              <Select name="clienti" value={formData.clienti} onValueChange={(value) => handleChange({ target: { name: 'clienti', value } })} required>
              <SelectTrigger>
                <SelectValue placeholder="Scegli Cliente" />
              </SelectTrigger>
              <SelectContent>
                {clients.map(client => (
                  <SelectItem key={client._id} value={client._id}>
                    {client.typology === 'Private' ? client.fullName : client.companyName}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            ) : (
              <Select name="fornitore_id" value={formData.fornitore_id} onValueChange={(value) => handleChange({ target: { name: 'fornitore_id', value } })}>
                <SelectTrigger>
                  <SelectValue placeholder="Scegli Fornitore" />
                </SelectTrigger>
                <SelectContent>
                  {suppliers.map(supplier => (
                    <SelectItem key={supplier._id} value={supplier._id}>{supplier.companyName}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}

            <div className="space-y-2">
              <Label>Ragione Sociale</Label>
              <span className="block">{/* Add logic to display company name */}</span>
            </div>
            <div className="space-y-2">
              <Label>Indirizzo</Label>
              <span className="block">{/* Add logic to display address */}</span>
            </div>
            <div className="space-y-2">
              <Label>Città</Label>
              <span className="block">{/* Add logic to display city */}</span>
            </div>
            <div className="space-y-2">
              <Label>CAP</Label>
              <span className="block">{/* Add logic to display ZIP code */}</span>
            </div>
            <div className="space-y-2">
              <Label>Provincia</Label>
              <span className="block">{/* Add logic to display province */}</span>
            </div>
            <div className="space-y-2">
              <Label>Partita Iva</Label>
              <span className="block">{/* Add logic to display VAT number */}</span>
            </div>
            <div className="space-y-2">
              <Label>Codice Fiscale</Label>
              <span className="block">{/* Add logic to display fiscal code */}</span>
            </div>
          </CardContent>
        </Card>

        <Card className="bg-white">
          <CardHeader>
            <CardTitle>Dati del documento</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="data">Data</Label>
              <Input type="date" id="data" name="data" value={formData.data} onChange={handleChange} required />
            </div>

            <Input
              type="number"
              id="numeroddt"
              name="numeroddt"
              value={formData.numeroddt}
              readOnly
              disabled
              className="bg-gray-100"
            />

            <div className="space-y-2">
              <Label htmlFor="valuta">Valuta</Label>
              <Select name="valuta" value={formData.valuta} onValueChange={(value) => handleChange({ target: { name: 'valuta', value } })}>
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona Valuta" />
                </SelectTrigger>
                <SelectContent>
                  {currencies.map(currency => (
                    <SelectItem key={currency.valuta} value={currency.valuta}>{currency.valuta}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label htmlFor="cantiere">Cantiere</Label>
              <Select name="cantiere" value={formData.cantiere} onValueChange={(value) => handleChange({ target: { name: 'cantiere', value } })}>
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona Cantiere" />
                </SelectTrigger>
                <SelectContent>
                  {cantiere.map(cantiere => (
                    <SelectItem key={cantiere._id} value={cantiere._id}>{cantiere.name}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </CardContent>
        </Card>

        {/*<Card>
          <CardHeader>
            <CardTitle>Allegato</CardTitle>
          </CardHeader>
          <CardContent>
            <Input type="file" />
          </CardContent>
        </Card>*/}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 items-start">
        <Card className="md:col-span-2 bg-white">
          <CardHeader>
            <CardTitle>Lista degli articoli</CardTitle>
          </CardHeader>
          <CardContent>
            {formData.articoli.map((articolo, index) => (
              <div key={index} className="mb-6 pb-6 border-b">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div className="space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <Label htmlFor={`codice-${index}`}>Codice</Label>
                        <Input id={`codice-${index}`} name="codice" value={articolo.codice} onChange={(e) => handleArticoliChange(index, e)} />
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor={`nome-${index}`}>Nome</Label>
                        <Input id={`nome-${index}`} name="nome" value={articolo.nome} onChange={(e) => handleArticoliChange(index, e)} />
                      </div>
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor={`descrizione-${index}`}>Descrizione</Label>
                      <Textarea id={`descrizione-${index}`} name="descrizione" value={articolo.descrizione} onChange={(e) => handleArticoliChange(index, e)} />
                    </div>
                  </div>

                  <div className="space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <Label htmlFor={`um-${index}`}>U.M.</Label>
                        <Select name="um" value={articolo.um} onValueChange={(value) => handleArticoliChange(index, { target: { name: 'um', value } })}>
                          <SelectTrigger>
                            <SelectValue placeholder="Seleziona U.M." />
                          </SelectTrigger>
                          <SelectContent>
                            {um.map(um => (
                              <SelectItem key={um.unit} value={um.unit}>{um.unit}</SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor={`qita-${index}`}>Q.ità</Label>
                        <Input id={`qita-${index}`} name="qita" value={articolo.qita} onChange={(e) => handleArticoliChange(index, e)} />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <Label htmlFor={`iva-${index}`}>IVA</Label>
                        <Select name="iva" value={articolo.iva} onValueChange={(value) => handleArticoliChange(index, { target: { name: 'iva', value } })}>
                          <SelectTrigger>
                            <SelectValue placeholder="Seleziona IVA" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="22">22%</SelectItem>
                            <SelectItem value="21">21%</SelectItem>
                            <SelectItem value="20">20%</SelectItem>
                            <SelectItem value="10">10%</SelectItem>
                            <SelectItem value="4">4%</SelectItem>
                            <SelectItem value="0">0%</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor={`sconto-${index}`}>Sconto</Label>
                        <Input id={`sconto-${index}`} name="sconto" value={articolo.sconto} onChange={(e) => handleArticoliChange(index, e)} />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <Label htmlFor={`importonetto-${index}`}>Importo netto</Label>
                        <Input id={`importonetto-${index}`} name="importonetto" value={articolo.importonetto} onChange={(e) => handleArticoliChange(index, e)} />
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor={`importo-${index}`}>Importo</Label>
                        <Input id={`importo-${index}`} name="importo" value={articolo.importo} onChange={(e) => handleArticoliChange(index, e)} readOnly />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end space-x-2">
                  {formData.articoli.length > 1 && (
                    <Button
                      type="button"
                      onClick={() => handleDelete(index)}
                      variant="destructive"
                    >
                      Elimina
                    </Button>
                  )}
                  <Button
                    type="button"
                    onClick={() => handleDuplicate(index)}
                  >
                    Aggiungi
                  </Button>
                </div>
              </div>
            ))}
          </CardContent>
        </Card>

        <Card className="bg-white">
          <CardHeader>
            <CardTitle>Riepilogo</CardTitle>
          </CardHeader>
          <CardContent className="space-y-2">
            <div className="flex justify-between">
              <span>Prodotti e/o Servizi</span>
              <span className="text-xl">€ {formData.prodottival || '0'}</span>
            </div>
            <div className="flex justify-between">
              <span>IVA</span>
              <span className="text-xl">€ {formData.ivaval || '0'}</span>
            </div>
            <div className="flex justify-between">
              <span className="font-bold">Totale</span>
              <span className="text-xl font-bold">€ {formData.totaleval || '0'}</span>
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="flex justify-end">
        <input type="hidden" id="ProdottiSpaninput" name="prodottival" value={formData.prodottival} />
        <input type="hidden" id="ivaSpaninput" name="ivaval" value={formData.ivaval} />
        <input type="hidden" id="totaleSpaninput" name="totaleval" value={formData.totaleval} />
        <Button type="submit">Salva</Button>
      </div>
    </form>
  );
}

export default DetailComponent;