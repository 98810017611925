import React from 'react';
import { useIubenda } from '../../../hooks/useIubenda';

export const PolicyLink = ({ href, title }) => {
  useIubenda();
  
  return (
    <a
      href={href}
      className="iubenda-white iubenda-noiframe iubenda-embed hover:underline text-nowrap"
      title={title}
      rel="noreferrer"
    >
      {title}
    </a>
  );
};