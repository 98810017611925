export const employeesList = [
  { value: "emp1", label: "Employee 1" },
  { value: "emp2", label: "Employee 2" },
  { value: "emp3", label: "Employee 3" },
  { value: "emp4", label: "Employee 4" },
  { value: "emp5", label: "Employee 5" },
  { value: "emp6", label: "Employee 6" },
];
export const designationsList = ["CEO", "CTO", "CCEO", "Manager", "CFO", "HR"];

export const levels = [
  { value: "1", label: "Level 1" },
  { value: "2", label: "Level 2" },
  { value: "3", label: "Level 3" },
  { value: "4", label: "Level 4" },
  { value: "5", label: "Level 5" },
  { value: "6", label: "Level 6" },
  { value: "7", label: "Level 7" },
];

export const levelColors = {
  1: "bg-blue-200",
  2: "bg-yellow-300",
  3: "bg-blue-500",
  4: "bg-green-400",
  5: "bg-red-700",
  6: "bg-slat-500",
  7: "bg-lime-500",
};

export const ROOT_NODE = {
  name: "CEO",
  designation: "CEO",
  level: 1,
  employees: [],
};
