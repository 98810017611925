import React from 'react'
import { FaInstagram, FaFacebookSquare } from "react-icons/fa";
import { FaSquareTwitter } from "react-icons/fa6";
import logo from "../../assest/logo1.png"

const Content = ({contentData}) => {
  return (
    <div
      className="h-full bg-gradient-to-r from-blue-950 to-blue-800 relative"
    >
      <div 
        className="absolute inset-0 bg-cover bg-center bg-no-repeat opacity-90"
        style={{
          backgroundImage: `url(${contentData.image})`,
        }}
      ></div>
      <div className="relative h-full w-full bg-[rgba(23,24,40,0.7)] flex flex-col justify-between text-white p-8">
        <div className="mt-5"><img src={logo} className="w-[230px]" alt="Logo" /></div>
        <div className="space-y-5 my-8">
          <h1 className="text-3xl font-bold text-[from-blue-950] w-[80%]">
            {contentData.title}
          </h1>
          <p>
            {contentData.description}
          </p>
        </div>
        <div className="flex gap-3">
          <FaInstagram className="size-9" />
          <FaFacebookSquare className="size-9" />
          <FaSquareTwitter className="size-9" />
        </div>
      </div>
    </div>
  )
}

export default Content