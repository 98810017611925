import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import Preventivi_addComponent from '../../../components/admin/preventivi/preventivi_add'

const Preventivi_addDetails = () => {
  return (
    <Layout>
      <Preventivi_addComponent />
    </Layout>
  )
}

export default Preventivi_addDetails