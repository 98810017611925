import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import ChatComponent from '../../../components/admin/chat/chat'

const ChatDetails = () => {
  return (
    <Layout>
      <ChatComponent />
    </Layout>
  )
}

export default ChatDetails