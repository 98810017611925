import { createJsonTypeInstance, createMultipartInstance } from './index';
const axios = createJsonTypeInstance();

export const fetchPreventivi = async (id) => {
  try {
    const response = await axios.get(`/preventivi/fetchById/${id}`);
    console.log('Preventivi by is frontend:', response);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const fetchCurrencies = async () => {
  try {
    const response = await axios.get('/valuta');
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const fetchUm = async () => {
  try {
    const response = await axios.get('/um');
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const fetchPreventiviInvoiceById = async (token) => {
  try {
    const response = await axios.get(`/preventivi_invoice/${token}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};


export const updatePreventivi = async (id, updateData) => {
  try {
    const response = await axios.put(`/preventivi/${id}`, updateData);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const updatePreventiviInvoice = async (token, updateData) => {
  try {
    const response = await axios.put(`/preventivi_invoice/token/${token}`, updateData);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const fetchClients = async (companyId) => {
  try {
    const response = await axios.get(`/client/all-Clients/${companyId}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};


export const fetchCompany = async (companyId) => {
  try {
    const response = await axios.get(`/company/${companyId}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};

