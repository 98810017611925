import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import ContabilitaComponent from '../../../components/admin/cantieri/contabilita'

const ContabilitaDetails = () => {
  return (
    <Layout>
      <ContabilitaComponent />
    </Layout>
  )
}

export default ContabilitaDetails