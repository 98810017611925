import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import EmployeeComponent from '../../../components/admin/personalData/employees'

const Employees = () => {
  return (
    <Layout>
      <EmployeeComponent />
    </Layout>
  )
}

export default Employees