import { createJsonTypeInstance, createMultipartInstance } from "./index";
const axios = createJsonTypeInstance();

export const postDocument = async (data) => {
  try {
    console.log("Sending document data:", data);
    const axiosInstance = createMultipartInstance();
    const uploadData = new FormData();

    Object.keys(data).forEach((key) => {
      if (key === "file" && data[key] instanceof File) {
        uploadData.append(key, data[key]);
      } else {
        uploadData.append(key, data[key]);
      }
    });

    console.log("FormData contents:");
    for (let pair of uploadData.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }

    const response = await axiosInstance.post("/document", uploadData);
    console.log("Document creation response:", response);
    return response;
  } catch (error) {
    console.error("Error in postDocument:", error);
    console.error("Error response:", error.response);
    throw error.response || error;
  }
};

export const fetchDocuments = async (
  companyId,
  currentPage = 1,
  limit = 10,
  searchQuery = ""
) => {
  try {
    const response = await axios.get(
      `/document/all-document/${companyId}`,
      {
        params: {
          page: currentPage,
          limit,
          search: searchQuery
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching documents:", error);
    throw error.response ? error.response.data : new Error("An unexpected error occurred");
  }
};

export const fetchDocument = async (id) => {
  try {
    const response = await axios.get(`/document/${id}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const updateDocument = async (id, data) => {
  try {
    const axiosInstance = createMultipartInstance();
    const updateData = new FormData();

    Object.keys(data).forEach((key) => {
      if (key === "file" && data[key]) {
        updateData.append(key, data[key][0]);
      } else {
        updateData.append(key, data[key]);
      }
    });
    const response = await axiosInstance.put(`/document/${id}`, updateData);
    return response;
  } catch (error) {
    throw error.response;
  }
};
export const deleteDocument = async (id) => {
  try {
    const response = await axios.delete(`/document/${id}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};
