import * as yup from "yup";

export const loginSchema = yup
  .object({
    email: yup
      .string()
      .required("Email is required*")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/,
        "Enter a valid email address"
      ),
    password: yup
      .string()
      .required("Password is required*")
      .min(8, "Password must be at least 8 characters long"),
  })
  .required();


  export const signupSchema = yup
  .object({
    username: yup
      .string()
      .matches(/^[a-zA-Z ]*$/, "username non valido")
      .required("Username is required*")
      .min(3, "La username  deve contenere almeno 3 caratteri")
      .max(30, "Username cannot exceed 30 characters"),
      companyName: yup
      .string()
      .matches(/^[a-zA-Z ]*$/, "Invalid Company Name")
      .required("Company Name is required*")
      .min(3, "La ragione sociale deve contenere almeno 3 caratteri")
      .max(30, "Company Name  cannot exceed 30 characters"),
    email: yup
      .string()
      .required("Email is required*")
      .email("Indirizoo email non valido")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/,
        "Indirizoo email non valido"
      ),
    password: yup
      .string()
      .required("Password is required*")
      .min(8, "La password deve contenere almeno 8 caratteri"),
    confirm_password: yup
      .string()
      .required("Password is required*")
      .oneOf([yup.ref("password"), null], "Le password inserite non corrispondono"),
  })
  .required();


  export const resetPasswordSchema = yup
  .object({
   
    password: yup
      .string()
      .required("Password is required*")
      .min(8, "Password must be at least 8 characters long"),
    confirm_password: yup
      .string()
      .required("Password is required*")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();


