import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./screens/auth/Login";
import Register from "./screens/auth/Register";
import ForgetPassword from "./screens/auth/ForgetPassword";
import RequestSuccess from "./screens/auth/RequestSuccess";
import DashBoardScreen from "./screens/admin/Dashboard";
import CreateNewPassword from "./screens/auth/CreateNewPassword";
// import Information from "./screens/admin/agency/Information";
import Computimetric from "./screens/admin/computi-metrici/Computimetric";
import Profilio from "./screens/admin/profilio/Profilio";
import Utenti from "./screens/admin/utenti/Utenti";
import Flotta from "./screens/admin/flotta/Flotta";
import FlottaDetails from "./screens/admin/flotta/FlottaDetails";
import Cantieri from "./screens/admin/cantieri/Cantieri";
import Contabilita from "./screens/admin/cantieri/Contabilita";
import Documenti from "./screens/admin/cantieri/Documenti";
import Foto from "./screens/admin/cantieri/Foto";
import Informazioni from "./screens/admin/cantieri/Informazioni";
import Accounting from "./screens/admin/cantieri/Accounting";
import Produzione from "./screens/admin/cantieri/Produzione";
import Rapportini from "./screens/admin/cantieri/Rapportini";
import Ddt from "./screens/admin/ddt/Ddt";
import Ddt_add from "./screens/admin/ddt/Ddt_add";
import Ddt_edit from "./screens/admin/ddt/Ddt_edit";
import Preventivi from "./screens/admin/preventivi/Preventivi";
import Preventivi_add from "./screens/admin/preventivi/Preventivi_add";
import Preventivi_edit from "./screens/admin/preventivi/Preventivi_edit";
import Status_preventivi from "./screens/admin/preventivi/Status_preventivi";
import Magazzino from "./screens/admin/magazzino/Magazzino";
import Movimenti from "./screens/admin/movimenti/Movimenti";
import Luoghi_di_deposito from "./screens/admin/magazzino/Luoghi_di_deposito";
import Computrimetriciaddrec from "./screens/admin/computi-metrici/Compumetriciaddrec";
import EditInformation from "./screens/admin/agency/EditInformation";
import Calender from "./screens/admin/calender";
import Employees from "./screens/admin/personalData/Employees";
import AddEmployee from "./screens/admin/personalData/AddEmployee";
import ProtectedRoute from "./potectedRoutes";
import Agency from "./screens/admin/agency";
import Chat from "./screens/admin/chat/Chat";
import Assistenza from "./screens/admin/assistenza/Assistenza";
import Impostazioni from "./screens/admin/impostazioni/Impostazioni";
import ClientScreen from "./screens/admin/personalData/Client";
import NewClient from "./screens/admin/personalData/NewClient";
import SupplierScreen from "./screens/admin/personalData/Supplier"
import AddSupplier from "./screens/admin/personalData/addSupplier";
import Prezzari from "./screens/admin/prezzari_regioni/Prezzari_regioni";
import Capitoli from "./screens/admin/prezzari_regioni/Capitoli";
import Categoria from "./screens/admin/prezzari_regioni/Categoria";
import Description from "./screens/admin/prezzari_regioni/Description";
import Famiglia from "./screens/admin/prezzari_regioni/Famiglia";
import Regioni from "./screens/admin/prezzari_regioni/Regioni";
import ConstructionSiteLayout from "./components/admin/cantieri/ConstructionSiteLayout";
import SubscriptionPage from "./screens/auth/SubscriptionPage";
import SubscriptionSuccessPage from "./screens/auth/SubscriptionSuccess";
import SettingsPage from "./screens/admin/impostazioni/Settings"
import { SubscriptionProvider } from "./screens/auth/SubscriptionContext";
import SubscriptionProtectedRoute from "./screens/auth/SubscriptionProtectedRoute";
import LandingPage from "./screens/LandingPage";
import ComingSoonWrapper from './components/admin/sharedComponent/ComingSoonWrapper';
import RegisterSubscription from "./screens/RegisterSubscription";
import InvitedUserRegistration from "./screens/auth/InviteRegister";
import Notifications from "./screens/admin/notifications/Notification";
import CookieBanner from "./components/admin/sharedComponent/CookieBanner";

const AdminOnlyRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  if (user.isInvitedUser || user.role !== 'admin') {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

function App() {
  return (
    <SubscriptionProvider>
      <BrowserRouter>
        <CookieBanner />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="Forget-Password" element={<ForgetPassword />} />
          <Route path="Forget-Password-Request" element={<RequestSuccess />} />
          <Route path="create-password" element={<CreateNewPassword />} />
          <Route path="/invite-register" element={<InvitedUserRegistration />} />


          <Route
            path="dashboard"
            element={
              <ProtectedRoute>
                <DashBoardScreen />
              </ProtectedRoute>
            }
          />

          <Route
            path="/"
            element={
              <LandingPage />
            }
          />

          <Route
            path="calendar"
            element={
              <ProtectedRoute>
                <Calender />
              </ProtectedRoute>
            }
          />

          <Route
            path="agency/:tab"
            element={
              <ProtectedRoute>
                <Agency />
              </ProtectedRoute>
            }
          />

          <Route
            path="agency/edit-information/:id"
            element={
              <ProtectedRoute>
                <EditInformation />
              </ProtectedRoute>
            }
          />

          <Route
            path="employees/edit/:id"
            element={
              <ProtectedRoute>
                <AddEmployee />
              </ProtectedRoute>
            }
          />
          <Route
            path="employees/:tab"
            element={
              <ProtectedRoute>
                <Employees />
              </ProtectedRoute>
            }
          />
          <Route
            path="employees/add"
            element={
              <ProtectedRoute>
                <AddEmployee />
              </ProtectedRoute>
            }
          />

          <Route
            path="/clients"
            element={
              <ProtectedRoute>
                <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                  <ClientScreen />
                </SubscriptionProtectedRoute>
              </ProtectedRoute>
            }
          />

          <Route
            path="/clients/add"
            element={
              <ProtectedRoute>
                <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                  <NewClient />
                </SubscriptionProtectedRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="/clients/edit"
            element={
              <ProtectedRoute>
                <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                  <NewClient />
                </SubscriptionProtectedRoute>
              </ProtectedRoute>
            }
          />

          <Route
            path="/suppliers"
            element={
              <ProtectedRoute>
                <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                  <SupplierScreen />
                </SubscriptionProtectedRoute>
              </ProtectedRoute>
            }
          />

          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <SettingsPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/supplier/add"
            element={
              <ProtectedRoute>
                <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                  <AddSupplier />
                </SubscriptionProtectedRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="/supplier/edit"
            element={
              <ProtectedRoute>
                <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                  <AddSupplier />
                </SubscriptionProtectedRoute>
              </ProtectedRoute>
            }
          />

          <Route
            path="/subscription"
            element={
              <ProtectedRoute>
                <AdminOnlyRoute>
                  <SubscriptionPage />
                </AdminOnlyRoute>
              </ProtectedRoute>
            }
          />

          <Route
            path="/billing/:username"
            element={
                <RegisterSubscription />
            }
          />

          <Route
            path="/subscription/success"
            element={
              <ProtectedRoute>
                <AdminOnlyRoute>
                  <SubscriptionSuccessPage />
                </AdminOnlyRoute>
              </ProtectedRoute>
            }
          />

          <Route path="computi-metrici" element={
            <ProtectedRoute>
              <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                {/* <ComingSoonWrapper> */}
                <Computimetric />
                {/* </ComingSoonWrapper> */}
              </SubscriptionProtectedRoute>
            </ProtectedRoute>
          } />
          <Route path="computrimetriciaddrec/:id" element={
            <ProtectedRoute>
              <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                {/* <ComingSoonWrapper> */}
                <Computrimetriciaddrec />
                {/* </ComingSoonWrapper> */}
              </SubscriptionProtectedRoute>
            </ProtectedRoute>
          } />

          <Route path="flotta" element={
            <ProtectedRoute>
              <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                <Flotta />
              </SubscriptionProtectedRoute>
            </ProtectedRoute>
          } />
          <Route path="flotta_details/:id" element={
            <ProtectedRoute>
              <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                <FlottaDetails />
              </SubscriptionProtectedRoute>
            </ProtectedRoute>
          } />

          <Route path="profilio" element={<ProtectedRoute><Profilio /></ProtectedRoute>} />

          <Route path="utenti" element={<ProtectedRoute>
            {/* <ComingSoonWrapper> */}
            <Utenti />
            {/* </ComingSoonWrapper> */}
          </ProtectedRoute>} />

          <Route path="magazzino" element={
            <ProtectedRoute>
              <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                <Magazzino />
              </SubscriptionProtectedRoute>
            </ProtectedRoute>
          } />
          <Route path="luoghi_di_deposito" element={
            <ProtectedRoute>
              <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                <Luoghi_di_deposito />
              </SubscriptionProtectedRoute>
            </ProtectedRoute>
          } />

          <Route path="movimenti" element={
            <ProtectedRoute>
              <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                <Movimenti />
              </SubscriptionProtectedRoute>
            </ProtectedRoute>
          } />

          <Route path="chat" element={
            <ProtectedRoute>
              <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                <Chat />
              </SubscriptionProtectedRoute>
            </ProtectedRoute>
          } />

          <Route path="assistenza" element={<ProtectedRoute>
            {/* <ComingSoonWrapper> */}
            <Assistenza />
            {/* </ComingSoonWrapper> */}
          </ProtectedRoute>} />

          <Route path="impostazioni" element={<ProtectedRoute><Impostazioni /></ProtectedRoute>} />

          <Route path="notifications" element={<ProtectedRoute><Notifications /></ProtectedRoute>} />

          <Route path="ddt" element={
            <ProtectedRoute>
              <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                <Ddt />
              </SubscriptionProtectedRoute>
            </ProtectedRoute>
          } />
          <Route path="ddt_add" element={
            <ProtectedRoute>
              <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                <Ddt_add />
              </SubscriptionProtectedRoute>
            </ProtectedRoute>
          } />
          <Route path="ddt_edit/:id" element={
            <ProtectedRoute>
              <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                <Ddt_edit />
              </SubscriptionProtectedRoute>
            </ProtectedRoute>
          } />

          <Route path="preventivi" element={<ProtectedRoute><Preventivi /></ProtectedRoute>} />
          <Route path="preventivi_add" element={<ProtectedRoute><Preventivi_add /></ProtectedRoute>} />
          <Route path="preventivi_edit/:id" element={<ProtectedRoute><Preventivi_edit /></ProtectedRoute>} />
          <Route path="status_preventivi" element={<ProtectedRoute><Status_preventivi /></ProtectedRoute>} />

          <Route path="cantieri" element={
            <ProtectedRoute>
              <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                <Cantieri />
              </SubscriptionProtectedRoute>
            </ProtectedRoute>
          } />
          <Route path="cantieri/:id" element={
            <ProtectedRoute>
              <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                <ConstructionSiteLayout />
              </SubscriptionProtectedRoute>
            </ProtectedRoute>
          }>
            <Route path="informazioni" element={<ProtectedRoute><Informazioni /></ProtectedRoute>} />
            <Route path="contabilita" element={<ProtectedRoute><Contabilita /></ProtectedRoute>} />
            <Route path="accounting" element={<ProtectedRoute><Accounting /></ProtectedRoute>} />
            <Route path="documenti" element={
              <ProtectedRoute>
                <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                  <Documenti />
                </SubscriptionProtectedRoute>
              </ProtectedRoute>
            } />
            <Route path="foto" element={
              <ProtectedRoute>
                <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                  <Foto />
                </SubscriptionProtectedRoute>
              </ProtectedRoute>
            } />
            <Route path="produzione" element={<ProtectedRoute><Produzione /></ProtectedRoute>} />
            <Route path="rapportini" element={
              <ProtectedRoute>
                <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                  <Rapportini />
                </SubscriptionProtectedRoute>
              </ProtectedRoute>
            } />
          </Route>
          <Route path="prezzari" element={<ProtectedRoute><Prezzari /></ProtectedRoute>} />
          <Route path="capitoli" element={<ProtectedRoute><Capitoli /></ProtectedRoute>} />
          <Route path="categoria" element={<ProtectedRoute><Categoria /></ProtectedRoute>} />
          <Route path="description" element={<ProtectedRoute><Description /></ProtectedRoute>} />
          <Route path="famiglia" element={<ProtectedRoute><Famiglia /></ProtectedRoute>} />
          <Route path="regioni" element={<ProtectedRoute><Regioni /></ProtectedRoute>} />
        </Routes>
      </BrowserRouter>
    </SubscriptionProvider>
  );
}

export default App;