import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import ComputimetricComponent from '../../../components/admin/dashboard/computi-metrici/index'

const Computimetric = () => {
  return (
    <Layout>
      <ComputimetricComponent />
    </Layout>
  )
}

export default Computimetric