import React from 'react';
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Button } from "./button";

const colors = [
  "#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#FF00FF", "#00FFFF",
  "#800000", "#008000", "#000080", "#808000", "#800080", "#008080",
  "#FFA500", "#FFC0CB", "#800000", "#FF69B4", "#008B8B", "#F0E68C"
];

const ColorPicker = ({ value, onChange }) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button 
          variant="outline" 
          className="w-[80px] h-[35px]"
          style={{ backgroundColor: value }}
        />
      </PopoverTrigger>
      <PopoverContent className="w-[200px]">
        <div className="grid grid-cols-6 gap-2">
          {colors.map((color) => (
            <Button
              key={color}
              className="w-6 h-6 rounded-full p-0"
              style={{ backgroundColor: color }}
              onClick={() => onChange(color)}
            />
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default ColorPicker;