import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Edit, Trash2, ArrowRight, Upload, Mail, Calendar, FileText, CreditCard, Shield, RefreshCw, BellRing, Key, Smartphone, Lock, AlertTriangle, Moon, Sun } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription, CardFooter } from "../../ui/card";
import { Button } from "../../ui/button";
import { Progress } from "../../ui/progress";
import { Avatar, AvatarFallback, AvatarImage } from "../../ui/avatar";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import { Alert, AlertDescription, AlertTitle } from "../../ui/alert";
import { Badge } from "../../ui/badge";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../ui/tooltip";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../ui/dropdown-menu";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../ui/accordion";
import EditEmailModal from './modals/EditEmailModal';
import EditUsernameModal from './modals/EditUsernameModal';
import EditDobModal from './modals/EditDobModal';
import EditPasswordModal from './modals/EditPasswordModal';
import DeleteAccountModal from './modals/DeleteAccountModal';
import { useSubscription } from '../../../screens/auth/SubscriptionContext';


function ProfilePage() {

    const [showEditEmail, setShowEditEmail] = useState(false);
    const [showEditUsername, setShowEditUsername] = useState(false);
    const [showEditDob, setShowEditDob] = useState(false);
    const [showEditPassword, setShowEditPassword] = useState(false);
    const [showDeleteAccount, setShowDeleteAccount] = useState(false);
    const navigate = useNavigate();
    const { subscription } = useSubscription();
    const [userData, setUserData] = useState(() => {
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
            const parsed = JSON.parse(storedUser);
            console.log('Initial user data:', parsed); // Debug log
            return parsed;
        }
        return null;
    });


    // Add effect to update userData if localStorage changes
    useEffect(() => {
        const handleStorageChange = () => {
            const storedUser = localStorage.getItem("user");
            if (storedUser) {
                const parsed = JSON.parse(storedUser);
                setUserData(parsed);
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, []);

    // Handler functions remain the same
    const handleCloseEditUsername = () => setShowEditUsername(false);
    const handleShowEditUsername = () => setShowEditUsername(true);

    const handleCloseEditDob = () => setShowEditDob(false);
    const handleShowEditDob = () => setShowEditDob(true);

    const handleCloseEditEmail = () => setShowEditEmail(false);
    const handleShowEditEmail = () => setShowEditEmail(true);

    const handleCloseEditPassword = () => setShowEditPassword(false);
    const handleShowEditPassword = () => setShowEditPassword(true);

    const handleCloseDeleteAccount = () => setShowDeleteAccount(false);
    const handleShowDeleteAccount = () => setShowDeleteAccount(true);

    const daysUntilExpiry = 263;
    const spaceUsed = 7001;
    const totalSpace = 90 * 1024 * 1024 * 1024; // 90 GB in bytes
    const spaceUsedPercentage = (spaceUsed / totalSpace) * 100;

    // Add description to avoid the warning
    const dialogDescription = "Modifica le tue informazioni personali";


    // Debug log to check user data
    console.log('Current userData:', userData);

    return (
        <div className="mx-auto p-6 space-y-6">
            <Tabs defaultValue="profile" className="w-full">
                <TabsList className="grid w-full grid-cols-3">
                    <TabsTrigger value="profile">Profilo</TabsTrigger>
                    <TabsTrigger value="subscription">Fatturazione</TabsTrigger>
                    <TabsTrigger value="security">Sicurezza</TabsTrigger>
                    {/* <TabsTrigger value="preferences">Preferenze</TabsTrigger> */}
                </TabsList>
                <TabsContent value="profile">
                    <Card className='border-0'>
                        <CardHeader>
                            <CardTitle className="text-2xl font-bold">Profilo Utente</CardTitle>
                            <CardDescription>Gestisci le tue informazioni personali e le preferenze del tuo account.</CardDescription>
                        </CardHeader>
                        <CardContent>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                                <div className="flex flex-col items-center space-y-4">
                                    <Avatar className="w-32 h-32">
                                        <AvatarImage
                                            src="https://cdn.dribbble.com/users/3305260/screenshots/6628643/order-_fo59bc14fe01.jpg"
                                            alt={`${userData?.username} avatar`}
                                        />
                                        <AvatarFallback>{userData?.username?.charAt(0)}</AvatarFallback>
                                    </Avatar>
                                    <DropdownMenu>
                                        <DropdownMenuTrigger asChild>
                                            <Button variant="outline" disabled>
                                                <Upload className="mr-2 h-4 w-4" /> Gestisci Foto
                                            </Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent>
                                            <DropdownMenuItem>Carica nuova foto</DropdownMenuItem>
                                            <DropdownMenuItem>Rimuovi foto</DropdownMenuItem>
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                </div>
                                <div className="md:col-span-2 space-y-4">
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                        <div>
                                            <Label>Nome Completo</Label>
                                            <div className="flex items-center space-x-2">
                                                <Input value={userData?.username || ''} readOnly />
                                                <TooltipProvider>
                                                    <Tooltip>
                                                        <TooltipTrigger asChild>
                                                            <Button
                                                                variant="ghost"
                                                                size="icon"
                                                                onClick={handleShowEditUsername}
                                                                disabled={!userData?.id}
                                                            >
                                                                <Edit className="h-4 w-4" />
                                                            </Button>
                                                        </TooltipTrigger>
                                                        <TooltipContent>
                                                            <p>Modifica nome</p>
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                            </div>
                                        </div>

                                        <div>
                                            <Label>Email</Label>
                                            <div className="flex items-center space-x-2">
                                                <Input value={userData?.email || ''} readOnly />
                                                <TooltipProvider>
                                                    <Tooltip>
                                                        <TooltipTrigger asChild>
                                                            {/* <Button variant="ghost" size="icon" disabled>
                                                                <Mail className="h-4 w-4" />
                                                            </Button> */}
                                                        </TooltipTrigger>
                                                        <TooltipContent>
                                                            <p>Non puoi modificare l'email</p>
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                            </div>
                                        </div>

                                        <div>
                                            <Label>Nome Azienda</Label>
                                            <div className="flex items-center space-x-2">
                                                <Input value={userData?.companyName || ''} readOnly />
                                                <TooltipProvider>
                                                    <Tooltip>
                                                        <TooltipTrigger asChild>
                                                            {/* <Button variant="ghost" size="icon" disabled>
                                                                <Edit className="h-4 w-4" />
                                                            </Button> */}
                                                        </TooltipTrigger>
                                                        <TooltipContent>
                                                            <p>Non puoi modificare il nome azienda</p>
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                            </div>
                                        </div>

                                        <div>
                                            <Label>Data di nascita</Label>
                                            <div className="flex items-center space-x-2">
                                                <Input value={userData?.dob || ''} readOnly />
                                                <Button
                                                    variant="ghost"
                                                    size="icon"
                                                    onClick={handleShowEditDob}
                                                    disabled={!userData?.id}
                                                >
                                                    <Calendar className="h-4 w-4" />
                                                </Button>
                                            </div>
                                        </div>

                                        {/* <div>
                                            <Label>PEC</Label>
                                            <div className="flex items-center space-x-2">
                                                <Input placeholder="Aggiungi PEC" disabled />
                                                <TooltipProvider>
                                                    <Tooltip>
                                                        <TooltipTrigger asChild>
                                                            <Button variant="ghost" size="icon" disabled>
                                                                <FileText className="h-4 w-4" />
                                                            </Button>
                                                        </TooltipTrigger>
                                                        <TooltipContent>
                                                            <p>Funzionalità in arrivo</p>
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                            </div>
                                            <p className="text-sm text-muted-foreground mt-1">Funzionalità in arrivo</p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </TabsContent>
                <TabsContent value="subscription">
                    <Card className='border-0'>
                        <CardHeader>
                            <CardTitle>Dettagli Fatturazione</CardTitle>
                            <CardDescription>Gestisci il tuo piano e lo spazio di archiviazione.</CardDescription>
                        </CardHeader>
                        <CardContent className="space-y-6">
                            {subscription ? (
                                <>
                                    <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-6">
                                        <div className="w-32 h-32 relative">
                                            <Progress value={spaceUsedPercentage} className="w-32 h-32 rounded-full" />
                                            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                                                <div className="text-2xl font-bold">{spaceUsedPercentage.toFixed(1)}%</div>
                                                <div className="text-sm">Utilizzato</div>
                                            </div>
                                        </div>
                                        <div className="space-y-2 flex-1">
                                            <p>Il tuo piano attuale comprende <Badge variant="secondary">90 GB</Badge> di spazio in cloud</p>
                                            <p>Spazio utilizzato: <Badge variant="outline">{(spaceUsed / 1024).toFixed(2)} KB</Badge></p>
                                            <p>Spazio rimanente: <Badge variant="outline">{((totalSpace - spaceUsed) / (1024 * 1024 * 1024)).toFixed(2)} GB</Badge></p>
                                            <Progress value={spaceUsedPercentage} className="w-full" />
                                        </div>
                                    </div>
                                    <Accordion type="single" collapsible>
                                        <AccordionItem value="item-1">
                                            <AccordionTrigger>Dettagli Piano</AccordionTrigger>
                                            <AccordionContent>
                                                <div className="space-y-2">
                                                    <p><strong>Nome Piano:</strong> {subscription.planType}</p>
                                                    <p><strong>Stato:</strong> {subscription.status}</p>
                                                    <p><strong>Prezzo:</strong> €{subscription.price} / {subscription.interval}</p>
                                                    <p><strong>Prossimo rinnovo:</strong> {new Date(subscription.currentPeriodEnd).toLocaleDateString()}</p>
                                                </div>
                                            </AccordionContent>
                                        </AccordionItem>
                                    </Accordion>
                                    {/* <Alert>
                                        <CreditCard className="h-4 w-4" />
                                        <AlertTitle>Informazione sulla Licenza</AlertTitle>
                                        <AlertDescription>
                                            La tua licenza scade tra {daysUntilExpiry} giorni. Considera di rinnovare o fare un upgrade.
                                        </AlertDescription>
                                    </Alert> */}
                                    {/* <div className="flex justify-between items-center">
                                        <Button variant="outline" onClick={() => navigate('/subscription')}>
                                            <RefreshCw className="mr-2 h-4 w-4" /> Cancella Fatturazione
                                        </Button>
                                        <Link to='/subscription'>
                                            <Button>
                                                Upgrade Piano
                                            </Button>
                                        </Link>
                                    </div> */}
                                </>
                            ) : (
                                <div className="text-center space-y-4">
                                    <AlertTriangle className="h-12 w-12 mx-auto text-yellow-500" />
                                    <h3 className="text-xl font-semibold">Nessun Fatturazione Attivo</h3>
                                    <p className='mb-3'>Non hai attualmente un fatturazione attivo. Sottoscrivi un piano per accedere a tutte le funzionalità.</p>
                                    <Link to='/subscription'>
                                        <Button className='mt-3'>
                                            Scegli un Piano
                                        </Button>
                                    </Link>
                                </div>
                            )}
                        </CardContent>
                    </Card>
                </TabsContent>
                <TabsContent value="security">
                    <Card className='border-0'>
                        <CardHeader>
                            <CardTitle>Sicurezza dell'Account</CardTitle>
                            <CardDescription>Gestisci le impostazioni di sicurezza e l'accesso al tuo account.</CardDescription>
                        </CardHeader>
                        <CardContent className="space-y-4">
                            <div className="flex justify-between items-center">
                                <div>
                                    <h4 className="text-lg font-semibold">Password</h4>
                                    <p className="text-sm text-gray-500">Ultima modifica: 3 mesi fa</p>
                                </div>
                                <Button variant="outline" onClick={handleShowEditPassword}>
                                    <Key className="mr-2 h-4 w-4" /> Modifica Password
                                </Button>
                            </div>
                            <div className="flex justify-between items-center">
                                <div>
                                    <h4 className="text-lg font-semibold">Autenticazione a Due Fattori</h4>
                                    <p className="text-sm text-gray-500">Aumenta la sicurezza del tuo account</p>
                                </div>
                                <Button disabled variant='outline'>
                                    Funzionalità in arrivo
                                </Button>
                            </div>
                            <div className="flex justify-between items-center">
                                <div>
                                    <h4 className="text-lg font-semibold">Sessioni Attive</h4>
                                    <p className="text-sm text-gray-500">Gestisci i tuoi accessi attivi</p>
                                </div>
                                <Button disabled variant='outline'>
                                    Funzionalità in arrivo
                                </Button>
                            </div>
                            {/* <Alert variant="destructive">
                                <AlertTriangle className="h-4 w-4" />
                                <AlertTitle>Zona Pericolosa</AlertTitle>
                                <AlertDescription>
                                    L'eliminazione dell'account è un'azione irreversibile. Tutti i tuoi dati verranno persi.
                                </AlertDescription>
                                <Button variant="destructive" className="mt-2" onClick={handleShowDeleteAccount}>
                                    <Trash2 className="mr-2 h-4 w-4" /> Elimina Account
                                </Button>
                            </Alert> */}
                        </CardContent>
                    </Card>
                </TabsContent>

            </Tabs>

            <EditUsernameModal
                isOpen={showEditUsername}
                onClose={handleCloseEditUsername}
                id={userData?.id}
                currentUsername={userData?.username}
                description={dialogDescription}
                onSuccess={(newUsername) => {
                    const updatedUser = { ...userData, username: newUsername };
                    localStorage.setItem("user", JSON.stringify(updatedUser));
                    setUserData(updatedUser);
                }}
            />

            <EditDobModal
                isOpen={showEditDob}
                onClose={handleCloseEditDob}
                id={userData?.id}
                currentDob={userData?.dob}
                description={dialogDescription}
                onSuccess={(newDob) => {
                    const updatedUser = { ...userData, dob: newDob };
                    localStorage.setItem("user", JSON.stringify(updatedUser));
                    setUserData(updatedUser);
                }}
            />

            {/* Remove or disable the email modal since email editing is not allowed */}
            {/* <div className="flex items-center space-x-2">
                <Input value={user?.email} readOnly />
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Button variant="ghost" size="icon" disabled>
                                <Mail className="h-4 w-4" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                            <p>Non puoi modificare l'email</p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            </div> */}
            <EditPasswordModal isOpen={showEditPassword} onClose={handleCloseEditPassword} />
            <DeleteAccountModal isOpen={showDeleteAccount} onClose={handleCloseDeleteAccount} />
        </div>
    );
}

export default ProfilePage;