import React from "react";
import Layout from "../../../components/admin/sharedComponent/Layout";
import AgencyComponent from "../../../components/admin/agency";

const Agency = () => {
  return (
    <Layout>
      <AgencyComponent />
    </Layout>
  );
};

export default Agency;
