import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSubscription } from './SubscriptionContext';
import { Button } from "../../components/ui/button";
import { X } from 'lucide-react';

const SubscriptionProtectedRoute = ({ children, requiredPlans }) => {
  const { subscription, loading } = useSubscription();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading) {
      // Show modal only if there's no subscription
      setShowModal(!subscription);
    }
  }, [loading, subscription]);

  const handleUpgrade = () => {
    setShowModal(false);
    navigate('/subscription');
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const renderModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-4 sm:mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Abbonamento Richiesto</h2>
          <Button variant="ghost" size="icon" className="md:hidden" onClick={handleClose}>
            <X className="h-4 w-4" />
          </Button>
        </div>
        <p className="mb-4">
          Per accedere a questa funzionalità, è necessario un abbonamento. Scegli un piano per continuare.
        </p>
        <div className="flex justify-end">
          <Button onClick={handleUpgrade}>
            Visualizza Piani di Abbonamento
          </Button>
        </div>
      </div>
    </div>
  );

  // If loading, show nothing (or a loading indicator if you prefer)
  if (loading) {
    return null; // or return <LoadingSpinner /> if you have one
  }

  // If there's a subscription (including free), allow access
  if (subscription) {
    return children;
  }

  // If no subscription, show children with modal
  return (
    <>
      {children}
      {showModal && renderModal()}
    </>
  );
};

export default SubscriptionProtectedRoute;