import { Input } from "../../../../ui/input";

const MainData = ({ register }) => (
  <div className="flex flex-col gap-3">
    <div className="flex flex-wrap gap-3">
      <div className="flex-1">
        <label htmlFor="businessName" className="block mb-1 text-sm font-medium text-gray-900">Ragione Sociale</label>
        <Input
          {...register("businessName")}
          id="businessName"
          placeholder="Inserisci la ragione sociale"
          required
        />
      </div>
      <div className="flex-1">
        <label htmlFor="internalCode" className="block mb-1 text-sm font-medium text-gray-900">Codice Interno</label>
        <Input
          {...register("internalCode")}
          id="internalCode"
          placeholder="Inserisci il codice interno"
        />
      </div>
    </div>
    <div className="flex flex-wrap gap-3">
      <div className="flex-1">
        <label htmlFor="vatNumber" className="block mb-1 text-sm font-medium text-gray-900">Partita IVA</label>
        <Input
          {...register("vatNumber")}
          id="vatNumber"
          placeholder="Inserisci la partita IVA"
        />
      </div>
      <div className="flex-1">
        <label htmlFor="taxId" className="block mb-1 text-sm font-medium text-gray-900">Codice Fiscale</label>
        <Input
          {...register("taxId")}
          id="taxId"
          placeholder="Inserisci il codice fiscale"
        />
      </div>
    </div>
  </div>
);

export default MainData;