import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import FlottaComponent from '../../../components/admin/flotta/index'

const Flotta = () => {
  return (
    <Layout>
      <FlottaComponent />
    </Layout>
  )
}

export default Flotta