import React, { useEffect, useState } from 'react';
import { Controller } from "react-hook-form";

const ImageController = ({ name, control, label, rounded, defaultValue }) => {

  const [preview, setPreview] = useState();

  useEffect(() => {
    setPreview(defaultValue)
  }, [defaultValue])
  const handleImageChange = (e, onChange) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
      onChange(file);
    }
  };

  return (
    <div>
      <label htmlFor={`${name}-dropzone`} className="block text-sm font-medium text-gray-900">
        {label}
      </label>
      <label htmlFor={`${name}-dropzone`}>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange } }) => (
            <>
              <div className={`flex justify-center items-center w-[150px] h-[150px] p-3 border-2 border-gray-300 border-dashed ${rounded}`}>
                {preview ? (
                  <div className="w-full h-full flex justify-center items-center">
                    <img src={preview} className={`w-full h-full object-cover ${rounded ? "rounded-full" : ""}`} />
                  </div>
                ) : (
                  <div className="flex flex-col justify-center items-center">
                    <svg
                      className="w-6 h-6 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Carica file</p>
                    <p className="text-[8px] text-gray-400 mt-1 text-center">
                      PNG, JPG, SVG, WEBP, and GIF are allowed.
                    </p>
                  </div>
                )}
              </div>

              <input
                id={`${name}-dropzone`}
                type="file"
                className="hidden "
                accept="image/*"
                onChange={(e) => handleImageChange(e, onChange)}
              />

            </>
          )}
        />
      </label>
    </div>

  );
};

export default ImageController;
