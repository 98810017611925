import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAuthToken, clearAuth } from '../apis/index'; 
import { jwtDecode } from 'jwt-decode';
import Spinner from '../components/Spinner';

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = getAuthToken();
        const userString = localStorage.getItem('user');

        if (!token || !userString) {
          handleAuthFailure();
          return;
        }

        const user = JSON.parse(userString);
        
        // Verify token validity
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;
          
          if (decodedToken.exp < currentTime) {
            handleAuthFailure('La sessione è scaduta. Per favore, accedi di nuovo.');
            return;
          }
        } catch (tokenError) {
          handleAuthFailure('Errore di autenticazione. Per favore, accedi di nuovo.');
          return;
        }

        // Check subscription status based on user role
        if (user.role === 'admin') {
          if (!checkAdminSubscription(user)) {
            handleSubscriptionFailure(user.username);
            return;
          }
        } else if (user.isInvitedUser) {
          if (!checkInvitedUserAccess(user)) {
            handleInvitedUserFailure();
            return;
          }
        }

        setIsChecking(false);
      } catch (error) {
        console.error('Auth check error:', error);
        handleAuthFailure('Si è verificato un errore. Per favore, accedi di nuovo.');
      }
    };

    checkAuth();
  }, [navigate, location]);

  const checkAdminSubscription = (user) => {
    return user.subscription && 
           ['active', 'trialing', 'canceled'].includes(user.subscription.status);
  };

  const checkInvitedUserAccess = (user) => {
    return !!user.adminId;
  };

  const handleAuthFailure = (message = null) => {
    clearAuth();
    
    if (message) {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    navigate('/login', { 
      replace: true,
      state: { from: location.pathname }
    });
  };

  const handleSubscriptionFailure = (username) => {
    toast.error(
      "Non puoi accedere all'applicazione senza un piano attivo. Per favore, sottoscrivi un piano.",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );
    navigate(`/billing/${username}`, { replace: true });
  };

  const handleInvitedUserFailure = () => {
    toast.error(
      "Il tuo account non è correttamente collegato a un amministratore. Per favore, contatta il supporto.",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );
    navigate('/login', { replace: true });
  };

  if (isChecking) {
    return <Spinner />;
  }

  return children;
};

export default ProtectedRoute;