import React, { useState, useEffect } from "react";
import { X } from "lucide-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../../ui/dialog";
import { Button } from "../../../ui/button";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../ui/select";
import { Badge } from "../../../ui/badge";
import { Employees } from "../../../../apis/Employees";

const NodeModal = ({
  isOpen,
  onClose,
  onSave,
  onUpdate,
  onDelete,
  selectedNode,
  designations,
  levels,
  isEditing,
  level,
}) => {
  const [designation, setDesignation] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [name, setName] = useState("");
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (selectedNode) {
      setDesignation(selectedNode.designation || "");
      setSelectedLevel(selectedNode.level?.toString() || "");
      setSelectedEmployees(selectedNode.employees?.map(emp => emp._id || emp) || []);
      setName(selectedNode.name || "");
    } else {
      setDesignation("");
      setSelectedLevel("");
      setSelectedEmployees([]);
      setName("");
    }
  }, [selectedNode]);

  useEffect(() => {
    const fetchEmployees = async () => {
      setLoading(true);
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        const companyId = user.companyId;
        const response = await Employees(companyId, 1, 1000, ''); // Fetch all employees
        setEmployees(response.data.employees.map(emp => ({
          value: emp._id,
          label: `${emp.firstName} ${emp.sureName}`
        })));
      } catch (err) {
        setError("Failed to fetch employees");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployees();
  }, []);

  const handleSave = () => {
    const newNode = {
      parentId: selectedNode?._id,
      name,
      level: selectedLevel,
      designation,
      employees: selectedEmployees,
    };
    if (isEditing) {
      onUpdate(selectedNode, newNode);
    } else {
      onSave(newNode);
    }
  };

  const levelList = !isEditing
    ? level
      ? levels.filter((lev) => parseInt(lev.value) === level + 1)
      : levels
    : level
    ? levels.filter((lev) => parseInt(lev.value) === level)
    : levels;

  const toggleEmployee = (employeeId) => {
    setSelectedEmployees((current) =>
      current.includes(employeeId)
        ? current.filter((id) => id !== employeeId)
        : [...current, employeeId]
    );
  };

  if (loading) return <div>Loading employees...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            {isEditing ? `Edit Node` : `Aggiungi nuovo nodo al Livello ${level}`}
          </DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-1 items-center gap-4">
            <Label htmlFor="name" className="text-left">
              Nome
            </Label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-1 items-center gap-4">
            <Label htmlFor="designation" className="text-left">
              Ruolo
            </Label>
            <Select onValueChange={setDesignation} value={designation}>
              <SelectTrigger className="col-span-3">
                <SelectValue placeholder="Select Designation" />
              </SelectTrigger>
              <SelectContent>
                {designations?.map((desig, index) => (
                  <SelectItem key={index} value={desig || `designation-${index}`}>
                    {desig}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="grid grid-cols-1 items-center gap-4">
            <Label htmlFor="level" className="text-left">
              Livello
            </Label>
            <Select onValueChange={setSelectedLevel} value={selectedLevel}>
              <SelectTrigger className="col-span-3">
                <SelectValue placeholder="Select Level" />
              </SelectTrigger>
              <SelectContent>
                {levelList?.map((lev, index) => (
                  <SelectItem key={index} value={lev.value || `level-${index}`}>
                    {lev.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="grid grid-cols-1 items-center gap-4">
            <Label htmlFor="employees" className="text-left">
              Dipendenti
            </Label>
            <Select
              onValueChange={toggleEmployee}
              value={selectedEmployees}
              multiple
            >
              <SelectTrigger className="col-span-3">
                <div className="flex flex-wrap gap-1">
                  {selectedEmployees.length > 0 ? (
                    selectedEmployees.map((empId) => (
                      <Badge key={empId} variant="secondary" className="mr-1">
                        {employees.find((e) => e.value === empId)?.label || empId}
                        <button
                          className="ml-1 text-xs"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleEmployee(empId);
                          }}
                        >
                          <X size={12} />
                        </button>
                      </Badge>
                    ))
                  ) : (
                    <span className="text-muted-foreground">Select employees</span>
                  )}
                </div>
              </SelectTrigger>
              <SelectContent>
                {employees.map((employee) => (
                  <SelectItem key={employee.value} value={employee.value}>
                    {employee.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
        <DialogFooter>
          {isEditing && (
            <Button variant="destructive" onClick={onDelete}>
              Delete
            </Button>
          )}
          <Button onClick={handleSave}>{isEditing ? "Update" : "Salva"}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default NodeModal;