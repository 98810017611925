import { useEffect, useState } from 'react';

const CookieBanner = () => {
  const [scriptLoadError, setScriptLoadError] = useState(false);
  const [bannerInitialized, setBannerInitialized] = useState(false);

  useEffect(() => {
    // Check if consent is already given via iubenda
    const checkConsent = () => {
      try {
        // Check if _iub.cs.consent exists and has a consent record
        return window._iub?.cs?.consent?.exists || 
               document.cookie.includes('_iub_cs-') ||
               document.cookie.includes('euconsent-v2');
      } catch {
        return false;
      }
    };

    // Don't initialize if banner is already initialized or consent exists
    if (window._iub || bannerInitialized || checkConsent()) {
      return;
    }

    const loadScript = (src, attributes = {}) => {
      return new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) {
          resolve();
          return;
        }

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        
        Object.entries(attributes).forEach(([key, value]) => {
          script[key] = value;
        });

        script.onload = resolve;
        script.onerror = reject;
        
        document.head.appendChild(script);
      });
    };

    const initIubenda = async () => {
      try {
        window._iub = window._iub || [];

        const configScript = document.createElement('script');
        configScript.type = 'text/javascript';
        configScript.innerHTML = `
          window._iub.csConfiguration = {
            siteId: 3823051,
            cookiePolicyId: 33611450,
            lang: "it",
            countryDetection: true,
            perPurposeConsent: true,
            purposes: "1,2,3,4,5",
            cookiePolicyInOtherWindow: true,
            whitelabel: false,
            banner: {
              position: "float-bottom-center",
              acceptButtonDisplay: true,
              customizeButtonDisplay: true,
              rejectButtonDisplay: true,
              closeButtonRejects: true,
              acceptButtonColor: "#0B5ED7",
              acceptButtonCaptionColor: "white",
              customizeButtonColor: "#212529",
              customizeButtonCaptionColor: "white",
              rejectButtonColor: "#212529",
              rejectButtonCaptionColor: "white",
              textColor: "#212529",
              backgroundColor: "#FFFFFF",
              fontSize: "14px",
              prependOnBody: true,
              acceptButtonCaption: "Accetta tutti",
              rejectButtonCaption: "Rifiuta",
              customizeButtonCaption: "Personalizza",
              content: "Utilizziamo i cookie e altre tecnologie di tracciamento per migliorare la tua esperienza di navigazione nel nostro sito, per mostrarti contenuti personalizzati e annunci mirati, per analizzare il traffico sul nostro sito e per capire da dove arrivano i nostri visitatori.",
              closeButtonCaption: "Chiudi"
            },
            callback: {
              onReady: function() {
                setBannerInitialized(true);
              },
              onError: function() {
                setScriptLoadError(true);
              },
              onPreferenceExpressed: function(preference) {
                // When user expresses their preference (accept/reject)
                setBannerInitialized(true);
              }
            }
          };
        `;
        document.head.appendChild(configScript);

        await loadScript('https://cs.iubenda.com/autoblocking/3823051.js');
        await loadScript('//cdn.iubenda.com/cs/iubenda_cs.js', {
          charset: 'UTF-8',
          async: true
        });
        
        setBannerInitialized(true);
      } catch (error) {
        console.error('Inizializzazione del consenso cookie non riuscita:', error);
        setScriptLoadError(true);
      }
    };

    initIubenda();

    return () => {
      const scripts = document.querySelectorAll('script[src*="iubenda"]');
      scripts.forEach(script => script.remove());
      
      const configs = document.querySelectorAll('script:not([src])');
      configs.forEach(script => {
        if (script.textContent.includes('_iub.csConfiguration')) {
          script.remove();
        }
      });
    };
  }, [bannerInitialized]);

  // Fallback banner
  if (scriptLoadError) {
    return (
      <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg p-4 z-50">
        <div className="max-w-screen-xl mx-auto flex flex-col sm:flex-row items-center justify-between gap-4">
          <p className="text-sm text-gray-600">
            Questo sito utilizza i cookie per migliorare la tua esperienza di navigazione. 
            Continuando a utilizzare questo sito, accetti la nostra{' '}
            <a 
              href="https://www.iubenda.com/privacy-policy/33611450/cookie-policy" 
              className="text-blue-600 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              informativa sui cookie
            </a>.
          </p>
          <div className="flex gap-2">
            <button
              onClick={() => {
                document.cookie = "_iub_cs-consent=rejected; path=/; max-age=31536000";
                setScriptLoadError(false);
              }}
              className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors"
            >
              Rifiuta
            </button>
            <button
              onClick={() => {
                document.cookie = "_iub_cs-consent=accepted; path=/; max-age=31536000";
                setScriptLoadError(false);
              }}
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
            >
              Accetta tutti
            </button>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default CookieBanner;