import { createJsonTypeInstance, createMultipartInstance } from './index';
const axios = createJsonTypeInstance();

export const fetchDdt = async (id) => {
  try {
    const response = await axios.get(`/ddt/fetchById/${id}`);
    if (response.data && response.data.data) {
      return response.data;
    }
    throw new Error('Invalid response format');
  } catch (error) {
    throw error.response || error;
  }
};



export const fetchCurrencies = async () => {
  try {
    const response = await axios.get('/valuta');
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const fetchUm = async () => {
  try {
    const response = await axios.get('/um');
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const fetchSupplier = async (companyId) => {
  try {
    const response = await axios.get(`/supplier/all-Suppliers/${companyId}`);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const fetchCantiere = async (companyId) => {
  try {
    const response = await axios.get(`/cantieri/all-cantiere/${companyId}`);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const fetchDdtDetails = async (id) => {
  try {
    const response = await axios.get(`/ddt_invoice/${id}`);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const fetchDdtInvoiceById = async (token) => {
  try {
    const response = await axios.get(`/ddt_invoice/${token}`);
    
    // Check if response has the expected structure
    if (response.data && response.data.success) {
      return {
        success: true,
        data: {
          ddtInvoice: response.data.data.ddtInvoice
        }
      };
    }
    
    throw new Error('Invalid response format');
  } catch (error) {
    console.error('Error fetching DDT invoice:', error);
    throw error.response || error;
  }
};

export const fetchClients = async (companyId) => {
  try {
    const response = await axios.get(`/client/all-Clients_computimetric/${companyId}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};


export const updateDdt = async (id, updateData) => {
    try {
      const response = await axios.put(`/ddt/${id}`, updateData);
      return response;
    } catch (error) {
      throw error.response;
    }
  };


  export const updateDdtInvoice = async (token, updateData) => {
    try {
      const response = await axios.put(`/ddt_invoice/${token}`, updateData);
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  