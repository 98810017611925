import React from 'react'
// import SideBar from '../../components/admin/sharedComponent/SideBar'
import Layout from '../../components/admin/sharedComponent/Layout'
import Dashboard from '../../components/admin/dashboard'

const DashBoardScreen = () => {
  return (
    <Layout>
     <Dashboard />
    </Layout>
  )
}

export default DashBoardScreen