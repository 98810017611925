import {createJsonTypeInstance}  from './index';
const axios =createJsonTypeInstance();

export const getCompnayEmployeeAttendence = async (companyId) => {
    try {
      const response = await axios.get(`/attendance/AllEmployee-Attendance/${companyId}`);
      return response;
    } catch (error) {
      throw error.response.data; 
    }
  };

  export const markAttendance = async (data) => {
    console.log("attendance data:", data);
    try {
        const response = await axios.post('/attendance/mark', data);
        return response;
    } catch (error) {
        throw error.response.data; 
    }
};

export const updateAttendance = async (data) => {
    try {
        const response = await axios.put('/attendance/update', data);
        return response;
    } catch (error) {
        throw error.response.data;
    }
};
