import {createJsonTypeInstance}  from './index';
const axios =createJsonTypeInstance();


export const AddCompanyShares = async (data) => {
    try {
      const response = await axios.post('propertyShares/addOrUpdateMembers' , data);
      return response;
    } catch (error) {
      throw error.response.data; 
    }
  };



  export const getCompanySharesData = async (id) => {
    try {
      const response = await axios.get(`/propertyShares/${id}`);
      return response;
    } catch (error) {
      throw error.response; 
    }
  };


  export const deleteMemberShares = async (data) => {
    try {
      const response = await axios.delete('/propertyShares/memberDelete', {
        data: data
      });
      return response;
    } catch (error) {
      throw error.response.data;
    }
  };
