import { createJsonTypeInstance } from './index';
const axios = createJsonTypeInstance();

export const fetchPreventivi = async (companyId, currentPage, limit) => {
  try {
    const response = await axios.get(`/preventivi/${companyId}?page=${currentPage}&limit=${limit}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const updatePreventivi = async (id, updatedData) => {
  try {
    const response = await axios.put(`preventivi/${id}`, updatedData);
    return response;
    } catch (error) {
    throw error.response;
  }
};

export const deletePreventivo = async (id) => {
  try {
    const response = await axios.delete(`preventivi/${id}`);
    return response;
    } catch (error) {
    throw error.response;
  }
};

export const exportPreventiviToPDF = async (companyId, preventivoId) => {
  try {
    const response = await axios.get(`/preventivi/${companyId}/${preventivoId}/pdf`, {
      responseType: 'blob'
    });
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `preventivo_${preventivoId}.pdf`;
    link.click();
    window.URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error('Error exporting PDF:', error);
    throw error;
  }
};

export const importPreventivi = async (companyId, file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    
    const response = await axios.post(`/preventivi/import/${companyId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    throw error.response;
  }
};