import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import Status_preventiciComponent from '../../../components/admin/preventivi/status_preventivi'

const Status_preventiviDetails = () => {
  return (
    <Layout>
      <Status_preventiciComponent />
    </Layout>
  )
}

export default Status_preventiviDetails