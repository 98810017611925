import React, { useEffect } from "react";
import { useForm, watch } from "react-hook-form";
import { AddLeave, UpdateLeave } from "../../../../../apis/leave";
import { toast } from "react-toastify"
const AddAbsence = ({ isOpen, onClose, setIsModalOpen, employeeData, selectedId, SingleLeaveData, dataUpdate, setDataUpdate }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user.companyId;

  const { register, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: SingleLeaveData,
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      const payload = {
        ...data,
        companyId: companyId,
      };
      if (selectedId) {
        const res = await UpdateLeave(selectedId, payload);
        if (res.status === 200) { toast.success(res.data?.message) } else { toast.error("Failed to updated absence") }
      } else {
        const res = await AddLeave(payload);
        if (res.status === 201) { toast.success(res.data?.message) } else { toast.error("Failed to mark absence") }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsModalOpen(false);
      setDataUpdate(!dataUpdate)
    }
  };

  useEffect(() => {
    reset(SingleLeaveData);
  }, [SingleLeaveData, reset]);

  const calculateAbsenceHours = () => {
    const startDate = new Date(watch("startDate"));
    const endDate = new Date(watch("endDate"));

    if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate) && startDate <= endDate) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
      const absenceHours = diffDays * 8;
      setValue("absenceHours", absenceHours);
    } else {
      setValue("absenceHours", 0);
    }
  };

  useEffect(() => {
    calculateAbsenceHours();
  }, [watch("startDate"), watch("endDate")]);

  return (
    <div
      id="authentication-modal"
      tabIndex="-1"
      aria-hidden={!isOpen}
      className={`${isOpen ? "flex" : "hidden"
        } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-black bg-opacity-50`}
    >
      <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 w-[35%]">
        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            {selectedId ? "Aggiornamento assenza" : "Aggiungi assenza"}
          </h3>
          <button
            type="button"
            className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            onClick={onClose}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <div className="p-4 md:p-5">
          <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label
                htmlFor="employee"
                className="block text-sm font-medium text-gray-900 dark:text-white pb-1"
              >
                Dipendente
              </label>
              <select
                id="employee"
                className="border border-gray-300 text-gray-900 text-sm rounded w-full p-2.5"
                {...register("employeeId")}
                required
              >
                <option value="">Seleziona dipendente</option>
                {employeeData?.map((employee) => (
                  <option key={employee.employeeId} value={employee.employeeId}>
                    {employee.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="typology"
                className="block text-sm font-medium text-gray-900 dark:text-white pb-1"
              >
                Tipologia assenza
              </label>
              <select
                id="typology"
                className="border border-gray-300 text-gray-900 text-sm rounded w-full p-2.5"
                {...register("absenceType")}
                required
              >
                <option value="">Seleziona tipologia</option>
                <option value="1">Via roma construction site 1</option>
                <option value="2">Via roma construction site 2</option>
                <option value="3">Via roma construction site 3</option>
                <option value="4">Via roma construction site 4</option>
                <option value="5">Via roma construction site 5</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="startDate"
                className="block text-sm font-medium text-gray-900 dark:text-white pb-1"
              >
                Data inizio assenza
              </label>
              <input
                type="date"
                id="startDate"
                className="border border-gray-300 text-gray-900 text-sm rounded w-full p-2.5"
                {...register("startDate")}
              />
            </div>
            <div>
              <label
                htmlFor="endDate"
                className="block text-sm font-medium text-gray-900 dark:text-white pb-1"
              >
                Data fine assenza
              </label>
              <input
                type="date"
                id="endDate"
                className="border border-gray-300 text-gray-900 text-sm rounded w-full p-2.5"
                {...register("endDate")}
              />
            </div>
            <div>
              <label
                htmlFor="absenceHours"
                className="block text-sm font-medium text-gray-900 dark:text-white pb-1"
              >
                Ore di assenza
              </label>
              <input
                type="number"
                min={0}
                id="absenceHours"
                className="border border-gray-300 text-gray-900 text-sm rounded w-full p-2.5"
                {...register("absenceHours")}
                readOnly
              />
            </div>
            <div className="flex justify-end items-end gap-3">
              <button
                type="button"
                onClick={onClose}
                className="text-white bg-[#eb4343] hover:opacity-90 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-1.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Annulla
              </button>
              <button
                type="submit"
                className="text-white bg-[#06052C] hover:opacity-90 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-1.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {selectedId ? "Aggiornamento" : "Aggiungi"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddAbsence;
