import React, { useEffect, useState } from 'react';
import NavBar from './NavBar';
import SideBar from './SideBar';
import Footer from './Footer';

const Layout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    const breadcrumb = JSON.parse(localStorage.getItem('breadcrumb'));
    if (breadcrumb) {
      setBreadcrumbs([breadcrumb]);
    }
  }, []);

  const updateBreadcrumbs = (newCrumb) => {
    setBreadcrumbs([newCrumb]);
    if (newCrumb.label === "Dashboard") {
      newCrumb.label = "";
      newCrumb.path = "";
    }
    localStorage.setItem('breadcrumb', JSON.stringify(newCrumb));
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      <NavBar toggleSidebar={toggleSidebar} breadcrumbs={breadcrumbs} />
      <div className="flex">
        <SideBar
          isOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
          updateBreadcrumbs={updateBreadcrumbs}
        />
        <div className="w-full lg:ml-[16rem] bg-slate-50 md:h-[89vh] overflow-scroll">
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
