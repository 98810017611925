import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import RegioniComponent from '../../../components/admin/prezzari_regioni/regioni'

const RegioniDetails = () => {
  return (
    <Layout>
      <RegioniComponent />
    </Layout>
  )
}

export default RegioniDetails