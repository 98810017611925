
import React, { useEffect, useState } from 'react';
//import axios from 'axios';
import { Link } from 'react-router-dom';
import SearchBar from '../sharedComponent/SearchBar'
import { TbEdit } from "react-icons/tb";
import { MdOutlineDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { FaArrowRight, FaFilter } from "react-icons/fa";


function TableComponent() {
  const [showModal, setShowModal] = useState(false);

  function handleAddClick() {
    setShowModal(true);
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  return (
    <div className="ml-10 mr-7">
      <div class="flex px-4 text-white bg-[#06052C]">
        <div class="flex-shrink-0 p-4"><Link to={`/magazzino`}>Inventario</Link></div>
        <div class="flex-shrink-0 p-4"><Link to={`/luoghi_di_deposito`}>Luoghi di deposito</Link></div>
      </div>
      <div className="flex justify-between my-7">
        <button onClick={handleAddClick} className="flex items-center space-x-2 text-white bg-[#06052C] hover:opacity-90 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-3  text-center cursor-pointer" type="button">
          Aggiungi
        </button>
        <div className="w-[40%] flex space-x-2">
          <SearchBar />
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg  mx-auto flex items-center justify-center">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 bg-gray-300 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th className="p-3" scope="col">ID</th>
              <th className="py-3" scope="col">Nome</th>
              <th className="py-3" scope="col">Indirizzo</th>
              <th className="py-3" scope="col">Città</th>
              <th className="py-3" scope="col">CAP</th>
              <th className="py-3" scope="col">Luogo di deposito</th>
              <th className="py-3" scope="col">Nazione</th>
            </tr>
          </thead>
          <tbody>
            <tr key={1}>
              <td className="p-3"></td>
              <td className="py-3"></td>
              <td className="py-3"></td>
              <td className="py-3"></td>
              <td className="py-3"></td>
              <td className="py-3"></td>
              <td className="py-3">

              </td>
            </tr>
          </tbody>
          {/*<tbody>
          {data.map(item => (
            <tr key={item._id}>
              <td>{item.sn}</td>
              <td>{item.date}</td>
              <td>{item.code}</td>
              <td>{item.client_id}</td>
              <td>{item.description}</td>
              <td>
                <Link to={`/details/${item._id}`}>View Details</Link>
              </td>
            </tr>
          ))}
        </tbody>*/}
        </table>
      </div>
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black opacity-50" onClick={handleCloseModal}></div>
          <div className="bg-white rounded-lg shadow-lg p-6 z-10 w-3/5 overflow-auto">
            <h2 className="text-xl font-semibold mb-4">Aggiungi nuovo luogo di deposito</h2>
            <form class="">
              <div className="mb-4">
                <label htmlFor="driverName" className="block text-sm font-medium text-gray-700">Nome del luogo</label>
                <input type="text" id="driverName" className="mt-1 p-2 w-full border border-gray-300 rounded-md" />
              </div>
              <div className="mb-4">
                <label htmlFor="driverName" className="block text-sm font-medium text-gray-700">Indirizzo</label>
                <input type="text" id="driverName" className="mt-1 p-2 w-full border border-gray-300 rounded-md" />
              </div>
              <div className="mb-4 flex space-x-4">
                <div className="w-1/2">
                  <label htmlFor="driverName" className="block text-sm font-medium text-gray-700">Città</label>
                  <input type="text" id="driverName" className="mt-1 p-2 w-full border border-gray-300 rounded-md" />
                </div>
                <div className="w-1/2">
                  <label htmlFor="driverName" className="block text-sm font-medium text-gray-700">CAP</label>
                  <input type="text" id="driverName" className="mt-1 p-2 w-full border border-gray-300 rounded-md" />
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="driverName" className="block text-sm font-medium text-gray-700">Nazione</label>
                <input type="text" id="driverName" className="mt-1 p-2 w-full border border-gray-300 rounded-md" />
              </div>
              <div className="flex justify-end space-x-2">
                <button type="button" onClick={handleCloseModal} className="px-4 py-2 text-white bg-[#8c060c] rounded-md">Annulla</button>
                <button type="submit" className="px-4 py-2 text-white bg-[#06052C] rounded-md">Salva</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableComponent;
