import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import Client from "../../../components/admin/personalData/client"

const ClientScreen = () => {
  return (
    <Layout><Client /></Layout>
  )
}

export default ClientScreen