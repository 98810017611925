import React, { useState, useEffect } from 'react';
import { getAllTickets, deleteTicket } from '../../../apis/TicketApi';
import TicketForm from './TicketForm';
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import { Button } from "../../ui/button";
import { Alert, AlertDescription, AlertTitle } from "../../ui/alert";
import { Badge } from "../../ui/badge";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../../ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import { toast } from 'react-toastify';

const TicketComponent = ({ isSupport = false }) => {
  const [tickets, setTickets] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [editingTicket, setEditingTicket] = useState(null);

  useEffect(() => {
    fetchTickets();
  }, []);

  const fetchTickets = async () => {
    try {
      setLoading(true);
      const response = await getAllTickets();
      setTickets(response);
      setError(null);
    } catch (err) {
      console.error('Errore nel recupero dei ticket:', err);
      setError('Impossibile recuperare i ticket');
      setTickets([]);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteTicket(id);
      setTickets(prevTickets => prevTickets.filter(ticket => ticket._id !== id));
      toast({
        title: "Ticket eliminato",
        description: "Il ticket è stato eliminato con successo.",
      });
    } catch (err) {
      setError('Impossibile eliminare il ticket');
      toast({
        title: "Errore",
        description: "Impossibile eliminare il ticket. Riprova più tardi.",
        variant: "destructive",
      });
    }
  };

  const handleEdit = (ticket) => {
    setEditingTicket(ticket);
    setShowForm(true);
  };

  const handleFormSuccess = (response) => {
    setShowForm(false);
    setEditingTicket(null);
    fetchTickets();
    toast({
      title: editingTicket ? "Ticket aggiornato" : "Ticket creato",
      description: `Il ticket numero ${response.ticketNumber} è stato ${editingTicket ? 'aggiornato' : 'creato'} con successo.`,
    });
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case 'High': return 'bg-red-500 text-white';
      case 'Medium': return 'bg-yellow-500 text-black';
      case 'Low': return 'bg-green-500 text-white';
      default: return 'bg-gray-500 text-white';
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Aperto': return 'bg-blue-500 text-white';
      case 'In Corso': return 'bg-yellow-500 text-black';
      case 'Chiuso': return 'bg-green-500 text-white';
      default: return 'bg-gray-500 text-white';
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Caricamento ticket in corso...</div>;
  }

  return (
    <div className="p-4 max-w-7xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Sistema di Supporto Ticket</h1>
        <Dialog open={showForm} onOpenChange={setShowForm}>
          <DialogTrigger asChild>
            <Button>Crea Nuovo Ticket</Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>{editingTicket ? 'Modifica Ticket' : 'Crea Nuovo Ticket'}</DialogTitle>
            </DialogHeader>
            <TicketForm
              onClose={() => {
                setShowForm(false);
                setEditingTicket(null);
              }}
              onSuccess={handleFormSuccess}
              ticket={editingTicket}
              isSupport={isSupport}
            />
          </DialogContent>
        </Dialog>
      </div>
      
      {error && (
        <Alert variant="destructive">
          <AlertTitle>Errore</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <Tabs defaultValue="all">
        <TabsList className="mb-4">
          <TabsTrigger value="all">Tutti i Ticket</TabsTrigger>
          <TabsTrigger value="open">Aperti</TabsTrigger>
          <TabsTrigger value="inProgress">In Corso</TabsTrigger>
          <TabsTrigger value="closed">Chiusi</TabsTrigger>
        </TabsList>

        {['all', 'open', 'inProgress', 'closed'].map((tab) => (
          <TabsContent key={tab} value={tab}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {tickets && tickets.length > 0 ? (
                tickets
                  .filter(ticket => 
                    tab === 'all' || 
                    (tab === 'open' && ticket.status === 'Aperto') ||
                    (tab === 'inProgress' && ticket.status === 'In Corso') ||
                    (tab === 'closed' && ticket.status === 'Chiuso')
                  )
                  .map((ticket) => (
                    <Card key={ticket._id} className="hover:shadow-lg transition-shadow duration-300">
                      <CardHeader>
                        <CardTitle className="flex justify-between items-center">
                          <span className="truncate">#{ticket.ticketNumber} - {ticket.subject}</span>
                          <Badge className={getStatusColor(ticket.status)}>{ticket.status}</Badge>
                        </CardTitle>
                      </CardHeader>
                      <CardContent>
                        <p className="text-sm text-gray-600 mb-2 truncate">{ticket.description}</p>
                        <div className="flex justify-between items-center">
                          <Badge className={getPriorityColor(ticket.priority)}>{ticket.priority}</Badge>
                          <span className="text-sm text-gray-500">{ticket.category}</span>
                        </div>
                        <div className="mt-4 flex justify-end space-x-2">
                          <Button size="sm" onClick={() => handleEdit(ticket)}>
                            {!isSupport && ticket.status === 'Aperto' ? 'Chiudi Ticket' : 'Modifica'}
                          </Button>
                          <Button size="sm" variant="destructive" onClick={() => handleDelete(ticket._id)}>Elimina</Button>
                        </div>
                      </CardContent>
                    </Card>
                  ))
              ) : (
                <p className="col-span-full text-center text-gray-500">Nessun ticket trovato.</p>
              )}
            </div>
          </TabsContent>
        ))}
      </Tabs>
    </div>
  );
};

export default TicketComponent;